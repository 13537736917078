/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Formik, Form, Field } from 'formik';
import { styled } from '@mui/material/styles';

import { TextField, Autocomplete } from '@mui/material';

const CustomTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiInputBase-root': {
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& input': {
      fontSize: '16px', // Taille de la police souhaitée
      border: 'none', // Supprimer la bordure par défaut
      width: '80%',
    },
  },

  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
}));
function AutoComplete({
  name, label, options, setFieldValue, disabled = false, valueForm,
}) {
  console.log(valueForm, 'zaza', options);
  //   const options = [
  //     { label: 'Option 1', value: 'option1' },
  //     { label: 'Option 2', value: 'option2' },
  //     { label: 'Option 3', value: 'option3' },
  //   ];

  return (
    <Field name={name}>
      {({ field }) => (
        <Autocomplete
          {...field}
          name={name}
          options={options}
          getOptionLabel={(option) => {
            const selectedOption = options.find(
              (opt) => opt.value === option || opt.value === option.value,
            );
            return selectedOption ? selectedOption.label : '';
          }}
          disabled={disabled}
          {...(valueForm && { value: valueForm })}
          onChange={(event, newValue) => {
            setFieldValue(name, newValue?.value ? newValue.value : '');
          }}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              label={label}
              margin="normal"
              variant="standard"
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          )}
        />
      )}
    </Field>
  );
}

export default AutoComplete;
