import * as React from 'react';
import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import logoEstelle from '../../images/intense-rh.png';
import { useMyContext } from '../../hooks/ContextOpen';

const pathnameToTitle = (pathname) => {
  if (['/', '/candidats'].includes(pathname)) {
    return 'Candidats';
  }
  if (pathname === '/customers') {
    return 'Entreprises';
  }
  if (pathname === '/update-job') {
    return 'Modification métier';
  }

  if (pathname === '/calendar') {
    return 'Calendrier';
  }

  if (pathname === '/missions') {
    return 'Missions';
  }

  if (pathname === '/mail-template') {
    return 'Template mail';
  }

  return '';
};

export default function PersistentDrawerLeft() {
  const location = useLocation();
  const { openDrawing, setOpenDrawing } = useMyContext();

  return (
    <Toolbar>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => setOpenDrawing(!openDrawing)}
          color="inherit"
        >
          {openDrawing ? <CloseIcon /> : <MenuIcon /> }
        </IconButton>
      </Box>
      <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
          {pathnameToTitle(location.pathname)}
        </Typography>
      </Box>

      <img
        src={logoEstelle}
        style={{ display: { xs: 'none', md: 'flex' }, width: 50, marginRight: 20 }}
        alt="intense RH logo"
      />

      {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
    </Toolbar>

  );
}
