/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
  Box, Card, CardContent, Accordion, AccordionSummary, AccordionDetails, IconButton,
} from '@mui/material';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Grow from '@mui/material/Grow';
import CloseIcon from '@mui/icons-material/Close';
import FormForMission from '../../../../../Components/FormForMission';
import CardForMission from './CardForMission';
import colors from '../../../../../utils/colors';

// import CardCommentary from '../CardCommentary';
// import Commentary from '../Commentary';

export default function ContactsComponent({ customerId }) {
  const [missions, setMissions] = useState([]);
  const [isCreateActive, setIsCreateActive] = useState(false);
  const [contactModifyId, setContactModifyId] = useState(false);

  const getMissions = async () => {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/api/mission/all`, { customerId });

    setMissions(result.data.data);
  };

  const onSubmit = async (value, { resetForm }) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/mission`, { ...value, customerId });

    await getMissions();

    setIsCreateActive(false);

    resetForm();
  };

  const deleteMission = async (id) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/mission/${id}`);

    await getMissions();
  };

  const updateMission = async (id, body) => {
    await axios.patch(`${process.env.REACT_APP_API_URL}/api/mission/${id}`, body);

    await getMissions();

    setContactModifyId(false);
  };

  useEffect(() => {
    getMissions();
  }, []);

  console.log(missions);
  return (

    <Grid container style={{ maxHeight: '35rem', overflow: 'auto' }}>
      <Grid item xs={12} md={12}>
        <Typography variant="body1" gutterBottom>
          Missions
        </Typography>

      </Grid>
      <Grid item xs={12} md={12}>

        {!isCreateActive
        && (
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Chip
              label="Ajouter une mission"
              onClick={() => setIsCreateActive(true)}
              style={{ background: colors.color, color: 'white' }}
            />
          </div>
        )}
        <Grow in={isCreateActive} mountOnEnter unmountOnExit>

          <Box sx={{ minWidth: '95%' }}>

            <Card variant="outlined">

              <CardContent>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <IconButton>
                    <CloseIcon onClick={() => setIsCreateActive(false)} />
                  </IconButton>
                </div>
                <FormForMission onSubmit={onSubmit} />
              </CardContent>
            </Card>
          </Box>
        </Grow>
      </Grid>

      {missions.length
        ? missions.map((mission) => {
          if (mission._id === contactModifyId) {
            return (
              <Grid item xs={12} md={12} key={mission._id}>
                <Card variant="outlined">
                  <CardContent>
                    <FormForMission onSubmit={(value) => updateMission(mission._id, value)} mission={mission} isModify onClose={() => setContactModifyId(null)} />
                  </CardContent>
                </Card>
              </Grid>
            );
          }
          return (
            <Grid item xs={12} md={12} key={mission._id}>
              <CardForMission
                value={mission}
                //   addOrDeleteToFavorite={addOrDeleteToFavorite}
                deleteMission={() => deleteMission(mission._id)}
                updateMission={() => setContactModifyId(mission._id)}
              />
            </Grid>
          );
        })
        : null}
    </Grid>
  );
}
