/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from 'react';
import {
  EditorState, convertFromRaw, convertToRaw, Modifier,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { Autocomplete, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import colors from '../../utils/colors';
import SearchComponent from '../SearchComponent';

const content = {
  entityMap: {},
  blocks: [{
    key: '637gr',
    text: 'Initialized from content state.',
    type: 'unstyled',
    depth: 0,
    inlineStyleRanges: [],
    entityRanges: [],
    data: {},
  }],
};

const toolbar = {
  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough'], // Définir la police sans-serif pour les sélections
  },
  fontFamily: { // Passer une option vide pour la police de caractères
    options: [],
  },
};

const options = [
  { group: 'Client', value: '${customer.name}' },
  { group: 'Client', value: '${customer.email}' },
  { group: 'Client', value: '${customer.phone}' },
  { group: 'Client', value: '${customer.address}' },
  { group: 'Candidat', value: '${prospect.name}' },
  { group: 'Candidat', value: '${prospect.email}' },
  { group: 'Candidat', value: '${prospect.phone}' },
  { group: 'Candidat', value: '${prospect.address}' },
  { group: 'Mission', value: '${mission.nameMission}' },
  { group: 'Mission', value: '${mission.description}' },
  { group: 'Mission', value: '${mission.email}' },
  { group: 'Mission', value: '${mission.job}' },
  { group: 'Mission', value: '${mission.jobs}' },
  { group: 'Mission', value: '${mission.city}' },
  { group: 'Contact', value: '${contact.name}' },
  { group: 'Contact', value: '${contact.phoneNumber}' },
  { group: 'Contact', value: '${contact.email}' },
  { group: 'Contact', value: '${contact.job}' },
  // Ajoutez d'autres options ici selon vos besoins
];

function EmailInput({
  text, title, setTitle, saveTemplate, textChangeFunc, isSaving = true,
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [editorState, setEditorState] = useState(null);
  const [isVisibility, setIsVisibility] = useState(false);
  const [isTextChange, setIsTextChange] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    customer: '', prospect: '', mission: '', contact: '',
  });
  const [customerSelected, setCustomerSelected] = useState(null);

  const textUpdated = () => {
    setIsTextChange(true);
    if (textChangeFunc) {
      textChangeFunc();
    }
  };
  const onEditorStateChange = (editorStatee, salut) => {
    setEditorState(editorStatee);
    textUpdated();
  };

  const searchCustomForProspect = async (value) => {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/api/prospects/search`, { name: value });

    return result?.data?.response.map((prospect) => ({ label: prospect.name, value: prospect }));
  };

  const searchCustomForCustomer = async (value) => {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/api/customer/search`, { name: value });

    return result?.data?.response.map((prospect) => ({ label: prospect.name, value: prospect }));
  };

  const searchCustomForContact = async (value) => {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/api/contact/search`, { name: value, ...(customerSelected ? { customerId: customerSelected._id } : {}) });

    return result?.data?.response.map((prospect) => ({ label: prospect.name, value: prospect }));
  };

  const searchCustomForMission = async (value) => {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/api/mission/search`, { name: value, ...(customerSelected ? { customerId: customerSelected._id } : {}) });

    return result?.data?.response.map((prospect) => ({ label: prospect.nameMission, value: prospect }));
  };

  const handleOptionChange = async (value, option) => {
    if (option === 'customer') {
      setCustomerSelected(value);
    }

    setSelectedOptions({ ...selectedOptions, [option]: value });
  };

  const handleOptionSelected = (value) => {
    const currentContentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    // Supprime la création d'entité LINK
    const newContentState = Modifier.insertText(
      currentContentState,
      selectionState,
      value,
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-characters',
    );

    setEditorState(newEditorState);
  };

  const replaceVariables = (template, prospect, customer, mission, contact) => template.replace(/\${(.*?)}/g, (match, p1) => {
    const keys = p1.split('.');
    let value;
    if (prospect && keys[0] === 'prospect' && Object.prototype.hasOwnProperty.call(prospect, keys[1])) {
      value = prospect[keys[1]];
    } else if (customer && keys[0] === 'customer' && Object.prototype.hasOwnProperty.call(customer, keys[1])) {
      value = customer[keys[1]];
    } else if (mission && keys[0] === 'mission' && Object.prototype.hasOwnProperty.call(mission, keys[1])) {
      value = mission[keys[1]];
    } else if (contact && keys[0] === 'contact' && Object.prototype.hasOwnProperty.call(contact, keys[1])) {
      value = contact[keys[1]];
    }
    // Si la valeur existe, retourne la valeur remplacée, sinon, retourne la variable inchangée
    return value !== undefined ? value : match;
  });

  const updateTextWithDifferentValue = (textBlock) => {
    const filledTemplate = textBlock.blocks.map((block) => {
      const filledText = replaceVariables(block.text, selectedOptions?.prospect, selectedOptions?.customer, selectedOptions?.mission, selectedOptions?.contact);
      return { ...block, text: filledText };
    });

    return { blocks: filledTemplate };
  };

  useEffect(() => {
    if (text) {
      if (isVisibility || !isSaving) {
        const result = updateTextWithDifferentValue(text);
        setTitle(replaceVariables(title, selectedOptions?.prospect, selectedOptions?.customer, selectedOptions?.mission, selectedOptions?.contact));
        setEditorState(EditorState.createWithContent(convertFromRaw({ entityMap: {}, ...result })));
      } else {
        setEditorState(EditorState.createWithContent(convertFromRaw({ entityMap: {}, ...text })));
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [text, isVisibility]);

  useEffect(() => {
    if (text) {
      if (isVisibility || !isSaving) {
        const result = updateTextWithDifferentValue(text);
        console.log(result);
        setEditorState(EditorState.createWithContent(convertFromRaw({ entityMap: {}, ...result })));
      }
    }
    if (title) {
      if (isVisibility || !isSaving) {
        const result = replaceVariables(title, selectedOptions?.prospect, selectedOptions?.customer, selectedOptions?.mission, selectedOptions?.contact);
        setTitle(result);
      }
    }
  }, [selectedOptions]);

  return (
    <Grid container spacing={2}>
      {isSaving
        && (
          <>
            <Grid item xs={1} md={1}>

              <Tooltip title={isVisibility ? 'Version template' : 'Version réel'}>
                <Checkbox
                  icon={<VisibilityIcon />}
                  checkedIcon={<VisibilityOffIcon />}
                  checked={isVisibility}
                  onChange={(value) => {
                    if (!isVisibility && isTextChange) {
                      enqueueSnackbar('Veuillez sauvegarder avant de voir la version réel', { variant: 'warning' });
                    } else {
                      setSelectedOptions({
                        customer: '', prospect: '', mission: '', contact: '',
                      });
                      setIsVisibility(value.target.checked);
                    }
                  }}
                />
              </Tooltip>

            </Grid>
            <Grid item xs={11} md={11} />
          </>
        )}
      {isVisibility || !isSaving
        ? (
          <>
            <Grid item xs={4} md={4}>
              <SearchComponent label="Candidat" searchCustom={searchCustomForProspect} handleOptionChange={(value) => handleOptionChange(value, 'prospect')} beginValue={selectedOptions?.prospect} />
            </Grid>
            <Grid item xs={4} md={4}>
              <SearchComponent label="Client" searchCustom={searchCustomForCustomer} handleOptionChange={(value) => handleOptionChange(value, 'customer')} beginValue={selectedOptions?.customer} />
            </Grid>
            <Grid item xs={4} md={4}>
              <SearchComponent label="Mission" searchCustom={searchCustomForMission} handleOptionChange={(value) => handleOptionChange(value, 'mission')} customerId={customerSelected?._id} beginValue={selectedOptions?.mission} />
            </Grid>
            <Grid item xs={4} md={4}>
              <SearchComponent label="Contact" searchCustom={searchCustomForContact} handleOptionChange={(value) => handleOptionChange(value, 'contact')} customerId={customerSelected?._id} beginValue={selectedOptions?.contact} />
            </Grid>
          </>
        )
        : (
          <Grid item xs={12} md={12}>
            <Autocomplete
              key={editorState}
              freeSolo
              options={options.map((option) => option.value)}
              groupBy={(option) => options?.find((item) => item.value === option)?.group}
              renderInput={(params) => (
                <TextField {...params} label="Ajouter une variable d'automatisation" variant="outlined" />
              )}
              onChange={(event, value) => {
                handleOptionSelected(value);
                console.log('onChange', event?.target, value);
                setIsTextChange(false);
              }}
            />
          </Grid>
        )}
      <Grid item xs={12} md={12}>
        <Editor
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbar={toolbar}
          toolbarHidden={!isSaving || isVisibility}
          readOnly={isVisibility}
          toolbarStyle={{
            border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '10px',
          }}
          editorStyle={{
            height: '20rem',
            border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '10px',
            fontFamily: 'sans-serif',
          }}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          label="Sujet du mail"
          variant="outlined"
          sx={{ width: '100%' }}
          onChange={(event) => {
            setTitle(event.target.value);
            textUpdated();
          }}
          value={title}
        />
      </Grid>
      {isSaving && (
      <Grid item xs={12} md={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
        <Button
          sx={{ background: colors.color }}
          component="label"
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={async () => {
            const contentState = editorState.getCurrentContent();
            const rawContentState = convertToRaw(contentState);
            await saveTemplate(rawContentState, title);
            setIsTextChange(false);
            enqueueSnackbar('Template enregistrée avec succès', { variant: 'success' });
          }}
        >
          Enregistrer
        </Button>

      </Grid>
      )}
    </Grid>
  );
}

export default EmailInput;
