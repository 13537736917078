import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import colors from '../../utils/colors';

export default function BasicSpeedDial({
  actions, isRight = true, iconPrincipal, sx = {
    position: 'fixed',
    bottom: '1rem',
    right: '1rem',
  },
}) {
  return (
    <Box sx={sx}>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        icon={iconPrincipal || <SpeedDialIcon />}
        FabProps={{
          sx: {
            bgcolor: colors.secondary,
            '&:hover': {
              bgcolor: colors.secondary,
            },
          },
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.onClick}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
