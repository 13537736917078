/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { styled } from '@mui/material/styles';

const CustomTextField = styled(NativeSelect)(({ theme, disabled }) => ({
  '& .MuiInputBase-root': {
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& input': {
      fontSize: '16px', // Taille de la police souhaitée
      border: 'none', // Supprimer la bordure par défaut
      width: '80%',
    },
  },

  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
}));

export default function NativeSelectDemo({
  label, name, options, defaultValue, value, disabled = false, isMultiple = false, ...rest
}) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel variant="standard" htmlFor="uncontrolled-native" shrink>
          {label}
        </InputLabel>
        <CustomTextField
          value={value}
          disabled={disabled}
          multiple={isMultiple}
          {...rest}
          defaultValue={defaultValue}
          inputProps={{
            name,
            id: 'uncontrolled-native',
          }}
        >
          {options.map((op) => <option key={op.value} value={op.value}>{op.name}</option>)}
        </CustomTextField>
      </FormControl>
    </Box>
  );
}
