const colors = {
  linear: 'linear-gradient( 105deg,rgb(242,113,33) 0%,rgb(233,64,87) 70%,rgb(138,35,135) 100%)',
  color: 'rgb(233,64,87)',
  colorSecondary: 'rgb(138,35,135)',
  colorThird: 'rgb(242,113,33)',
  primary: '#584B53',
  secondary: '#584B53',
  background: 'white',
  white: '#fff',
  borderColor: 'black',
};

export default colors;
