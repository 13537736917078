/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import {
  Card, CardHeader, Avatar, IconButton, CardActionArea, Menu, MenuItem,
} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormForStepMission from './FormForStepMission';
import FormForRefusedMission from './FormForRefusedMission';
import { ENUM_MATCH } from '../../../../../utils/enums/enumsStepper';
import colors from '../../../../../utils/colors';

const { palette } = createTheme();

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function formatString(inputString) {
  // Supprimer les occurrences de ".", "-", "_" et " "
  const cleanedString = inputString.replace(/[.\-_ ]/g, '');

  // Ajouter un point tous les deux caractères
  const formattedString = cleanedString.match(/.{1,2}/g).join('.');

  return formattedString;
}

export default function MediaCard({
  value,
  selected,
  onClickCheckBox,
  isCheckBoxSelected,
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isEvolveModalOpen, setIsEvolveModalOpen] = React.useState(false);
  const [isRefusedModalOpen, setIsRefusedModalOpen] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    navigate(`/candidats/${value._id}`);
    // setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CardActionArea
        sx={{
          background: colors.linear,
          borderRadius: '1rem',
          color: 'white',
          height: '11rem',
        }}
        onClick={handleClick}
      >
        <CardHeader
          avatar={(
            <Avatar sx={{ bgcolor: 'white', color: 'red' }} aria-label="recipe">
              {value?.name?.split(' ')?.slice(0, 2)?.map((name) => name[0])}
            </Avatar>
        )}
          {...(onClickCheckBox
             && {
               action: <Checkbox
                 onClick={(event) => {
                   event.stopPropagation();
                   return onClickCheckBox(value);
                 }}
                 sx={{
                   color: 'white',
                   '&.Mui-checked': {
                     color: 'white',
                   },
                 }}
                 checked={isCheckBoxSelected}
               />,
             }
             )}
          subheaderTypographyProps={{ color: 'white' }}
          title={<div style={{ height: '1rem', overflow: 'hidden' }}>{value?.name || ''}</div>}
          subheader={value?.phoneNumber ? formatString(value?.phoneNumber) : ' '}
        />
        <CardContent>
          {value?.jobs?.length
        && (
        <Typography variant="body3" color="text.primary" sx={{ color: 'white' }}>
          {`${value.jobs[0]}`}
        </Typography>
        )}
          {value?.email
        && (
        <Typography variant="body2" color="text.secondary" sx={{ color: 'white' }}>
          {value.email}
        </Typography>
        )}
          {value?.jobDetail?.experienceYear
        && (
        <Typography variant="body2" color="text.secondary" sx={{ color: 'white' }}>
          {`Année d'expérience: 
          ${value.jobDetail.experienceYear} ${value.jobDetail.experienceYear > 1 ? 'ans' : 'an'}`}
        </Typography>
        )}
          <br />
          {/* {value?.jobs?.length > 1
        && (
          <div style={{ overflow: 'auto', textOverflow: 'ellipsis', width: '10rem' }}>

            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                color: 'white',
              }}
            >
              {`Métiers secondaire: ${value.jobs.slice(1).map(
                (job, index) => (index === value.jobs.length - 2 ? `${job}` : ` ${job}`),
              )}`}
            </Typography>
          </div>

        )} */}
        </CardContent>
      </CardActionArea>
      {/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => navigate(`/candidats/${value._id}`)}>Regarder le profil</MenuItem>
      </Menu> */}
    </>
  );
}
