/* eslint-disable no-undef */
/* eslint-disable space-infix-ops */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import styled from 'styled-components';

const CustomAutocomplete = styled(Autocomplete)(({ theme, disabled }) => ({
  '& .MuiInputBase-root': {
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& input': {
      fontSize: '16px', // Taille de la police souhaitée
      border: 'none', // Supprimer la bordure par défaut
      width: '80%',
    },
  },

  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
}));

const myDefaultOption = { label: 'Créer le nouveau job' };

const _filterOptions = createFilterOptions();
const filterOptions = (optionsFilt, state) => {
  const results = _filterOptions(optionsFilt, state);

  if (!results.includes(myDefaultOption)) {
    results.unshift(myDefaultOption);
  }

  return results;
};

export default function ComboBox({
  label, createNew, options, name, setFieldValue, valueForm, isDisabled, ...rest
}) {
  const [inputValue, setInputValue] = useState('');

  const onSelectOption = async (value) => {
    if (value?.label === 'Créer le nouveau job') {
      await createNew(inputValue);

      setInputValue(inputValue);
      setFieldValue('job', inputValue);

      return;
    }

    setInputValue(value?.label || '');
    setFieldValue('job', value?.label || '');
  };

  return (
    <CustomAutocomplete
      filterOptions={filterOptions}
      name={name}
      options={[...options, myDefaultOption]}
      disabled={isDisabled}
      onSelect={(event) => setInputValue(event.target.value)}
      {...rest}
      value={valueForm || inputValue}
      onChange={(event, newValue) => onSelectOption(newValue)}
      freeSolo
      renderInput={
        (params) => <TextField {...params} label={label} variant="standard" />
      }
      renderOption={(props, option, { index }) => {
        if (option.label === 'Créer le nouveau job') {
          return (
            <div key={`${option.label}${index}`} style={{ width: '100%', margin: '0.2rem' }} {...props}>
              {option.label}
              : '
              {inputValue}
              '
            </div>
          );
        }
        return (
          <div key={`${option.label}${index}`} style={{ width: '100%', margin: '0.2rem' }} {...props}>
            <Chip label={option.label} />
          </div>
        );
      }}
    />
  );
}
