/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
/* eslint-disable max-len */
import axios from 'axios';
import {
  Typography, Box, Tabs, Tab, IconButton,
} from '@mui/material';
import BookIcon from '@mui/icons-material/Book';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import FormForExperience from './FormForExperience';
import FormForFormation from './FormForFormation';
import Paper from '../../../../../Components/Paper';
import FeedBackButton from '../../../../../Components/FeedbackButton';
import DocumentCategories from '../../../../../Components/DocumentsCategories';
import FormForProspect from '../../../FormForProspect';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function ExperienceAndFormation({
  prospect, setProspect, allJobs, getAll, documents, onSubmit, innerRef, isDisabled,
}) {
  const formRef = useRef(null);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [isSelectIA, setIsSelectIA] = useState(false);
  const [value, setValue] = React.useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setIsFirstLoading(true);
    setValue(newValue);
  };

  const handleSelectedDocs = (doc) => {
    if (selectedDocs.find((url) => url === doc.pdfUrl)) {
      return setSelectedDocs(selectedDocs.filter((url) => url !== doc.pdfUrl));
    }

    return setSelectedDocs([...selectedDocs, doc.pdfUrl]);
  };

  const updateProspect = async (values) => {
    function removeEmptyProperties(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (
            obj[key] === ''
              || obj[key] === 0
              || obj[key] === null
              || obj[key] === undefined
          ) {
            delete obj[key];
          } else if (typeof obj[key] === 'object' && !(obj[key] instanceof Date) && !_.isArray(obj[key])) {
            removeEmptyProperties(obj[key]);

            if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
            }
          }
        }
      }
      return obj;
    }

    const coco = removeEmptyProperties(values);

    const result = await axios.patch(`${process.env.REACT_APP_API_URL}/api/prospects/${prospect._id}`, coco);

    setProspect(result.data.prospect);

    await getAll();
  };

  const getResumeFunction = async (document) => {
    const selected = isSelectIA;
    setIsSelectIA(false);
    enqueueSnackbar('Patientez les informations vont se mettre à jour', { variant: 'warning' });

    await axios.patch(`${process.env.REACT_APP_API_URL}/api/prospects/resume/${prospect._id}`, { urls: selectedDocs, resumeType: selected });

    const result = await axios.get(`${process.env.REACT_APP_API_URL}/api/prospects/${prospect._id}`);

    enqueueSnackbar('Les informations ont été mis à jour', { variant: 'success' });

    setSelectedDocs([]);
    setProspect(result.data.prospect);
  };

  return !isSelectIA
    ? (
      <Paper elevation={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Information" {...a11yProps(0)} />
                <Tab label="Expériences" {...a11yProps(1)} />
                <Tab label="Formation" {...a11yProps(2)} />
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} style={{ height: '35rem', overflow: 'scroll' }}>
            <CustomTabPanel value={value} index={0}>
              <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'right', width: '100%', marginBottom: '1rem',
              }}
              >
                <FeedBackButton
                  title="Compléter par l'IA"
                  onClick={() => {
                    setIsSelectIA('INFORMATION');
                  }}
                  icon={<BookIcon />}
                />
              </div>
              <FormForProspect onSubmit={(values) => updateProspect(values)} allJobs={allJobs} prospect={prospect} innerRef={innerRef} isDebounce />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'right', width: '100%', marginBottom: '1rem',
              }}
              >
                <FeedBackButton
                  title="Compléter par l'IA"
                  onClick={() => {
                    setIsSelectIA('EXPERIENCE');
                  }}
                  icon={<BookIcon />}
                />
              </div>
              <FormForExperience isFirstLoading={isFirstLoading} setIsFirstLoading={setIsFirstLoading} onSubmit={(values) => updateProspect(values)} allJobs={allJobs} valuesInitial={prospect?.experiences || []} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'right', width: '100%', marginBottom: '1rem',
              }}
              >
                <FeedBackButton
                  title="Compléter par l'IA"
                  onClick={() => {
                    setIsSelectIA('FORMATION');
                  }}
                  icon={<BookIcon />}
                />
              </div>
              <FormForFormation isFirstLoading={isFirstLoading} setIsFirstLoading={setIsFirstLoading} onSubmit={(values) => updateProspect(values)} allJobs={allJobs} valuesInitial={prospect?.formations || []} />
            </CustomTabPanel>
          </Grid>
        </Grid>
      </Paper>
    )
    : (
      <Paper elevation={6} style={{ marginTop: '1rem' }}>
        <Grid container style={{ marginBottom: '2rem' }} spacing={1}>
          <Grid xs={12} md={12}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setIsSelectIA(false)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid
            xs={12}
            md={12}
            style={{
              display: 'flex', alignContent: 'center', justifyContent: 'space-between', width: '100%',
            }}
          >
            <Typography variant="body2"> Sélectionnez les documents à analyser</Typography>
            <FeedBackButton
              title="Envoyer"
              onClick={getResumeFunction}
              icon={<BookIcon />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <DocumentCategories name="CV" documents={documents?.cv} onClick={handleSelectedDocs} selectedDocs={selectedDocs} />
        </Grid>

        <Grid container spacing={1}>
          <DocumentCategories name="Comptes rendus" documents={documents?.rendu} onClick={handleSelectedDocs} selectedDocs={selectedDocs} />
        </Grid>

        <Grid container spacing={1}>
          <DocumentCategories name="Autres documents" documents={documents?.other} onClick={handleSelectedDocs} selectedDocs={selectedDocs} />
        </Grid>
      </Paper>
    );
}

export default ExperienceAndFormation;
