/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  createContext, useContext, useState,
} from 'react';

const MyContext = createContext();

export const useMyAuth = () => useContext(MyContext);

export function MyAuthentificationProvider({ children }) {
  const [isAuthentificated, setIsAuthentificated] = useState(false);

  return (
    <MyContext.Provider value={{ isAuthentificated, setIsAuthentificated }}>
      {children}
    </MyContext.Provider>
  );
}
