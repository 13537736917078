/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { isEmpty, pickBy } from 'lodash';
import Filters from './Filters';
import Table from './Table';
import RightButtonCreate from '../../Components/RightButtonCreate';
import LeftButtonTools from './LeftButtonTools';

const DEFAULT_ROWS_PER_PAGE = 5;

export default function App() {
  const [missions, setMissions] = useState([]);
  const [sort, setSort] = useState({ field: null, sort: null });
  const [filters, setFilters] = useState({
    name: '', status: [],
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getMission = async () => {
    setIsLoading(true);
    const finalFilters = pickBy(filters, (value) => !isEmpty(value));

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/mission/all`,
      { ...finalFilters, paginate: { page: page + 1, limit: rowsPerPage }, ...(sort?.field && { sort: { [sort.field]: sort.sort } }) },
    );
    setMissions(response.data);
    setIsLoading(false);
  };

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      const updatedRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(updatedRowsPerPage);

      setPage(0);
    },
    [],
  );

  const handleChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage);
    },
    [],
  );

  const handleRequestSort = (event, property, sortOfProperty) => {
    setSort({ field: property, sort: sortOfProperty });
  };

  useEffect(() => {
    getMission();
  }, [page, rowsPerPage, filters, sort]);

  return (
    <>
      <div>
        <div style={{ marginInline: 85, marginTop: '3rem' }}>
          <Filters filters={filters} setFilters={setFilters} />
        </div>
        <div style={{ margin: '4rem' }}>
          <Table
            missions={missions}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            selected={selected}
            setSelected={setSelected}
            handleRequestSort={handleRequestSort}
            sort={sort}
            isLoading={isLoading}
          />
        </div>
      </div>

      <RightButtonCreate getCustomers={getMission} />
      <LeftButtonTools
        getCustomers={getMission}
        selected={selected}
        setSelected={setSelected}
      />
    </>
  );
}
