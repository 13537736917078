/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {
  CardActionArea, IconButton, Menu, MenuItem,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import CardMedia from '@mui/material/CardMedia';
import SubjectIcon from '@mui/icons-material/Subject';
import Tooltip from '@mui/material/Tooltip';
import colors from '../../utils/colors';

const settings = [
  { name: 'Ajouter CV principal', value: 'cvPrincipal' },
  { name: 'Ajouter CV client', value: 'cvCustomer' },
  { name: 'CV', value: 'cv' },
  { name: 'Comptes rendus', value: 'rendu' },
  { name: 'Autres documents', value: 'other' },
];

export default function DocumentList2({
  doc, onClick, onDelete, onSelect, nameDoc, isSelected,
}) {
  const [openMenuLeftBar, setOpenMenuLeftBar] = useState(false);
  const clickDeleteIcon = async (event) => {
    event.stopPropagation();
    return onDelete();
  };

  const clickOnUpdateIcon = async (event) => {
    event.stopPropagation();

    setOpenMenuLeftBar(event.currentTarget);
  };

  return (
    <Card
      sx={{
        maxWidth: 345,
        backgroundColor:
        isSelected
          ? colors.color
          : null,
      }}
      onClick={onClick}
    >
      <CardActionArea>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {onSelect
          && (
          <IconButton onClick={clickOnUpdateIcon}>
            <SettingsIcon />
          </IconButton>
          )}
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={openMenuLeftBar}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={Boolean(openMenuLeftBar)}
            onClose={() => setOpenMenuLeftBar(null)}
          >
            {settings.filter(({ value }) => value !== nameDoc).map(({ name, value }) => (
              <MenuItem
                key={value}
                onClick={async (event) => {
                  event.stopPropagation();
                  await onSelect(doc, value);
                  setOpenMenuLeftBar(null);
                }}
              >
                <Typography variant="body2" textAlign="center">{name}</Typography>
              </MenuItem>
            ))}
          </Menu>
          {onDelete
          && (
          <IconButton onClick={clickDeleteIcon}>
            <DeleteIcon />
          </IconButton>
          )}
        </div>

        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          {['application/octet-stream', 'application/pdf'].includes(doc.file.mimeType)
           && doc?.pdfUrl ? (
             <PictureAsPdfIcon
               sx={{ fontSize: '4rem' }}
               style={{ color: isSelected ? 'white' : colors.color }}
             />

            )
            : doc.file.mimeType
             === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              ? (
                <SubjectIcon
                  sx={{ fontSize: '4rem' }}
                  style={{ color: isSelected ? 'white' : colors.color }}
                />
              ) : (
                <CardMedia
                  component="img"
                  image={doc.pdfUrl}
                  height={116}
                  alt="Image"
                />
              )}
        </div>
        <CardContent style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <Tooltip title={doc.file.fileName}>
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: '1.3rem' }}>
              <Typography
                gutterBottom
                variant="body2"
                color={isSelected ? 'white' : 'text.secondary'}
                align="center"
              >
                {doc.file.fileName}
              </Typography>
            </div>
          </Tooltip>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
