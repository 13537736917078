/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { fr } from 'date-fns/locale'; // Importez la locale française depuis date-fns
import dayjs from 'dayjs';

export default function DateTimePickerViewRenderers({
  onAccept,
  label = 'Choisir une date de rappel',
  initialValue,
  disabled = false,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale={fr}>
      <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
        <DateTimePicker
          label={label}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          ampm={false}
          format="D MMMM, YYYY (HH:mm)"
          autoFocus
          onAccept={(value) => onAccept(new Date(value.$d))}
          {...(initialValue && { value: dayjs(initialValue) })}
          disabled={disabled}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
