import * as React from 'react';

import Grid from '@mui/material/Grid';
import { TextField as MuiTextField, IconButton, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import { inputClasses } from '@mui/material/Input';
import { styled } from '@mui/material/styles';
import { inputLabelClasses } from '@mui/material/InputLabel';
import MultipleSelect from '../../Components/MultipleSelect';
import colors from '../../utils/colors';

const TextField = styled(MuiTextField)(`
  .${inputLabelClasses.root} {
    font-size: 14px;
  }
`);

export default function Filter({
  setFilters, filters,
}) {
  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 2, md: 3 }}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      sx={{
        backgroundColor: colors.white,
        borderRadius: 2,
        boxShadow: 2,
      }}
      paddingBottom={2}
    >
      <Grid item xs={12} md={3}>
        <TextField
          sx={{ width: '90%' }}
          label="Nom, Telephone, Mail"
          variant="standard"
          multiline
          onChange={
            (event) => setFilters((oldFilters) => ({
              ...oldFilters,
              paginate: {
                page: 1,
                limit: 5,
              },
              contact: event.target.value,
            }))
          }
          value={filters.contact}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <MultipleSelect
          values={[
            { value: 'Inactif', color: 'error' },
            { value: 'Actif', color: 'success' },
            { value: 'En Attente', color: 'warning' },
            { value: 'A contacter' }]}
          label="Status"
          selected={filters?.status}
          onChange={async (event) => setFilters((oldFilters) => ({
            ...oldFilters,
            status: event.target.value,
            paginate: {
              page: 1,
              limit: 5,
            },
          }
          ))}
        />
      </Grid>
    </Grid>
  );
}
