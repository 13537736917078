import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TitleActivity from './TitleActivity';

const formattedDate = (dateString) => {
  const date = new Date(dateString);

  return `${date.toLocaleDateString()}
          ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
};

export default function OutlinedCard({
  commentary,
}) {
  return (
    <Box sx={{ minWidth: '95%' }}>
      <Card variant="outlined">
        <CardContent>

          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography key="date" sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                {formattedDate(commentary?.statusDate || commentary?.createdAt)}
              </Typography>
            </div>
            <TitleActivity key="lol" commentary={commentary} />
            <Typography key="commentary" variant="caption">
              {commentary.commentary}
            </Typography>
          </>
        </CardContent>
      </Card>
    </Box>
  );
}
