import React from 'react';
import { Modal, Grid } from '@mui/material';

function ShowPdf({ pdfUrl }) {
  return pdfUrl
    ? (
      <iframe
        src={pdfUrl}
        title="PDF Viewer"
        width="100%"
        height="500rem"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        frameBorder="0"
        // onLoad={() => console.log('puuuute')}
        // onDragEnter={() => console.log('azeazea')}
      />
    )
    : null;
}

export default ShowPdf;
