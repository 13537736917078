/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react';
import {
  Container, Grid, IconButton,
} from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

function FileUploadComponent({
  metadata = {}, callback, url = '/api/upload', isMultiple = true, accept,
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [message, setMessage] = useState('');
  const fileInputRef = useRef(null);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileInputChange = async (event) => {
    setSelectedFiles(event.target.files);

    const filesSelected = event.target.files;

    if (filesSelected?.length) {
      const formData = new FormData();
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < filesSelected.length; i++) {
        formData.append('files', filesSelected[i]);
      }

      formData.append('metadata', JSON.stringify(metadata));

      const options = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}${url}`, formData, options)
        .then(async (response) => {
          setMessage('Fichiers téléchargés avec succès');
          if (callback) {
            await callback(response.data);
          }
          setSelectedFiles([]);
        })
        .catch((error) => {
          setMessage('Une erreur s\'est produite lors du téléchargement des fichiers');
          console.error('Erreur de téléchargement des fichiers :', error);
        });
    }
  };

  return (
    <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={2}>
          {!!selectedFiles?.length && (<CircularProgress />)}
        </Grid>
        <Grid item xs={12} md={2}>
          <input
            type="file"
            id="file-input"
            multiple={isMultiple}
            ref={fileInputRef}
            onChange={handleFileInputChange}
            style={{ display: 'none' }}
            {...(!!accept && { accept })}
          />
          <IconButton onClick={handleFileSelect}>
            <AddCircle sx={{ fontSize: '3rem' }} />
          </IconButton>
        </Grid>

      </Grid>
    </Container>
  );
}

export default FileUploadComponent;
