/* eslint-disable max-len */
import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import CardCommentary from '../CardCommentary';
import Commentary from '../Commentary';

export default function CommentaryComponent({
  commentaries, handleClick, addOrDeleteToFavorite, deleteCommentary, updateCommentary, createAlert, onDeleteAlarm,
}) {
  return (
    <>
      <Grid container spacing={1} sx={{ height: '3rem' }}>
        <Grid item xs={12} md={12}>
          <Typography variant="body1" gutterBottom>
            Suivis
          </Typography>
        </Grid>

      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: 3, height: '13rem', overflow: 'auto' }}>
        {commentaries.length
          ? commentaries.map((commentary) => (
            <Grid item xs={12} md={12} key={commentary}>
              <CardCommentary key={commentary} commentary={commentary} addOrDeleteToFavorite={addOrDeleteToFavorite} deleteCommentary={deleteCommentary} updateCommentary={updateCommentary} createAlert={createAlert} onDeleteAlarm={onDeleteAlarm} />
            </Grid>
          ))
          : null}
        <Grid item xs={12} md={12}>
          <Commentary handleClick={handleClick} />
        </Grid>
      </Grid>
    </>
  );
}
