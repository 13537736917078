/* eslint-disable max-len */
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';

import axios from 'axios';

import FileUploadComponent from '../../../../Components/FileUploadComponent';

import PdfModal from '../../../../Components/PdfModal';
import DocumentsList2 from '../../../../Components/DocumentList';

export default function Prospect({
  prospect, documents, getDocuments, getProspectById, getPrincipalDocuments, onDeleteFile,
}) {
  const [pdfUrl, setPdfUrl] = useState(null);

  const openFile = async (doc) => {
    if (doc.file.fileName.slice(-4) === '.pdf') {
      return setPdfUrl(doc.pdfUrl);
    }
    return window.open(doc.pdfUrl, '_blank');
  };

  const onSelect = async (doc, value) => {
    if (['cvPrincipal', 'cvCustomer'].includes(value)) {
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/prospects`, { ids: [prospect._id], set: { $set: { [value]: doc.file._id } } });
      await getProspectById();
      await getPrincipalDocuments();
    } else {
      await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/file/${doc.file._id}`,
        { metadata: { ...doc.file.metadata, documentType: value } },
      );
    }

    await getProspectById();
    await getDocuments();
  };

  return (
    <Grid container direction="row" spacing={12} style={{ marginTop: '1rem', height: '31.4rem', overflow: 'auto' }}>
      <Grid item xs={4}>
        <Grid container spacing={1} sx={{ marginBottom: 5 }} direction="row">
          <Grid item xs={12} md={12}>
            <Typography variant="body1" component="div" gutterBottom>
              CV
            </Typography>
          </Grid>
          {documents?.cv?.length ? documents.cv.map((doc) => (
            <Grid key={doc.file._id} item xs={12} md={12}>
              <DocumentsList2 doc={doc} key={doc.file._id} onClick={() => openFile(doc)} onDelete={() => onDeleteFile(doc.file._id)} onSelect={onSelect} nameDoc="cv" />
            </Grid>
          )) : null }
          <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <FileUploadComponent metadata={{ prospectId: prospect?._id, documentType: 'cv' }} callback={async () => getDocuments()} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>

        <Grid container spacing={1} sx={{ marginBottom: 5 }}>
          <Grid item xs={12} md={12}>
            <Typography variant="body1" component="div" gutterBottom>
              Comptes rendus
            </Typography>
          </Grid>
          {documents?.rendu?.length ? documents.rendu.map((doc) => (
            <Grid key={doc.file._id} item xs={12} md={12}>
              <DocumentsList2 doc={doc} key={doc.file._id} onClick={() => openFile(doc)} onDelete={() => onDeleteFile(doc.file._id)} onSelect={onSelect} nameDoc="rendu" />
            </Grid>
          )) : null}
          <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <FileUploadComponent metadata={{ prospectId: prospect?._id, documentType: 'rendu' }} callback={async () => getDocuments()} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>

        <Grid container spacing={1} sx={{ marginBottom: 5 }}>
          <Grid item xs={12} md={12}>
            <Typography variant="body1" component="div" gutterBottom>
              Autres documents
            </Typography>
          </Grid>
          {documents?.other?.length ? documents.other.map((doc) => (

            <Grid key={doc.file._id} item xs={12} md={12}>
              <DocumentsList2 doc={doc} key={doc.file._id} onClick={() => openFile(doc)} onDelete={() => onDeleteFile(doc.file._id)} onSelect={onSelect} nameDoc="other" />
            </Grid>
          )) : null }
          <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <FileUploadComponent metadata={{ prospectId: prospect?._id, documentType: 'other' }} callback={async () => getDocuments()} />
          </Grid>
        </Grid>
      </Grid>
      {/* <DocumentsList /> */}
      {Boolean(pdfUrl) && (<PdfModal isOpen={Boolean(pdfUrl)} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} />)}

    </Grid>
  );
}
