/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  Formik, Form, Field, FieldArray,
} from 'formik';
import {
  TextField, Button, IconButton, Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import InputAdornment from '@mui/material/InputAdornment';
import { AddCircle } from '@mui/icons-material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import _ from 'lodash';
import NativeSelect from '../NativeSelect';
import AutocompleteOrCreate from '../AutocompleteOrCreate';
import AutocompleteOrCreateForArray from '../AutoCompleteOrCreateForArray';

import CheckBoxGroups from '../CheckBoxGroups';

const CustomTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiInputBase-root': {
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& input': {
      fontSize: '16px', // Taille de la police souhaitée
      border: 'none', // Supprimer la bordure par défaut
      width: '80%',
    },
  },

  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
}));

function formatNumber(value) {
  // Convertir la valeur en nombre
  const number = parseFloat(value);

  // Vérifier si la valeur est un nombre valide
  if (!number) {
    return value; // Retourner la valeur d'origine si ce n'est pas un nombre
  }

  // Formater le nombre avec 2 chiffres après la virgule
  const formattedNumber = number.toFixed(2);

  // Séparer les parties entière et décimale
  const [integerPart, decimalPart] = formattedNumber.split('.');

  // Ajouter des espaces tous les 3 chiffres avant la virgule
  const integerWithSpaces = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Rejoindre la partie entière formatée avec la partie décimale
  const formattedValue = `${integerWithSpaces}.${decimalPart}`;

  return formattedValue;
}

function FormikMaterialUIExample({
  prospect, onSubmit, innerRef, isButtonSubmit = false, isDisabled = false,
}) {
  const [allJobs, setAllJobs] = useState([]);

  const getAllJobs = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs`);

    setAllJobs(response.data.jobs);
  };

  const addNewJob = async (value) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/jobs`, { jobName: value });

    await getAllJobs();
  };

  useEffect(() => {
    getAllJobs();
  }, []);

  const initialValues = {
    name: prospect?.name || '',
    job: prospect?.job || '',
    jobs: prospect?.jobs || [],
    phoneNumber: prospect?.phoneNumber || '',
    cityName: prospect?.cityName || '',
    zipCode: prospect?.zipCode || '',
    email: prospect?.email || '',
    jobDetail: {
      experienceYear: prospect?.jobDetail?.experienceYear || '',
      salary: {
        min: prospect?.jobDetail?.salary?.min || '',
        max: prospect?.jobDetail?.salary?.max || '',
        actual: prospect?.jobDetail?.salary?.actual || '',
      },
      distanceMaxPlace: prospect?.jobDetail?.distanceMaxPlace || '',
      companySize: {
        grande: prospect?.jobDetail?.companySize?.grande || false,
        moyenne: prospect?.jobDetail?.companySize?.moyenne || false,
        petite: prospect?.jobDetail?.companySize?.petite || false,
      },
      vehicle: {
        vehicleType: prospect?.jobDetail?.vehicle?.vehicleType || '',
        permisType: prospect?.jobDetail?.vehicle?.permisType || '',
      },
    },
  };

  return (
    <Formik innerRef={innerRef} initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, values, setFieldValue }) => (
        <Form onSubmit={(handleSubmit)}>
          <Grid container spacing={1} sx={{ marginBottom: 5 }}>
            <Grid item xs={12} md={12}>
              <Field
                name="name"
                label={(
                  <Typography>
                    Prénom et nom
                  </Typography>
                )}
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            {/* <Grid item xs={12} md={12}>
              <Field name="job">
                {({ field }) => (
                  <AutocompleteOrCreate
                    name="job"
                    label="Métier"
                    options={allJobs.map((job) => ({ label: job }))}
                    createNew={addNewJob}
                    setFieldValue={setFieldValue}
                    isDisabled={isDisabled}
                    valueForm={values?.job}
                    {...field}
                  />
                )}
              </Field>
            </Grid> */}

          </Grid>
          <Grid container spacing={2}>
            <FieldArray name="jobs">
              {({
                remove, push, form,
              }) => (
                <>
                  {values?.jobs?.length > 0
                      && values.jobs.map((job, index) => (
                        <React.Fragment key={index}>
                          <Grid item xs={10} md={10}>
                            <AutocompleteOrCreateForArray
                              key={index}
                              name="job"
                              label={`Métier ${index + 1}`}
                              options={allJobs.map((j) => ({ label: j }))}
                              createNew={addNewJob}
                              setFieldValue={setFieldValue}
                              isDisabled={isDisabled}
                              valueForm={job}
                              push={push}
                              insert={(val) => {
                                const newJobs = [...form.values.jobs];
                                newJobs[index] = val; // Modifier la valeur spécifique

                                setFieldValue('jobs', newJobs); //
                              }}
                            />
                          </Grid>
                          {!isDisabled && (
                          <Grid
                            item
                            xs={2}
                            md={2}
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'end',
                            }}
                          >
                            <IconButton
                              aria-label="Supprimer"
                              onClick={() => remove(index)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          </Grid>
                          )}
                        </React.Fragment>

                      ))}
                  <Grid item xs={12} md={12}>
                    <IconButton onClick={() => push('')}>
                      <Tooltip title="Ajouter un nouveau métier">
                        <AddCircle sx={{ fontSize: '3rem' }} />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </>
              )}
            </FieldArray>
          </Grid>

          <Grid container spacing={1} sx={{ marginBottom: 5 }}>
            {/* <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                Information candidat
              </Typography>
              <StyledLine />
            </Grid> */}
            <Grid item xs={12} md={4}>
              <Field
                name="phoneNumber"
                label="Numéro de téléphone"
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                name="email"
                label="email"
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                name="cityName"
                label="Ville"
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                name="zipCode"
                label="Code postal"
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ marginBottom: 5 }}>
            {/* <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                Détail métier
              </Typography>
              <StyledLine />
            </Grid> */}
            <Grid item xs={12} md={4}>
              <Field name="jobDetail.experienceYear">
                {({ field }) => (
                  <NativeSelect
                    name="jobDetail.experienceYear"
                    defaultValue={values?.jobDetail?.experienceYear}
                    value={values?.jobDetail?.experienceYear}
                    disabled={isDisabled}
                    {...field}
                    label="année d'experience"
                    options={[
                      { value: 0, name: '' },
                      { value: 1, name: '< 1 an' },
                      { value: 2, name: '2 ans' },
                      { value: 3, name: '3 ans' },
                      { value: 4, name: '4 ans' },
                      { value: 5, name: '5 ans' },
                      { value: 6, name: '6 ans' },
                      { value: 7, name: '7 ans' },
                      { value: 8, name: '8 ans' },
                      { value: 9, name: '9 ans' },
                      { value: 10, name: '10 ans et +' },
                    ]}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12} md={4}>
              <Field name="jobDetail.distanceMaxPlace">
                {({ field }) => (
                  <NativeSelect
                    name="jobDetail.distanceMaxPlace"
                    defaultValue={values?.jobDetail?.distanceMaxPlace || 0}
                    value={values?.jobDetail?.distanceMaxPlace || 0}
                    disabled={isDisabled}
                    {...field}
                    label="distance maximum ville"
                    options={[
                      { value: 0, name: '' },
                      { value: 2, name: '< 2 km' },
                      { value: 5, name: '< 5 km' },
                      { value: 10, name: '< 10 km' },
                      { value: 15, name: '< 15 km' },
                      { value: 20, name: '< 20 km' },
                      { value: 30, name: '< 30 km' },
                      { value: 40, name: '< 40 km' },
                      { value: 50, name: '< 50 km' },
                      { value: 100, name: '< 100 km' },
                      { value: 101, name: '100 km et +' },
                    ]}
                  />
                )}
              </Field>
            </Grid>

          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Field>
                {({ field }) => (
                  <CheckBoxGroups
                    isDisabled={isDisabled}
                    name="companySize"
                    {...field}
                    value={values?.jobDetail?.companySize}
                    label="taille d'entreprise souhaité"
                    options={[
                      { value: 'petite', name: 'jobDetail.companySize.petite' },
                      { value: 'moyenne', name: 'jobDetail.companySize.moyenne' },
                      { value: 'grande', name: 'jobDetail.companySize.grande' },
                    ]}
                    style={{ display: 'block', flexDirection: 'row' }}
                    onChange={
                      (value) => setFieldValue(Object.keys(value)[0], value[Object.keys(value)[0]])
}
                  />
                )}
              </Field>
            </Grid>

          </Grid>
          <Grid container spacing={1} sx={{ marginBottom: 5 }}>
            <Grid item xs={12} md={4}>
              <Field
                name="jobDetail.salary.min"
                label="salaire minimum"
                margin="normal"
                as={CustomTextField}
                type="number"
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
                helperText={
                  values?.jobDetail?.salary?.min
                    ? `${formatNumber(values.jobDetail.salary.min * 0.78)} € net` : ''
}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                name="jobDetail.salary.max"
                label="salaire maximum"
                margin="normal"
                as={CustomTextField}
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
                helperText={
                  values?.jobDetail?.salary?.max
                    ? `${formatNumber(values.jobDetail.salary.max * 0.78)} € net` : ''
}
                type="number"
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                name="jobDetail.salary.actual"
                label="salaire actuel"
                margin="normal"
                as={CustomTextField}
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>,
                }}
                helperText={
                  values?.jobDetail?.salary?.actual
                    ? `${formatNumber(values.jobDetail.salary.actual * 0.78)} € net` : ''
}
                type="number"
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ marginBottom: 7 }}>
            {/* <Grid item xs={12} md={12}>
              <Typography variant="h6" gutterBottom>
                Véhicule
              </Typography>
              <StyledLine />
            </Grid> */}
            {/* <Grid item xs={12} md={4}>
                <Field name="jobDetail.vehicle.hasVehicle">
                  {({ field }) => (
                    <NativeSelect
                      name="jobDetail.vehicle.hasVehicle"
                      defaultValue={values?.jobDetail?.vehicle?.hasVehicle}
                      disabled={isDisabled}
                      value={values?.jobDetail?.vehicle?.hasVehicle}
                      {...field}
                      label="Possession de véhicule"
                      options={[
                        { value: '', name: '' },
                        { value: true, name: 'oui' },
                        { value: false, name: 'non' },
                      ]}
                    />
                  )}
                </Field>
              </Grid> */}
            <Grid item xs={12} md={4}>
              <Field name="jobDetail.vehicle.permisType" array>
                {({ field, form }) => (
                  <NativeSelect
                    name="jobDetail.vehicle.permisType"
                    defaultValue={values?.jobDetail?.vehicle?.permisType}
                    disabled={isDisabled}
                    value={values?.jobDetail?.vehicle?.permisType}
                      // isMultiple
                    {...field}
                    label="Type de permis"
                    options={[
                      { value: '', name: '' },
                      { value: 'B', name: 'Permis B' },
                      { value: 'A', name: 'Permis A' },
                      { value: 'C', name: 'Permis C' },
                      { value: 'D', name: 'Permis D' },
                      { value: 'E(B)', name: 'Permis E(B)' },
                      { value: 'E(A)', name: 'Permis E(A)' },
                      { value: 'BE', name: 'Permis BE' },
                      { value: 'C1', name: 'Permis C1' },
                      { value: 'C1E', name: 'Permis C1E' },
                      { value: 'D1', name: 'Permis D1' },
                      { value: 'D1E', name: 'Permis D1E' },
                    ]}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={4}>
              <Field name="jobDetail.vehicle.vehicleType" array>
                {({ field, form }) => (
                  <NativeSelect
                    name="jobDetail.vehicle.vehicleType"
                    defaultValue={values?.jobDetail?.vehicle?.vehicleType}
                    disabled={isDisabled}
                    value={values?.jobDetail?.vehicle?.vehicleType}
                      // isMultiple
                    {...field}
                    label="Véhicule de transport"
                    options={[
                      { value: '', name: '' },
                      { value: 'voiture', name: 'Voiture' },
                      { value: 'velo', name: 'Vélo' },
                      { value: 'bus', name: 'Transport en commun' },
                      { value: 'moto', name: 'Moto' },
                      { value: 'trotinette', name: 'Trotinette électrique' },
                    ]}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
          {isButtonSubmit && (
            <Grid
              container
              spacing={1}
              sx={{ marginTop: 5 }}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Grid item>
                <Button variant="contained" type="submit"> Enregistrer</Button>
              </Grid>
            </Grid>
          )}

        </Form>
      )}
    </Formik>
  );
}

export default FormikMaterialUIExample;
