import * as React from 'react';
import Grid from '@mui/material/Grid';
import {
  TextField as MuiTextField, IconButton, InputAdornment, Button,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { omit } from 'lodash';
import CheckboxGroups from '../../Components/CheckBoxGroups';
import MultipleSelect from '../../Components/MultipleSelect';
import colors from '../../utils/colors';

const TextField = styled(MuiTextField)(`
  .${inputLabelClasses.root} {
    font-size: 14px;
  }
`);

export default function Filter({
  setFilters, filters, allJobs, deleteFilters,
}) {
  return (
    <Grid
      container
      rowSpacing={{ xs: 1, sm: 2, md: 3 }}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      sx={{
        backgroundColor: colors.white,
        borderRadius: 2,
        boxShadow: 2,
      }}
      paddingBottom={2}
    >
      <Grid item xs={12} md={3}>
        <TextField
          sx={{ width: '90%' }}
          label="Nom, Telephone, Mail"
          variant="standard"
          multiline
          onChange={
            (event) => setFilters((oldFilters) => ({
              ...oldFilters,
              paginate: {
                page: 1,
                limit: 5,
              },
              contact: event.target.value,
            }))
          }
          value={filters.contact}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div style={{ marginTop: '1rem' }}>
          <TextField
            sx={{ width: '90%' }}
            label="Ville"
            variant="standard"
            multiline
            onChange={
            (event) => setFilters((oldFilters) => ({
              ...oldFilters,
              paginate: {
                page: 1,
                limit: 5,
              },
              cityName: event.target.value,
            }))
          }
            value={filters.cityName}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          sx={{ width: '90%' }}
          label="Métier"
          variant="standard"
          onChange={
            (event) => setFilters((oldFilters) => ({
              ...(event?.target?.value ? {
                ...oldFilters,
                job: [event.target.value],
              } : { ...omit(oldFilters, ['job']) }),
              paginate: {
                page: 1,
                limit: 5,
              },
            }))
          }
          value={filters.job}
        />
        {/* <MultipleSelect
          onChange={({
            target: { value },
          }) => setFilters((oldFilters) => ({
            ...oldFilters,
            job: value,
            paginate: {
              page: 1,
              limit: 5,
            },
          }))}
          selected={filters.job}
          values={allJobs}
        /> */}
        <div style={{ marginTop: '1rem' }}>
          <MultipleSelect
            values={[
              { value: 'Inactif', color: 'error' },
              { value: 'Actif', color: 'success' },
              { value: 'En Attente', color: 'warning' },
              { value: 'A contacter' }]}
            label="Status"
            selected={filters?.status}
            onChange={async (event) => setFilters((oldFilters) => ({
              ...oldFilters,
              status: event.target.value,
              paginate: {
                page: 1,
                limit: 5,
              },
            }
            ))}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={3}>
        <CheckboxGroups
          onChange={
            (value) => setFilters((oldFilters) => ({
              ...oldFilters,
              exists: { ...oldFilters.exists, ...value },
            }
            ))
          }
          value={filters.exists}
          options={[{ name: 'data.mailFrom', value: 'Candidat mail', label: 'data.mailFrom' },
            { name: 'data.directory', value: 'Candidat CV', label: 'data.directory' },
            { name: 'data.isCreated', value: 'Candidat manuel', label: 'data.isCreated' }]}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Button
          sx={{ background: colors.color }}
          component="label"
          variant="contained"
          startIcon={<CancelIcon />}
          onClick={deleteFilters}
        >
          Supprimer filtres
        </Button>

      </Grid>
    </Grid>
  );
}

const compareObject = (obj, obj2) => {
  Object.keys();
};

const caca = (keys, obj, obj2) => {
  keys.forEach((key) => {
    if (!obj[key] && !obj2[key]) {
      return;
    }
    if (Array.isArray(obj[key])) {
      const firstObject = obj[key];
      if (!obj2[key]) {
        console.log(`ERROR on key: ${key}, obj1: ${obj[key]}, obj2: ${obj2[key]}`);
        return;
      }
      const secondObject = obj2[key];
      firstObject.forEach((objArrayPart, index) => {
        caca(Object.keys(objArrayPart), objArrayPart, secondObject[index]);
      });

      return;
    }

    if (typeof obj[key] === 'object' && !!obj2[key]) {
      caca(Object.keys(obj[key]), obj[key], obj2[key]);

      return;
    }

    if (obj[key] === obj2[key]) {
      return;
    }

    console.log(`ERROR on key: ${key}, obj1: ${obj[key]}, obj2: ${obj2[key]}`);
  });
};
