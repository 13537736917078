import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import StartIcon from '@mui/icons-material/Start';
import moment from 'moment';
import 'moment/locale/fr'; // Importe la locale française pour moment.js
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TodayIcon from '@mui/icons-material/Today';
import {
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import colors from '../../utils/colors';
import Modal from '../../Components/Modal';
import FormAlert from '../../Components/FormAlert';
import FormAlertCompleted from './FormAlertCompleted';

// Configure le localizer pour utiliser moment.js avec la locale française
const localizer = momentLocalizer(moment);

// Données d'exemple pour le calendrier

const messages = {
  day: 'Jour',
  week: 'Semaine',
  month: 'Mois',
  today: <TodayIcon fontSize="small" />,
  previous: <ArrowBackIcon fontSize="small" />,
  next: <StartIcon fontSize="small" />,
  date: 'Date',
  time: 'Heur',
  event: 'Événement',
};

const removeEmptyProperties = (obj) => Object.keys(obj).reduce((acc, key) => {
  if (
    obj[key] !== ''
          && obj[key] !== 0
          && obj[key] !== null
          && obj[key] !== undefined
  ) {
    return { ...acc, [key]: obj[key] };
  }
  return acc;
}, {});

function MyCalendar() {
  const [alerts, setAlerts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const minTime = new Date().setHours(8, 0, 0); // Heure de début à 6h00
  const maxTime = new Date().setHours(23, 0, 0); //

  const handleEventClick = (event) => {
    setSelectedAlert(event);
    console.log('Événement cliqué:', event);
  };

  const getAlerts = async () => {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/alert/all`,
      { dateBegin: new Date(2000, 11, 1, 10, 0), dateFinish: new Date(2040, 11, 1, 10, 0) },
    );

    setAlerts(result.data.alert.map(
      (alert, index) => ({
        title: alert?.type ? `${alert?.type} ${alert?.name || ''} ` : `Événement ${index + 1}`,
        start: new Date(alert.dateBegin),
        ...alert,
        _id: alert._id,
        end: alert?.dateFinish
          ? new Date(alert.dateFinish)
          : new Date(new Date(alert.dateBegin).getTime() + 60 * 60 * 1000),
      }),
    ));
  };
  useEffect(() => {
    getAlerts();
  }, []);

  const onSubmit = async (form) => {
    const body = removeEmptyProperties(form);

    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/alert`,
      { ...body, type: body?.type || 'Rappel' },
    );

    await getAlerts();
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        sx={{ background: colors.color, marginLeft: '3rem' }}
        component="label"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setIsModalOpen(true)}
      >
        Ajouter RDV
      </Button>
      <div style={{ height: '500px' }}>

        <Calendar
          localizer={localizer}
          events={alerts}
          startAccessor="start"
          endAccessor="end"
          style={{ margin: '50px' }}
          onSelectEvent={(event) => handleEventClick(event)}
          messages={messages}
          min={minTime} // Définit l'heure de début pour la vue quotidienne
          max={maxTime}
          eventPropGetter={(myEventsList) => {
            const initialStyle = { fontSize: '12px' };

            if (myEventsList.type === 'Rdv physique') {
              return { style: { backgroundColor: '#D32F2F', ...initialStyle } };
            }
            if (myEventsList.type === 'Rdv client') {
              return { style: { backgroundColor: '#ED6C03', ...initialStyle } };
            }
            if (myEventsList.type === 'Rdv téléphonique') {
              return { style: { backgroundColor: '#2F7C31', ...initialStyle } };
            }

            return { style: { backgroundColor: 'grey', ...initialStyle } };
          }}
        />
      </div>
      <Modal
        handleClose={() => {
          setIsModalOpen(false);
          setSelectedAlert(null);
        }}
        isOpen={isModalOpen}
      >
        <FormAlert onSubmit={onSubmit} mission={selectedAlert} />

      </Modal>
      <Modal
        handleClose={() => {
          setSelectedAlert(null);
        }}
        isOpen={selectedAlert}
      >
        <FormAlertCompleted onSubmit={onSubmit} mission={selectedAlert} />

      </Modal>
    </>
  );
}

export default MyCalendar;
