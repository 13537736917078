import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ModeIcon from '@mui/icons-material/Mode';
import { useNavigate } from 'react-router-dom';
import CustomizedSteppers from '../../../../../Components/Stepper';

const stepNumberOfMission = (stepForProspect) => {
  if (stepForProspect?.approved?.length) {
    return 6;
  }

  if (stepForProspect?.customerInterview?.length) {
    return 5;
  }

  if (stepForProspect?.customerProposal?.length) {
    return 4;
  }

  if (stepForProspect?.phoningCall?.length) {
    return 3;
  }

  if (stepForProspect?.firstContact?.length) {
    return 2;
  }

  if (stepForProspect?.selected?.length) {
    return 1;
  }
  return 0;
};

export default function MediaCard({
  value, deleteMission, updateMission,
}) {
  const navigate = useNavigate();

  return (
    <Card>
      {/* <CardMedia
        sx={{ height: 140 }}
        image="/static/images/cards/contemplative-reptile.jpg"
        title="green iguana"
      /> */}
      <CardContent>
        {value?.jobs?.length
        && (
        <Typography variant="body1" color="text.primary" marginBottom="1rem">
          {value.jobs.map((job, index) => (index === value.jobs.length - 1 ? job : `${job}, `))}
        </Typography>
        )}
        <CustomizedSteppers
          selected={false}
          setSelected={() => navigate(`/missions/${value._id}`)}
          prospectsFind={value}
          // getProspectByMission={getProspectByMission}
          step={stepNumberOfMission(value?.stepForProspect)}
        />
        {value?.contactFromCustomer?.name
        && (
        <Typography variant="body2" color="text.secondary" marginTop="1rem">
          contact:
          {' '}
          {value.contactFromCustomer?.name}
        </Typography>
        )}
        {value?.minExperienceYear
        && (
        <Typography variant="body2" color="text.secondary">
          expérience:
          {' '}
          {value.minExperienceYear}
          {' '}
          années
        </Typography>
        )}
      </CardContent>
      <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton aria-label="delete" onClick={updateMission}>
          <ModeIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={deleteMission}>
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
