import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import colors from '../../utils/colors';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '75%',
  bgcolor: 'background.paper',
  border: '2px solid',
  boxShadow: 24,
  backgroundColor: colors.background,
  p: 4,
  borderRadius: '5px',
  overflow: 'auto',
};

export default function BasicModal({
  handleClose, isOpen, children, width = '70%', height = '75%',
}) {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, width, height }}>
        {children}
      </Box>
    </Modal>
  );
}
