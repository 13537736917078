/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  createContext, useContext, useEffect, useState, useMemo,
} from 'react';
import { useDebounce } from '@uidotdev/usehooks';
import debounce from 'lodash/debounce';
import { SnackbarProvider, useSnackbar } from 'notistack';

const MyContext = createContext();

export const useMyContext = () => useContext(MyContext);

export function MyContextProvider({ children }) {
  const [openDrawing, setOpenDrawing] = useState(false);
  const [idWorker, setIdWorker] = useState(null);
  const [functionDebounce, setFunctionDebounce] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(
    () => {
      async function pollUntilTrue() {
        if (functionDebounce) {
          const result = await functionDebounce.func(functionDebounce.params);

          if (!result) {
            // Si le résultat n'est pas encore vrai, attendez une seconde et rappelez la fonction
            setTimeout(() => pollUntilTrue(), 5000);
          } else {
            setFunctionDebounce(null);
            // Si le résultat est vrai, faites quelque chose

            [
              'Vos candidats ont été créée avec succès',
            ].map((value) => enqueueSnackbar(value, { variant: 'success' }));
            console.log('La condition est vraie!');
          }
        }
      }
      pollUntilTrue();
    },
    [functionDebounce],
  );

  return (
    <MyContext.Provider value={{ openDrawing, setOpenDrawing, setFunctionDebounce }}>
      {children}
    </MyContext.Provider>
  );
}
