/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Badge, styled } from '@mui/material';
import CardForProspect from '../ProspectEstimate/CardForProspect';

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

function CustomTabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ mission, getProspectByMission }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', maxHeight: '22rem', overflow: 'auto' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <StyledBadge
            badgeContent={mission?.declineProspect?.cvRefusal?.length || 0}
            color="warning"
            onClick={() => handleChange('', 0)}
          >
            <Tab label="Refus CV" {...a11yProps(0)} />
          </StyledBadge>
          <StyledBadge
            badgeContent={mission?.declineProspect?.customerRefusal?.length || 0}
            color="warning"
            onClick={() => handleChange('', 1)}
          >
            <Tab label="Refus client" {...a11yProps(1)} />
          </StyledBadge>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Grid container spacing={2}>

          {mission?.declineProspect?.cvRefusal?.length
            ? mission.declineProspect.cvRefusal.map((prospect) => (
              <Grid item xs={12} md={12}>
                <CardForProspect
                  title="Candidat"
                  value={prospect}
                  missionId={mission._id}
                  getProspectByMission={getProspectByMission}
                  isRefusedActive={false}
                  selected="cvRefusal"
                />
              </Grid>
            )) : null}
        </Grid>

      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Grid container spacing={2}>

          {mission?.declineProspect?.customerRefusal?.length
            ? mission.declineProspect.customerRefusal.map((prospect) => (
              <Grid item xs={12} md={12}>
                <CardForProspect
                  title="Candidat"
                  value={prospect}
                  missionId={mission._id}
                  getProspectByMission={getProspectByMission}
                  isRefusedActive={false}
                  selected="customerRefusal"
                />
              </Grid>
            )) : null}
        </Grid>
      </CustomTabPanel>
    </Box>
  );
}
