/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */

import React, { useState, useMemo } from 'react';
import {
  Grid, Chip, Grow, Box, Card, CardContent, IconButton, Modal,
} from '@mui/material';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { useSnackbar } from 'notistack';
import CardForProspect from './CardForProspect';
import CustomizedSteppers from '../../../../../Components/Stepper';
import { ENUM_MATCH } from '../../../../../utils/enums/enumsStepper';
import colors from '../../../../../utils/colors';
import FormForSelectProspect from './FormForSelectProspect';
import FormForStepMission from './FormForStepMission';
import FormForRefusedMission from './FormForRefusedMission';
import FormForSendMaid from './FormForSendMail';

const style = {
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '30%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: '6rem',
};

const stepNumberOfMission = (stepForProspect) => {
  if (stepForProspect?.approved?.length) {
    return 6;
  }

  if (stepForProspect?.customerInterview?.length) {
    return 5;
  }

  if (stepForProspect?.customerProposal?.length) {
    return 4;
  }

  if (stepForProspect?.phoningCall?.length) {
    return 3;
  }

  if (stepForProspect?.firstContact?.length) {
    return 2;
  }

  if (stepForProspect?.selected?.length) {
    return 1;
  }
  return 0;
};

function ProspectEstimate({
  prospects,
  mission,
  getMission,
  isFavorite = false,
  createAlert,
  getProspectByMission,
  prospectsFind,
  getHistorics,
}) {
  const [selected, setSelected] = useState(ENUM_MATCH.matching);
  const [isCreateActive, setIsCreateActive] = useState(false);
  const [prospectsSelected, setProspectsSelected] = useState([]);
  const [isEvolveModalOpen, setIsEvolveModalOpen] = useState(false);
  const [isRefusedModalOpen, setIsRefusedModalOpen] = useState(false);
  const [isSendMailModelOpen, setIsMailSendModelOpen] = useState(false);
  const [isUnblockSend, setIsUnblockSend] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const step = useMemo(
    () => stepNumberOfMission(prospectsFind?.stepForProspect),
    [prospectsFind?.stepForProspect, prospectsFind?.declineProspect],
  );

  const addProspect = async (id) => {
    const result = await axios.patch(
      `${process.env.REACT_APP_API_URL}/api/mission/${mission._id}`,
      { prospectSelected: [...mission.prospectSelected, id] },
    );

    if (getHistorics) {
      await getHistorics();
    }
    await getMission();
  };

  const deleteProspect = async (id) => {
    const result = await axios.patch(
      `${process.env.REACT_APP_API_URL}/api/mission/${mission._id}`,
      { prospectSelected: mission.prospectSelected.filter((prospect) => id !== prospect) },
    );

    if (getHistorics) {
      await getHistorics();
    }

    await getMission();
  };

  const onSubmit = async (formData) => {
    await axios.patch(
      `${process.env.REACT_APP_API_URL}/api/mission/${prospectsFind._id}/prospect/${formData.prospectId}`,
      {
        stepTo: `stepForProspect.${selected}`,
      },
    );

    await getProspectByMission();

    if (getHistorics) {
      await getHistorics();
    }

    setIsCreateActive(false);
  };

  const sendMailToProspects = async (mailTemplates, title, isUpdated) => {
    if (isUpdated && !isUnblockSend) {
      enqueueSnackbar('Le template de mail a été modifié, si vous recliquez cela sera envoyé avec les modifications', { variant: 'warning' });
      setIsUnblockSend(true);
    } else {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/gmail/send`,
        {
          emailsAndInfos: prospectsSelected.map((prospect) => ({
            email: prospect?.email, mission, prospect, customer: mission?.customer, contact: mission?.contact,
          })),
          blocks: mailTemplates,
          title,
        },
      );

      enqueueSnackbar('Les mails ont été envoyé avec succès', { variant: 'success' });

      setIsMailSendModelOpen(false);
    }
  };

  const onClickCheckBox = (prospectClicked) => {
    const restProspect = prospectsSelected.filter((prospec) => prospec._id !== prospectClicked._id);

    if (restProspect.length === prospectsSelected.length) {
      return setProspectsSelected([...prospectsSelected, prospectClicked]);
    }
    return setProspectsSelected(restProspect);
  };

  const onSubmitEvolved = async (formData, isRefusedActive = true) => {
    await Promise.all(prospectsSelected.map(async (prospectSelected) => axios.patch(
      `${process.env.REACT_APP_API_URL}/api/mission/${mission._id}/prospect/${prospectSelected._id}`,
      {
        ...formData,
        stepFrom: isRefusedActive
          ? `stepForProspect.${selected}`
          : `declineProspect.${selected}`,
      },
    )));

    await getProspectByMission();

    if (getHistorics) {
      await getHistorics();
    }

    setIsEvolveModalOpen(false);
    setProspectsSelected([]);
  };

  const onSubmitRefused = async (formData) => {
    await Promise.all(prospectsSelected.map(async (prospectSelected) => axios.patch(
      `${process.env.REACT_APP_API_URL}/api/mission/${mission._id}/prospect/${prospectSelected._id}`,
      {
        ...formData,
        stepFrom: `stepForProspect.${selected}`,
        stepTo: [ENUM_MATCH.matching,
          ENUM_MATCH.firstContact,
          ENUM_MATCH.phoningCall,
          ENUM_MATCH.firstContact,
          ENUM_MATCH.selected,
        ].includes(selected) ? 'declineProspect.cvRefusal' : 'declineProspect.customerRefusal',
      },
    )));

    await getProspectByMission();

    if (getHistorics) {
      await getHistorics();
    }

    setIsRefusedModalOpen(false);
    setProspectsSelected([]);
  };

  console.log('caca', prospectsSelected);

  return (
    <>
      <CustomizedSteppers
        selected={selected}
        setSelected={setSelected}
        prospectsFind={prospectsFind}
        getProspectByMission={getProspectByMission}
        step={step}
      />

      <Grid item xs={12} md={12}>
        <div style={{
          display: 'flex', justifyContent: 'end', margin: '1rem', gap: '1rem',
        }}
        >

          {prospectsSelected?.length
            ? (
              <>
                <Chip
                  label="Refuser le(s) candidat(s)"
                  onClick={() => setIsRefusedModalOpen(true)}
                  style={{ background: colors.color, color: 'white' }}
                  icon={<KeyboardDoubleArrowDownIcon style={{ color: 'white' }} />}
                />
                <Chip
                  label="Évoluer le(s) candidat(s)"
                  onClick={() => setIsEvolveModalOpen(true)}
                  style={{ background: colors.color, color: 'white' }}
                  icon={<KeyboardDoubleArrowUpIcon style={{ color: 'white' }} />}
                />
                <Chip
                  label="Envoyer un mail"
                  onClick={() => setIsMailSendModelOpen(true)}
                  style={{ background: colors.colorThird, color: 'white' }}
                  icon={<ForwardToInboxIcon style={{ color: 'white' }} />}
                />
              </>
            ) : null}
          {selected !== ENUM_MATCH.matching
      && (

      <Chip
        label="Ajouter un candidat"
        onClick={() => setIsCreateActive(true)}
        style={{ background: colors.colorSecondary, color: 'white' }}
        icon={<AddIcon style={{ color: 'white' }} />}
      />
      )}

        </div>
        <Grow in={isCreateActive} mountOnEnter unmountOnExit>

          <Box sx={{ minWidth: '95%', margin: '1rem' }}>

            <Card variant="outlined">
              <CardContent>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <IconButton>
                    <CloseIcon onClick={() => setIsCreateActive(false)} />
                  </IconButton>
                </div>
                <FormForSelectProspect onSubmit={onSubmit} />
              </CardContent>
            </Card>
          </Box>
        </Grow>
      </Grid>
      <Collapse orientation="vertical" in={prospectsFind?.stepForProspect?.[selected]?.length} sx={{ marginTop: '2rem' }}>

        <Grid
          container
          spacing={2}
          sx={{
            maxHeight: '40rem',
            overflow: 'auto',
          }}
        >

          {
          prospectsFind?.stepForProspect?.[selected]?.length
            ? prospectsFind?.stepForProspect?.[selected].map((prospect) => (
              <Grid item xs={12} md={4}>
                <CardForProspect
                  title="Candidat"
                  value={prospect}
                  missionId={mission._id}
                  addProspect={addProspect}
                  deleteProspect={deleteProspect}
                  isFavorite={isFavorite}
                  createAlert={createAlert}
                  selected={selected}
                  getProspectByMission={getProspectByMission}
                  getHistorics={getHistorics}
                  onClickCheckBox={onClickCheckBox}
                  isCheckBoxSelected={prospectsSelected.find(({ _id }) => prospect._id === _id)}
                />
              </Grid>
            )) : null
}
        </Grid>
      </Collapse>

      <Modal open={isEvolveModalOpen} onClose={() => setIsEvolveModalOpen(false)}>
        <Fade in={isEvolveModalOpen}>
          <Box sx={style}>
            <FormForStepMission stepFrom={selected} onSubmit={onSubmitEvolved} />
          </Box>
        </Fade>
      </Modal>
      <Modal open={isRefusedModalOpen} onClose={() => setIsRefusedModalOpen(false)}>
        <Fade in={isRefusedModalOpen}>
          <Box sx={style}>
            <FormForRefusedMission stepFrom={selected} onSubmit={onSubmitRefused} />
          </Box>
        </Fade>
      </Modal>
      <Modal open={isSendMailModelOpen} onClose={() => setIsMailSendModelOpen(false)}>
        <Fade in={isSendMailModelOpen}>
          <Box sx={{ ...style, width: '70%' }}>
            <FormForSendMaid stepFrom={selected} onSubmit={sendMailToProspects} />
          </Box>
        </Fade>
      </Modal>

    </>
  );
}

export default ProspectEstimate;
