/* eslint-disable max-len */
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';

import Grid from '@mui/material/Grid';

import axios from 'axios';

import FileUploadComponent from '../../../../../Components/FileUploadComponent';

import PdfModal from '../../../../../Components/PdfModal';
import DocumentsList2 from '../../../../../Components/DocumentList';

export default function CustomerDocuments({ mission, documents, getDocuments }) {
  const [pdfUrl, setPdfUrl] = useState(null);

  const openFile = async (doc) => {
    if (doc.file.fileName.slice(-4) === '.pdf') {
      return setPdfUrl(doc.pdfUrl);
    }
    return window.open(doc.pdfUrl, '_blank');
  };

  const onDeleteFile = async (id) => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/file/${id}`);
    await getDocuments();
  };

  const onSelect = async (doc, value) => {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/api/file/${doc.file._id}`,
      { metadata: { ...doc.file.metadata, documentType: value } },
    );

    await getDocuments();
  };

  return (
    <Grid container>

      <Grid item xs={12} md={12} marginBottom="2rem">
        <Typography variant="body1" gutterBottom>
          Documents
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid container direction="row" spacing={8}>
          <Grid item xs={4} md={4}>
            <Grid container spacing={1} sx={{ marginBottom: 5 }}>
              <Grid item xs={12} md={12}>
                <Typography variant="body2" component="div" gutterBottom>
                  Descriptif d&lsquo;entreprises
                </Typography>
              </Grid>
              {documents?.cv?.length ? documents.cv.map((doc) => (
                <Grid key={doc.file._id} item xs={12} md={6}>
                  <DocumentsList2 doc={doc} key={doc} onClick={() => openFile(doc)} onDelete={() => onDeleteFile(doc.file._id)} onSelect={onSelect} />
                </Grid>
              )) : null }
              <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FileUploadComponent metadata={{ missionId: mission?._id, documentType: 'cv' }} callback={async () => getDocuments()} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} md={4}>

            <Grid container spacing={1} sx={{ marginBottom: 5 }}>
              <Grid item xs={12} md={12}>
                <Typography variant="body2" component="div" gutterBottom>
                  Fiches de postes ou annonces
                </Typography>
              </Grid>
              {documents?.rendu?.length ? documents.rendu.map((doc) => (
                <Grid key={doc.file._id} item xs={12} md={6}>
                  <DocumentsList2 doc={doc} key={doc} onClick={() => openFile(doc)} onDelete={() => onDeleteFile(doc.file._id)} onSelect={onSelect} />
                </Grid>
              )) : null}
              <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FileUploadComponent metadata={{ missionId: mission?._id, documentType: 'rendu' }} callback={async () => getDocuments()} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} md={4}>

            <Grid container spacing={1} sx={{ marginBottom: 5 }}>
              <Grid item xs={12} md={12}>
                <Typography variant="body2" component="div" gutterBottom>
                  Autres documents
                </Typography>
              </Grid>
              {documents?.other?.length ? documents.other.map((doc) => (

                <Grid key={doc.file._id} item xs={12} md={6}>
                  <DocumentsList2 doc={doc} key={doc} onClick={() => openFile(doc)} onDelete={() => onDeleteFile(doc.file._id)} onSelect={onSelect} />
                  {/* <ItemClickable
              sx={{
                clickableItem: {
                  cursor: 'pointer',
                },
              }}
              onClick={async () => openFile(doc)}
            >
              {doc.file.fileName || 'N/A'}

            </ItemClickable> */}
                </Grid>
              )) : null }
              <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FileUploadComponent metadata={{ missionId: mission?._id, documentType: 'other' }} callback={async () => getDocuments()} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <DocumentsList /> */}
      {Boolean(pdfUrl) && (<PdfModal isOpen={Boolean(pdfUrl)} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} />)}

    </Grid>
  );
}
