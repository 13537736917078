/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { isEmpty, pickBy } from 'lodash';

import Table from './Table';
import Filter from './Filter';

import RightButtonCreate from '../../Components/RightButtonCreate';
import LeftButtonTools from './LeftButtonTools';

const DEFAULT_ROWS_PER_PAGE = 5;

export default function App() {
  const [prospects, setProspects] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [selected, setSelected] = useState([]);
  const [sort, setSort] = useState({ field: null, sort: null });
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    name: '', job: [], status: [], cityName: '', exists: { 'data.mailFrom': false, 'data.directory': false, 'data.isCreated': false },
  });
  const [allJobs, setAllJobs] = useState([]);
  const [dense, setDense] = useState(true);

  const getJobs = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs`);

    setAllJobs(response.data.jobs);
  };

  useEffect(() => {
    getJobs();
  }, []);

  const getProspects = async () => {
    setIsLoading(true);
    const finalFilters = pickBy(filters, (value) => !isEmpty(value));

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/prospects/all`,
      { ...finalFilters, paginate: { page: page + 1, limit: rowsPerPage }, ...(sort?.field && { sort: { [sort.field]: sort.sort } }) },
    );
    setProspects(response.data);
    setIsLoading(false);
  };

  const handleChangePage = React.useCallback(
    (event, newPage) => {
      setPage(newPage);
    },
    [],
  );

  const handleChangeRowsPerPage = React.useCallback(
    (event) => {
      const updatedRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(updatedRowsPerPage);

      setPage(0);
    },
    [],
  );

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleRequestSort = (event, property, sortOfProperty) => {
    setSort({ field: property, sort: sortOfProperty });
  };

  const deleteFilters = () => {
    setPage(0);
    setRowsPerPage(DEFAULT_ROWS_PER_PAGE);
    setFilters({
      name: '', job: [], status: [], exists: { 'data.mailFrom': false, 'data.directory': false, 'data.isCreated': false },
    });
    setSort({ field: null, sort: null });
  };

  useEffect(() => {
    getProspects();
  }, [page, rowsPerPage, filters, sort]);

  return (
    <div>
      <div style={{ marginInline: 85, marginTop: '3rem' }}>
        <Filter
          setFilters={setFilters}
          filters={filters}
          allJobs={allJobs}
          deleteFilters={deleteFilters}
          dense={dense}
          handleChangeDense={handleChangeDense}
        />
      </div>
      <div style={{ margin: '4rem' }}>
        <Table
          prospects={prospects}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          setSelected={setSelected}
          selected={selected}
          getProspects={getProspects}
          dense={dense}
          handleRequestSort={handleRequestSort}
          sort={sort}
          isLoading={isLoading}
        />
      </div>

      <RightButtonCreate getProspects={getProspects} />
      <LeftButtonTools
        getProspects={getProspects}
        selected={selected}
        setSelected={setSelected}
        allJobs={allJobs}
      />

    </div>
  );
}
