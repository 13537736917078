/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import {
  Formik, Form, Field,
} from 'formik';
import {
  TextField, Button, Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import NativeSelect from '../../../../../Components/NativeSelect';
import colors from '../../../../../utils/colors';

const CustomTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiInputBase-root': {
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& input': {
      fontSize: '16px', // Taille de la police souhaitée
      border: 'none', // Supprimer la bordure par défaut
      width: '80%',
    },
  },

  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
}));

function FormForStepMission({
  stepFrom, onSubmit, innerRef, isButtonSubmit = false, isDisabled = false,
}) {
  return (
    <Formik innerRef={innerRef} onSubmit={onSubmit} initialValues={{}}>
      {({ handleSubmit, values }) => (
        <Form onSubmit={(handleSubmit)}>
          <Grid container spacing={1} sx={{ marginBottom: 5 }}>
            <Grid item xs={12} md={12}>
              <Field
                name="commentary"
                label={(
                  <Typography>
                    Ajouter un commentaire
                  </Typography>
                )}
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                multiline
                rows={2}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            sx={{ marginTop: 5 }}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Grid item>
              <Button
                style={{
                  background: colors.color,
                  display: 'flex',
                  justifyContent: 'end',
                }}
                variant="contained"
                type="submit"
              >
                {' '}
                Enregistrer
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default FormForStepMission;
