/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import CustomerModal from './CustomerModal';
import Paper from '../../../Components/Paper';

export default function App() {
  const params = useParams();
  const navigate = useNavigate();
  const [customerSelected, setCustomerSelected] = useState(null);

  const getCustomer = async () => {
    if (params.id) {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/customer/${params.id}`,
      );
      setCustomerSelected(result.data.prospect);
    }
  };

  useEffect(() => {
    getCustomer();
  }, [params?.id]);

  return (
    <div style={{ padding: '2rem', paddingInline: '4rem' }}>

      { Boolean(customerSelected) && (
      <>
        <IconButton onClick={() => navigate(-1)} style={{ marginBottom: '1rem' }}>
          <ArrowBackIcon />
        </IconButton>
        <CustomerModal customer={customerSelected} setCustomer={setCustomerSelected} />
      </>
      )}
    </div>
  );
}
