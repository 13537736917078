/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function ChipsArray({ chipData, onDelete }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
    >
      {chipData.map((data) => (
        <ListItem key={data.key}>
          <Chip
            icon={data?.icon}
            label={data.label}
            {...(onDelete && { onDelete: () => onDelete(data) })}
          />
        </ListItem>
      ))}
    </div>
  );
}
