/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
/* eslint-disable max-len */

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import {
  deepOrange, green, red,
} from '@mui/material/colors';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import axios from 'axios';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Tooltip, Typography } from '@mui/material';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import BadgeIcon from '@mui/icons-material/Badge';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import WorkIcon from '@mui/icons-material/Work';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import ToggleButton from '../../../../Components/ToggleButton';
import Select from '../../../../Components/MultipleSelect';
import DatePicker from '../../../../Components/DatePicker';
import FormForCustomer from '../../../../Components/FormForCustomer';
import CustomerDocuments from './Documents';
import ContactsComponent from './Contacts';
import { objectToQueryString } from '../../../../utils/allFunction';
import CommentaryComponent from '../../../../Components/CommentaryComponent';
import Paper from '../../../../Components/Paper';
import MissionComponent from './Missions';
import HistoricActivity from '../../../../Components/HistoricActivity';

const allButtons = [
  { value: 'information', title: 'Information', icons: <BadgeIcon /> },
  { value: 'contact', title: "Contact de l'entreprise", icons: <ContactEmergencyIcon /> },
  { value: 'mission', title: 'Missions', icons: <WorkIcon /> },
  { value: 'document', title: 'Documents', icons: <PermMediaIcon /> },
  { value: 'commentaire', title: 'Commentaires', icons: <InsertCommentIcon /> },
];

function CustomerModal({ customer, setCustomer }) {
  const [documents, setDocuments] = useState([]);
  const [historics, setHistorics] = useState([]);

  const [commentaries, setCommentaries] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const formRef = useRef(null);

  const getCommentaries = async () => {
    if (customer._id) {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/commentary/prospect/${customer._id}?${objectToQueryString({ page: 1, limit: 100 })}`);
      setCommentaries(response.data.data);
    }
  };

  const handleClick = async (text) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/commentary`, { prospectId: customer._id, commentary: text });
    setCommentaries([response.data.commentary, ...commentaries]);
  };

  const addOrDeleteToFavorite = async (commentary) => {
    await axios.patch(`${process.env.REACT_APP_API_URL}/api/commentary/${commentary._id}`, { isFavorie: !commentary?.isFavorie });

    await getCommentaries();
  };

  const deleteCommentary = async (commentary) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/commentary/${commentary._id}`, { isFavorie: !commentary?.isFavorie });

    await getCommentaries();
  };

  const updateCommentary = async (id, body) => {
    await axios.patch(`${process.env.REACT_APP_API_URL}/api/commentary/${id}`, body);

    await getCommentaries();
  };

  const createAlert = async (body) => {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/api/alert`, { ...body, customerId: customer._id });

    return result.data.alert;
  };

  const onDeleteAlarm = async (commentary) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/alert/${commentary.alert._id}`);

    await axios.patch(`${process.env.REACT_APP_API_URL}/api/commentary/unset/${commentary._id}`, { alert: '' });

    await getCommentaries();
  };

  const getDocuments = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/file/customer/${customer._id}`);

    setDocuments(res.data);
  };

  const callRef = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
      setIsEdit(false);
    }
  };

  const cancelRef = () => {
    if (formRef.current) {
      formRef.current.resetForm();
      setIsEdit(false);
    }
  };

  const updateCustomer = async (values) => {
    function removeEmptyProperties(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (
            obj[key] === ''
                || obj[key] === 0
                || obj[key] === null
                || obj[key] === undefined
          ) {
            console.log(1.5);

            delete obj[key];
          } else if (typeof obj[key] === 'object' && !(obj[key] instanceof Date)) {
            removeEmptyProperties(obj[key]);

            if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
            }
          }
        }
      }
      return obj;
    }

    const coco = removeEmptyProperties(values);

    const result = await axios.patch(`${process.env.REACT_APP_API_URL}/api/customer/${customer._id}`, coco);

    setCustomer(result.data.prospect);
  };

  const getHistorics = async () => {
    if (customer._id) {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/historic/all`, { customerId: customer._id });

      setHistorics([...(response?.data?.data || []), ...(customer?.createdAt !== customer?.updatedAt ? [{ createdAt: customer.updatedAt, title: 'Dernière modification' }] : []), { createdAt: customer.createdAt, title: 'Création du client' }]);
    }
  };

  useEffect(() => {
    getCommentaries();
    getDocuments();
    getHistorics();
  }, [customer._id]);

  return (
    <>
      <Paper elevation={5} paddingTheme="2%">
        <Grid container spacing={1} sx={{ marginBottom: 5 }}>
          <Grid item xs={3} md={1}>
            <Avatar sx={{ bgcolor: deepOrange[500] }}>{customer?.name?.split(' ')?.slice(0, 2)?.map((name) => name[0]) }</Avatar>
          </Grid>
          <Grid md={8} xs={4} />
          <Grid item xs={5} md={3}>
            <Select
              isMenuProps={false}
              values={[{ value: 'Inactif', color: 'error' },
                { value: 'Actif', color: 'success' },
                { value: 'En Attente', color: 'warning' },
                { value: 'A contacter' }]}
              isMultiple={false}
              label="Status"
              selected={customer?.status}
              onChange={async (event) => updateCustomer({ status: event.target.value, statusDate: new Date() })}
            />
          </Grid>
          <Grid item xs={7} md={9} />
          <Grid item xs={5} md={3}>
            <DatePicker
              value={customer?.statusDate}
              onChange={async (e) => updateCustomer({ statusDate: e.toDate() })}
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={1} sx={{ marginBottom: 5 }}>
          <Grid item xs={12}>
            <ToggleButton page={pageProspect} setPage={setPageProspect} allButtons={allButtons} />
          </Grid>
        </Grid> */}
      </Paper>

      <Grid container marginTop="1rem" spacing={2}>
        <Grid item xs={8} md={8}>
          <Grid container>
            <Paper elevation={5}>
              <Grid item xs={12} md={12}>
                <Typography variant="body1" gutterBottom>
                  Informations
                </Typography>

              </Grid>
              <Grid item xs={2} md={1}>
                <div style={{ textAlign: 'right', width: '100%' }}>
                  {isEdit ? (
                    <>
                      <IconButton onClick={callRef}>
                        <Tooltip title="Valider">
                          <CheckCircleOutlineIcon style={{ color: green[500] }} />
                        </Tooltip>
                      </IconButton>
                      <IconButton onClick={cancelRef}>
                        <Tooltip title="Annuler">
                          <HighlightOffIcon style={{ color: red[500] }} />
                        </Tooltip>
                      </IconButton>
                    </>
                  )
                    : (
                      <IconButton onClick={() => setIsEdit(true)}>
                        <Tooltip title="Modifier">
                          <ModeEditOutlineIcon />
                        </Tooltip>
                      </IconButton>
                    )}
                </div>
              </Grid>
              <FormForCustomer
                onSubmit={(values) => updateCustomer(values)}
                customer={customer}
                innerRef={formRef}
                isDisabled={!isEdit}
              />
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={4} md={4}>
          <Grid container direction="row-reverse" spacing={2}>
            <Grid item xs={12} md={12}>
              <Paper elevation={5}>
                <HistoricActivity historics={historics} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={12}>
              <Paper elevation={5}>
                <CommentaryComponent commentaries={commentaries} handleClick={handleClick} addOrDeleteToFavorite={addOrDeleteToFavorite} deleteCommentary={deleteCommentary} updateCommentary={updateCommentary} createAlert={createAlert} onDeleteAlarm={onDeleteAlarm} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      <Grid container spacing={2} marginTop="2rem">
        <Grid item xs={4} md={4}>
          <Paper elevation={5}>
            <ContactsComponent
              customerId={customer._id}
            />
          </Paper>
        </Grid>
        <Grid item xs={8} md={8}>
          <Paper elevation={5} paddingTheme="2.3%">
            <MissionComponent
              customerId={customer._id}
            />
          </Paper>
        </Grid>
      </Grid>
      <Paper elevation={5} style={{ marginTop: '1rem' }}>
        <CustomerDocuments documents={documents} getDocuments={getDocuments} customer={customer} />
      </Paper>
    </>
  );
}

export default CustomerModal;
