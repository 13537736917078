/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import MissionModal from './MissionModal';
import Paper from '../../../Components/Paper';

export default function App() {
  const [missionSelected, setMissionSelected] = useState(null);
  const navigate = useNavigate();
  const params = useParams();

  const getMission = async () => {
    if (params?.id) {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/mission/${params.id}`,
      );

      setMissionSelected(response.data.prospect);
    }
  };

  useEffect(() => {
    getMission();
  }, [params?.id]);

  return (
    <div style={{ padding: '2rem', paddingInline: '4rem' }}>
      {missionSelected && (
      <>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <MissionModal mission={missionSelected} getMission={getMission} />
      </>
      )}
    </div>
  );
}
