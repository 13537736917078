import React from 'react';

import Grid from '@mui/material/Grid';

import WorkerForCandidatComponent from './WorkerForCandidatComponent';

export default function JobPage() {
  return (
    <div style={{
      marginInline: '4rem', justifyContent: 'center', alignContent: 'center', alignItems: 'center',
    }}
    >

      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
      >
        <WorkerForCandidatComponent />
      </Grid>

    </div>
  );
}
