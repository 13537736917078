/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import NativeSelect from '../NativeSelect';

const CustomTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiInputBase-root': {
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& input': {
      fontSize: '16px', // Taille de la police souhaitée
      border: 'none', // Supprimer la bordure par défaut
      width: '80%',
    },
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
}));

function FormikMaterialUIExample({
  customer, onSubmit, innerRef, isButtonSubmit = false, isDisabled = false,
}) {
  const initialValues = {
    name: customer?.name || '',
    phoneNumber: customer?.phoneNumber || '',
    siret: customer?.siret || '',
    siren: customer?.siren || '',
    cityName: customer?.cityName || '',
    zipCode: customer?.zipCode || '',
    email: customer?.email || '',
    size: customer?.size || '',
    legalStatus: customer?.legalStatus || '',
    numberOfSalaries: customer?.numberOfSalaries || '',
    tva: customer?.tva || '',
    ape: customer?.ape || '',
    rcs: customer?.rcs || '',
    address: customer?.address || '',
  };

  return (
    <Formik innerRef={innerRef} initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, values, setFieldValue }) => (
        <Form onSubmit={(handleSubmit)}>
          <Grid container spacing={1} sx={{ marginBottom: 5 }}>
            <Grid item xs={12} md={12}>
              <Field
                name="name"
                label={(
                  <Typography>
                    {'Nom de l\'entreprise'}
                  </Typography>
                )}
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ marginBottom: 5 }}>
            <Grid item xs={12} md={4}>
              <Field name="size">
                {({ field }) => (
                  <NativeSelect
                    name="size"
                    defaultValue={values?.jobDetail?.distanceMaxPlace || 0}
                    value={values?.jobDetail?.distanceMaxPlace || 0}
                    disabled={isDisabled}
                    {...field}
                    label="Taille"
                    options={[
                      { value: '', name: '' },
                      { value: 'tpe', name: 'TPE' },
                      { value: 'pme', name: 'PME' },
                      { value: 'groupe', name: 'Groupe' },
                      { value: 'filiale', name: 'Filiale' },
                    ]}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} md={4}>
              <Field name="numberOfSalaries">
                {({ field }) => (
                  <NativeSelect
                    name="numberOfSalaries"
                    defaultValue={values?.jobDetail?.distanceMaxPlace || 0}
                    value={values?.jobDetail?.distanceMaxPlace || 0}
                    disabled={isDisabled}
                    {...field}
                    label="Nombre de salariés"
                    options={[
                      { value: '', name: '' },
                      { value: '19', name: '0 à 19' },
                      { value: '49', name: '20 à 49' },
                      { value: '99', name: '50 à 99' },
                      { value: '199', name: '100 à 199' },
                      { value: '249', name: '200 à 249' },
                      { value: '250+', name: '250+' },
                    ]}
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={0} md={8} />
            <Grid item xs={12} md={6}>
              <Field
                name="siret"
                label="siret"
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="siren"
                label="siren"
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="tva"
                label="N°TVA Intra"
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="rcs"
                label="RCS"
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="ape"
                label="Code APE ou NAF"
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="legalStatus"
                label="Forme juridique"
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ marginBottom: 5 }}>
            <Grid item xs={12} md={4}>
              <Field
                name="phoneNumber"
                label="Numéro de téléphone"
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Field
                name="email"
                label="email"
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Field
                name="address"
                label="Adresse"
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Field
                name="cityName"
                label="Ville"
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Field
                name="zipCode"
                label="Code postal"
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
          </Grid>

          {isButtonSubmit && (
            <Grid
              container
              spacing={1}
              sx={{ marginTop: 5 }}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Grid item>
                <Button variant="contained" type="submit"> Enregistrer</Button>
              </Grid>
            </Grid>
          )}

        </Form>
      )}
    </Formik>
  );
}

export default FormikMaterialUIExample;
