/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
/* eslint-disable max-len */
import axios from 'axios';
import {
  Typography, Box, Tabs, Tab, IconButton,
} from '@mui/material';
import BookIcon from '@mui/icons-material/Book';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import _ from 'lodash';
// import FormForExperience from '../FormForExperience';
// import FormForFormation from '../FormForFormation';
import DownloadIcon from '@mui/icons-material/Download';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useSnackbar } from 'notistack';
import Paper from '../../../../../Components/Paper';
import FeedBackButton from '../../../../../Components/FeedbackButton';
import DocumentCategories from '../../../../../Components/DocumentsCategories';
import FormForProspect from '../../../FormForProspect';
import ShowPdf from './ShowPdf';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function DocumentShowAndIA({
  documentsPrincipal, prospect, setProspect, documents, getPrincipalDocuments, refDocument,
}) {
  const formRef = useRef(null);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isSelectIA, setIsSelectIA] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [value, setValue] = React.useState(0);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleChange = (event, newValue) => {
    setIsFirstLoading(true);
    setValue(newValue);
  };

  const handleSelectedDocs = (doc) => {
    if (selectedDocs.find((url) => url === doc.pdfUrl)) {
      return setSelectedDocs(selectedDocs.filter((url) => url !== doc.pdfUrl));
    }

    return setSelectedDocs([...selectedDocs, doc.pdfUrl]);
  };

  const getResumeFunction = async () => {
    const selected = isSelectIA;
    setIsSelectIA(false);
    setSelectedDocs([]);

    enqueueSnackbar('Patientez le document va se mettre à jour', { variant: 'warning' });

    await axios.patch(`${process.env.REACT_APP_API_URL}/api/prospects/resume/${prospect._id}`, { urls: selectedDocs, resumeType: selected });

    const result = await axios.get(`${process.env.REACT_APP_API_URL}/api/prospects/${prospect._id}`);

    enqueueSnackbar('Le document a été mis à jour', { variant: 'success' });

    await getPrincipalDocuments();
    setProspect(result.data.prospect);
  };

  return !isSelectIA
    ? (
      <Paper elevation={5}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="CV actuel" {...a11yProps(0)} />
                <Tab label="Résumé IA" {...a11yProps(1)} />
                <Tab label="CV Interne" {...a11yProps(2)} />
                <Tab label="Compte rendu" {...a11yProps(3)} />
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} style={{ height: '35rem', overflow: 'scroll' }}>
            <CustomTabPanel value={value} index={0}>
              { documentsPrincipal?.cvPrincipal?.pdfUrl
                ? <ShowPdf pdfUrl={documentsPrincipal?.cvPrincipal?.pdfUrl} />
                : (
                  <div style={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',
                  }}
                  >
                    <IconButton onClick={() => refDocument.current?.scrollIntoView({ behavior: 'smooth' })}>
                      <PostAddIcon style={{ fontSize: '3rem' }} />
                    </IconButton>
                  </div>
                )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'right', width: '100%', marginBottom: '1rem',
              }}
              >
                <FeedBackButton
                  title="Compléter par l'IA"
                  onClick={() => {
                    setIsSelectIA('RESUME_CV');
                  }}
                  icon={<BookIcon />}
                />
              </div>
              {/* <FormForExperience isFirstLoading={isFirstLoading} setIsFirstLoading={setIsFirstLoading} onSubmit={(values) => updateProspect(values)} allJobs={allJobs} valuesInitial={prospect?.experiences || []} innerRef={formRef} /> */}
              {prospect?.summary
        && (
        <Grid item xs={12} md={12}>
          <div style={{
            display: 'flex', alignContent: 'center', justifyContent: 'left', width: '100%',
          }}
          >
            <Typography
              variant="body1"
              style={{ whiteSpace: 'pre-line' }}
            >
              {prospect.summary}
            </Typography>
          </div>

        </Grid>
        )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <div style={{
                display: 'flex', alignItems: 'center', justifyContent: documentsPrincipal?.cvCustomer?.pdfUrl ? 'space-between' : 'right', width: '100%', marginBottom: '1rem',
              }}
              >
                {documentsPrincipal?.cvCustomer?.pdfUrl && (
                <FeedBackButton
                  title=".docx"
                  onClick={() => {
                    window.open(documentsPrincipal?.cvCustomer?.pdfUrl);
                  }}
                  icon={<DownloadIcon />}
                />
                )}
                <FeedBackButton
                  title="Compléter par l'IA"
                  onClick={() => {
                    setIsSelectIA('INTERNE_CV');
                  }}
                  icon={<BookIcon />}
                />
              </div>
              <ShowPdf pdfUrl={documentsPrincipal?.cvCustomerPdf?.pdfUrl || documentsPrincipal?.cvCustomer?.pdfUrl} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <div style={{
                display: 'flex', alignItems: 'center', justifyContent: documentsPrincipal?.compteRendu?.pdfUrl ? 'space-between' : 'right', width: '100%', marginBottom: '1rem',
              }}
              >
                {documentsPrincipal?.compteRendu?.pdfUrl && (
                <FeedBackButton
                  title=".docx"
                  onClick={() => {
                    window.open(documentsPrincipal?.compteRendu?.pdfUrl);
                  }}
                  icon={<DownloadIcon />}
                />
                )}
                <FeedBackButton
                  title="Compléter par l'IA"
                  onClick={() => {
                    setIsSelectIA('COMPTE_RENDU');
                  }}
                  icon={<BookIcon />}
                />
              </div>
              <ShowPdf pdfUrl={documentsPrincipal?.compteRenduPdf?.pdfUrl || documentsPrincipal?.compteRendu?.pdfUrl} />
            </CustomTabPanel>
          </Grid>
        </Grid>
      </Paper>
    )
    : (
      <Paper elevation={6} style={{ marginTop: '1rem' }}>
        <Grid container style={{ marginBottom: '2rem' }} spacing={1}>
          <Grid xs={12} md={12}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setIsSelectIA(false)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid
            xs={12}
            md={12}
            style={{
              display: 'flex', alignContent: 'center', justifyContent: 'space-between', width: '100%',
            }}
          >
            <Typography variant="body2"> Sélectionnez les documents à analyser</Typography>
            <FeedBackButton
              title="Envoyer"
              onClick={getResumeFunction}
              icon={<BookIcon />}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <DocumentCategories name="CV" documents={documents?.cv} onClick={handleSelectedDocs} selectedDocs={selectedDocs} />
        </Grid>

        <Grid container spacing={1}>
          <DocumentCategories name="Comptes rendus" documents={documents?.rendu} onClick={handleSelectedDocs} selectedDocs={selectedDocs} />
        </Grid>

        <Grid container spacing={1}>
          <DocumentCategories name="Autres documents" documents={documents?.other} onClick={handleSelectedDocs} selectedDocs={selectedDocs} />
        </Grid>
      </Paper>
    );
}

export default DocumentShowAndIA;
