import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import StarRateIcon from '@mui/icons-material/StarRate';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ModeIcon from '@mui/icons-material/Mode';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import Grid from '@mui/material/Grid';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import { Chip } from '@mui/material';
import Commentary from '../Commentary';
import Modal from '../Modal';
import DateAndHourPicker from '../DateAndHourPicker';
import Select from '../MultipleSelect';

const formattedDate = (dateString) => {
  const date = new Date(dateString);

  return `${date.toLocaleDateString()} 
          ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
};

export default function OutlinedCard({
  commentary, addOrDeleteToFavorite, deleteCommentary, updateCommentary, createAlert, onDeleteAlarm,
}) {
  const [modifyId, setModifyId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(null);
  const [dateToUpdate, setDateToUpdate] = useState({ type: 'Rappel' });

  const runUpdateCommentary = async (text) => {
    await updateCommentary(commentary._id, { commentary: text });

    setDateToUpdate({ type: 'Rappel' });
    setModifyId(null);
    setIsModalOpen(null);
  };

  const runUpdateDateAlarm = async (text) => {
    const result = await createAlert(dateToUpdate);

    await updateCommentary(
      commentary._id,
      { alert: { _id: result._id, ...dateToUpdate } },
    );
    setDateToUpdate(null);
    setModifyId(null);
    setIsModalOpen(null);
  };

  const openModal = (id) => {
    setModifyId(null);
    setIsModalOpen(id);
  };

  return (
    <Box sx={{ minWidth: '95%' }}>
      <Card variant="outlined">
        <CardContent>
          {modifyId ? (
            <Commentary
              handleClick={runUpdateCommentary}
              textInitial={commentary.commentary}
              onClose={() => setModifyId(null)}
              label="Modifier un commentaire"
            />
          ) : (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
                  {formattedDate(commentary.createdAt)}
                </Typography>
                {commentary?.alert?.dateBegin && (
                <Chip
                  avatar={<AccessAlarmIcon />}
                  label={formattedDate(commentary?.alert?.dateBegin)}
                  color={[{ value: 'Rdv physique', color: 'error' },
                    { value: 'Rdv téléphonique', color: 'success' },
                    { value: 'Rdv client', color: 'warning' },
                    { value: 'Rappel', color: 'default' }].find(
                    ({ value }) => value === commentary.alert.type,
                  )?.color}
                  onDelete={() => onDeleteAlarm(commentary)}
                />
                )}
              </div>
              <Typography variant="body2">
                {commentary.commentary}
              </Typography>
            </>
          )}
        </CardContent>
        {!isModalOpen && !modifyId
          && (
            <CardActions>
              <IconButton aria-label="delete" onClick={() => addOrDeleteToFavorite(commentary)}>
                {commentary?.isFavorie ? <StarRateIcon /> : <StarBorderIcon />}
              </IconButton>
              <IconButton aria-label="delete" onClick={() => openModal(commentary)}>
                <AccessAlarmIcon />
              </IconButton>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setModifyId(commentary._id);
                  setIsModalOpen(null);
                }}
              >
                <ModeIcon />
              </IconButton>
              <IconButton aria-label="delete" onClick={() => deleteCommentary(commentary)}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </CardActions>
          )}
        {isModalOpen && (
        <Grid
          container
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          margin="1rem"
        >
          <Grid item xs={2}>
            <Select
              isMenuProps={false}
              values={[{ value: 'Rdv physique', color: 'error' },
                { value: 'Rdv téléphonique', color: 'success' },
                { value: 'Rdv client', color: 'warning' },
                { value: 'Rappel' }]}
              isMultiple={false}
              label="Type de RDV"
              selected={dateToUpdate?.type}
              onChange={(event) => setDateToUpdate({ ...dateToUpdate, type: event.target.value })}
            />
          </Grid>
          <Grid item xs={8}>
            <DateAndHourPicker
              onAccept={(value) => setDateToUpdate({ ...dateToUpdate, dateBegin: value })}
            />
          </Grid>

          <Grid
            item
            xs={1}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <IconButton aria-label="delete" onClick={() => setIsModalOpen(null)}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <IconButton
              aria-label="delete"
              onClick={runUpdateDateAlarm}
              disabled={!dateToUpdate}
            >
              <SendIcon />
            </IconButton>
          </Grid>
        </Grid>
        )}

      </Card>
    </Box>
  );
}
