/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import axios from 'axios';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import Paper from '../../../Components/Paper';
import EmailInput from '../../../Components/EmailInput';
import ProspectComponent from './ProspectModal';

export default function App() {
  const navigate = useNavigate();
  const params = useParams();
  const [prospect, setProspect] = React.useState(null);
  const [documentsPrincipal, setDocumentsPrincipal] = React.useState({ cvPrincipal: null, cvCustomer: null });

  const updateProspectById = async (id, body) => {
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/api/prospects/${id}`,
      body,
    );

    setProspect(response?.data?.prospect);
  };

  const getProspectById = async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/prospects/${id}`,
    );

    setProspect(response?.data?.prospect);
  };

  const getPrincipalDocuments = async () => {
    await getProspectById(params.id);
    let allDocuments = {};
    if (prospect?.compteRendu) {
      // const cvPrincipal = await axios.get(`${process.env.REACT_APP_API_URL}/api/file/${prospect?.cvPrincipal}`);
      // const cvCustomer = await axios.get(`${process.env.REACT_APP_API_URL}/api/file/${prospect?.cvCustomer}`);
      const compteRendu = await axios.get(`${process.env.REACT_APP_API_URL}/api/file/${prospect?.compteRendu}`);

      if (compteRendu?.data?.file?.metadata?.documentLink) {
        const compteRenduPdf = await axios.get(`${process.env.REACT_APP_API_URL}/api/file/${compteRendu.data.file.metadata.documentLink}`);

        allDocuments = { ...allDocuments, compteRendu: compteRendu.data, compteRenduPdf: compteRenduPdf.data };
      } else {
        allDocuments = { ...allDocuments, compteRendu: compteRendu.data };
      }
    }
    if (prospect?.cvPrincipal) {
      // const cvPrincipal = await axios.get(`${process.env.REACT_APP_API_URL}/api/file/${prospect?.cvPrincipal}`);
      // const cvCustomer = await axios.get(`${process.env.REACT_APP_API_URL}/api/file/${prospect?.cvCustomer}`);
      const cvPrincipal = await axios.get(`${process.env.REACT_APP_API_URL}/api/file/${prospect?.cvPrincipal}`);

      if (cvPrincipal?.data?.file?.metadata?.documentLink) {
        const cvPrincipalPdf = await axios.get(`${process.env.REACT_APP_API_URL}/api/file/${cvPrincipal.data.file.metadata.documentLink}`);

        allDocuments = { ...allDocuments, cvPrincipal: cvPrincipal.data, cvPrincipalPdf: cvPrincipalPdf.data };
      } else {
        allDocuments = { ...allDocuments, cvPrincipal: cvPrincipal.data };
      }
    }

    if (prospect?.cvCustomer) {
      // const cvPrincipal = await axios.get(`${process.env.REACT_APP_API_URL}/api/file/${prospect?.cvPrincipal}`);
      // const cvCustomer = await axios.get(`${process.env.REACT_APP_API_URL}/api/file/${prospect?.cvCustomer}`);
      const cvCustomer = await axios.get(`${process.env.REACT_APP_API_URL}/api/file/${prospect?.cvCustomer}`);

      if (cvCustomer?.data?.file?.metadata?.documentLink) {
        const cvCustomerPdf = await axios.get(`${process.env.REACT_APP_API_URL}/api/file/${cvCustomer.data.file.metadata.documentLink}`);

        allDocuments = { ...allDocuments, cvCustomer: cvCustomer.data, cvCustomerPdf: cvCustomerPdf.data };
      } else {
        allDocuments = { ...allDocuments, cvCustomer: cvCustomer.data };
      }
    }

    setDocumentsPrincipal(allDocuments);
  };

  useEffect(() => {
    if (params?.id) {
      updateProspectById(params.id, { isFirstOpen: false });
    }
  }, [params.id]);

  useEffect(() => {
    getPrincipalDocuments();
  }, [prospect?.cvPrincipal, prospect?.cvCustomer, prospect?.compteRendu]);

  return (

    <div style={{ padding: '2rem', paddingInline: '4rem' }}>
      {prospect
          && (
          <>
            <IconButton onClick={() => navigate(-1)} style={{ marginBottom: '1rem' }}>
              <ArrowBackIcon />
            </IconButton>
            <ProspectComponent
              prospect={prospect}
              setProspect={setProspect}
              updateProspectById={updateProspectById}
              getPrincipalDocuments={getPrincipalDocuments}
              documentsPrincipal={documentsPrincipal}
            />
            {/* <Paper elevation={6} sx={{ marginTop: '1rem' }}>
              <EmailInput />
            </Paper> */}
          </>
          )}
    </div>

  );
}
