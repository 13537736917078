import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import colors from '../../utils/colors';

const style = {
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid',
  boxShadow: 24,
  backgroundColor: colors.background,
  p: 4,
  borderRadius: '5px',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
};

export default function BasicModal({
  handleClose, isOpen, children, onValid, disabled = false,
}) {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

    >
      <Box
        sx={style}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            {children}
          </Grid>

        </Grid>

        <Grid container sx={{ marginTop: '5rem' }}>
          <Grid
            item
            xs={6}
            md={6}
            style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
          >
            <Button variant="contained" color="error" onClick={handleClose}>Refuser</Button>
          </Grid>
          <Grid
            item
            xs={6}
            md={6}
            style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              color="success"
              onClick={onValid}
              disabled={disabled}
            >
              Valider

            </Button>
          </Grid>
        </Grid>

      </Box>
    </Modal>
  );
}
