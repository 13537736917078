/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';

export default function FirstComponent({ onChange, value }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="fr"
        inputFormat="dd-MMMM-yyyyy"
      >
        <DatePicker
          value={value ? dayjs(value) : null}
          onChange={onChange}
          slotProps={{
            textField: {
              variant: 'standard',
              InputProps: {
                readOnly: true,
                disabled: true,
              },
              sx: {
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: '#000000',
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </div>
  );
}
