/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import {
  Formik, Form, Field, FieldArray,
} from 'formik';
import {
  TextField, Button, IconButton, Tooltip, Grid,
} from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useDebounce } from '@uidotdev/usehooks';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import _ from 'lodash';
import Paper from '../../../../../Components/Paper';

const CustomTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiInputBase-root': {
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& input': {
      fontSize: '14px', // Taille de la police souhaitée
      border: 'none', // Supprimer la bordure par défaut
      width: '80%',
    },
  },

  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
}));

const generateId = () => `_${Math.random().toString(36).substr(2, 9)}`;

// Function to generate an empty form object
const generateEmptyForm = (value) => ({
  id: value?._id || generateId(),
  name: value?.name || '',
  job: value?.job || '',
  cityName: value?.cityName || '',
  zipCode: value?.zipCode || '',
  experienceYear: value?.experienceYear || '',
  description: value?.descriptions || '',
});

function FormComponent({
  isDisabled = false, onSubmit, valuesInitial, isFirstLoading, setIsFirstLoading, innerRef,
}) {
  const [formValues, setFormValues] = useState(valuesInitial?.length
    ? valuesInitial.map((value) => generateEmptyForm(value))
    : [generateEmptyForm()]);

  const debouncedFormValues = useDebounce(formValues, 500);

  useEffect(() => {
    if (isFirstLoading) {
      setIsFirstLoading(false);
    } else {
      onSubmit({
        formations: formValues?.length
          ? formValues.map(({ id, ...other }) => other)
          : [],
      });
    }
  }, [debouncedFormValues]);

  useEffect(() => {
    setFormValues(valuesInitial?.length
      ? valuesInitial.map((value) => generateEmptyForm(value))
      : [generateEmptyForm()]);
  }, []);

  return formValues?.length
    ? (
      <Formik
        innerRef={innerRef}
        initialValues={{ forms: formValues }}
        // onSubmit={(values) => console.log(values)}
        enableReinitialize
      >
        {({ values, setValues }) => (
          <Form>
            {/* FieldArray for form items */}
            <FieldArray name="forms">
              {({ push, remove }) => (
                <>
                  {/* Display existing form items */}
                  {values.forms?.map((form, index) => (
                    <Paper paddingTheme="1%" sx={{ marginBottom: '1rem' }}>
                      <Grid container spacing={2} key={form?.id || form._id}>
                        {/* Field for name */}
                        <Grid item xs={12} md={8}>
                          <Field
                            name={`forms.${index}.name`}
                            label="Nom de l'entreprise"
                            margin="normal"
                            as={CustomTextField}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                            onChange={(e) => {
                              const newFormValues = formValues.map(
                                (formV, idx) => (idx === index
                                  ? { ...formV, name: e.target.value }
                                  : formV),
                              );
                              setValues({ ...values, forms: newFormValues });
                              setFormValues(newFormValues);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Field
                            name={`forms.${index}.experienceYear`}
                            label="Année d'expérience"
                            margin="normal"
                            as={CustomTextField}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type="number"
                            variant="standard"
                            onChange={(e) => {
                              const newFormValues = formValues.map(
                                (formV, idx) => (idx === index
                                  ? { ...formV, experienceYear: e.target.value }
                                  : formV),
                              );
                              setValues({ ...values, forms: newFormValues });
                              setFormValues(newFormValues);
                            }}
                          />
                        </Grid>
                        {/* Field for city name */}
                        <Grid item xs={12} md={4}>
                          <Field
                            name={`forms.${index}.cityName`}
                            label="Ville"
                            margin="normal"
                            as={CustomTextField}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                            onChange={(e) => {
                              const newFormValues = formValues.map(
                                (formV, idx) => (idx === index
                                  ? { ...formV, cityName: e.target.value }
                                  : formV),
                              );
                              setValues({ ...values, forms: newFormValues });
                              setFormValues(newFormValues);
                            }}
                          />
                        </Grid>
                        {/* Field for zip code */}
                        <Grid item xs={12} md={4}>
                          <Field
                            name={`forms.${index}.zipCode`}
                            label="Code postal"
                            margin="normal"
                            as={CustomTextField}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                            onChange={(e) => {
                              const newFormValues = formValues.map(
                                (formV, idx) => (idx === index
                                  ? { ...formV, zipCode: e.target.value }
                                  : formV),
                              );
                              setValues({ ...values, forms: newFormValues });
                              setFormValues(newFormValues);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Field
                            name={`forms.${index}.description`}
                            label="Description"
                            margin="normal"
                            as={CustomTextField}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            variant="standard"
                            multiline
                            rows={2}
                            onChange={(e) => {
                              const newFormValues = formValues.map(
                                (formV, idx) => (idx === index
                                  ? { ...formV, description: e.target.value }
                                  : formV),
                              );
                              setValues({ ...values, forms: newFormValues });
                              setFormValues(newFormValues);
                            }}
                          />
                        </Grid>
                        {/* Button to remove this form item */}
                        <Grid
                          item
                          xs={12}
                          md={12}
                          sx={{ display: 'flex', alignItems: 'right', justifyContent: 'right' }}
                        >
                          <IconButton
                            onClick={() => {
                              remove(index);
                              const newArray = [...formValues];
                              _.pullAt(newArray, index);
                              setFormValues(newArray);
                            }}
                          >
                            <Tooltip title="Remove">
                              <DeleteOutlineOutlinedIcon />
                            </Tooltip>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}
                  {/* Button to add a new form item */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <IconButton onClick={() => {
                      const newObject = generateEmptyForm();
                      push(newObject);
                      setFormValues([...formValues, newObject]);
                    }}
                    >
                      <Tooltip title="Ajouter une Formation">
                        <AddCircle sx={{ fontSize: '3rem' }} />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    )
    : null;
}

export default FormComponent;
