import * as React from 'react';

import {
  useNavigate,
} from 'react-router-dom';

import MainContent from './Main';

export default function PersistentDrawerLeft({ isAuthenticated, children }) {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, []);

  return (
    <MainContent>
      {children}
    </MainContent>
  );
}
