import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import FormWorker from './FormWorker';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  paddingInline: '2rem',
}));

export default function DividerStack() {
  const [workers, setWorkers] = useState([]);
  const getWorkers = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/worker`);

    setWorkers(response.data.workers);
  };

  const updateWorker = async (id, worker) => {
    await axios.patch(`${process.env.REACT_APP_API_URL}/api/worker/${id}`, worker);
    await getWorkers();
  };

  const deleteWorker = async (id) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/worker/${id}`);
    await getWorkers();
  };

  const createWorker = async (value) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/worker`, { ...value, isActive: true });
    await getWorkers();
  };
  useEffect(() => {
    getWorkers();
  }, []);
  return (
    <Grid container spacing={3}>
      {!workers?.length ? (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
          }}
        >
          <Item>
            <FormWorker onSubmit={(value) => createWorker(value)} />
          </Item>
        </Grid>
      )
        : workers.map((worker) => (
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              listStyle: 'none',
            }}
          >
            <Item>
              <FormWorker
                updateSwitch={updateWorker}
                isDisabled
                valuesInitital={worker}
                onDelete={deleteWorker}
              />
            </Item>
          </Grid>
        ))}

    </Grid>
  );
}
