/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useMemo, useEffect } from 'react';
import {
  Formik, Form, Field,
} from 'formik';
import {
  TextField, Button, Typography, Checkbox, FormControlLabel,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import SaveIcon from '@mui/icons-material/Save';
import MailTemplate from './MailTemplate';
import colors from '../../../../../../utils/colors';

const CustomTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiInputBase-root': {
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& input': {
      fontSize: '16px', // Taille de la police souhaitée
      border: 'none', // Supprimer la bordure par défaut
      width: '80%',
    },
  },

  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
}));

function CheckboxField({ field, label }) {
  return (
    <Checkbox
      {...field}
      checked={field.value || false}
      onChange={field.onChange}
      label={label}
    />
  );
}

function FormForSendMaid({
  stepFrom, onSubmit, innerRef, isButtonSubmit = false, isDisabled = false,
}) {
  const [isTemplate, setIsTemplate] = useState(true);
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [isTextChange, setIsTextChange] = useState(false);

  return (
    <Grid container spacing={1} sx={{ marginBottom: 5 }}>
      <Grid item xs={12} md={12}>
        <FormControlLabel
          control={(
            <Checkbox
              defaultChecked
              checked={isTemplate}
              onChange={(value) => setIsTemplate(value.target.checked)}
            />
)}
          label="Utiliser un template de mail"
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <MailTemplate
          text={text}
          setText={setText}
          setTitle={setTitle}
          title={title}
          textChangeFunc={() => { setIsTextChange(true); }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: 'flex', alignItems: 'center', justifyContent: 'right', marginTop: '2rem',
        }}
      >
        <Button
          sx={{ background: colors.color }}
          component="label"
          variant="contained"
          startIcon={<SaveIcon />}
          disabled={!text}
          onClick={async () => onSubmit(text, title, isTextChange)}
        >
          Envoyer
        </Button>

      </Grid>
    </Grid>
  );
}

export default FormForSendMaid;
