/* eslint-disable no-nested-ternary */
import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import _ from 'lodash';
import Chip from '@mui/material/Chip';
import TableSortLabel from '@mui/material/TableSortLabel';
import {
  useNavigate,
} from 'react-router-dom';
import TableSkeleton from '../../Components/TableSkeleton';
import colors from '../../utils/colors';
import Modal from '../../Components/Modal';

// import ProspectComponent from './ProspectModal';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nom',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'phoneNumber',
    numeric: true,
    disablePadding: false,
    label: 'Téléphone',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Adresse e-mail',
  },
  {
    id: 'statusDate',
    numeric: true,
    disablePadding: false,
    label: 'Date de status',
  },
];

const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = 'calories';

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order, orderBy, numSelected, rowCount, onRequestSort, selected, isAllSelected, sort,
  } = props;
  const createSortHandler = (newOrderBy) => (event) => {
    onRequestSort(event, newOrderBy, sort.sort === 'asc' ? 'desc' : 'asc');
  };

  return (
    <TableHead sx={{ background: colors.linear }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            // indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={isAllSelected}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{ color: 'white', fontWeight: 'bold' }}
          >
            <TableSortLabel
              active={sort.field === headCell.id}
              direction={sort.field === headCell.id ? sort.sort : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {sort.field === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {sort.sort === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          // eslint-disable-next-line max-len
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
        backgroundColor: '#9D5C63',
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected}
          {' '}
          selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  customers,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  selected,
  setSelected,
  handleRequestSort,
  sort,
  dense = true,
  isLoading,
}) {
  const [order, setOrder] = React.useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = React.useState(DEFAULT_ORDER_BY);
  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const navigate = useNavigate();

  const [paddingHeight, setPaddingHeight] = React.useState(0);

  const [open, setOpen] = React.useState(false);

  function matchInArrays(array1, array2) {
    const hasMatch = _.intersection(array1, array2);
    return hasMatch;
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = customers.data.map(({ _id }) => _id);
      setIsAllSelected(true);

      setSelected([...selected, ...newSelected]);
      return;
    }

    const matchInSelected = matchInArrays(selected, customers.data.map(({ _id }) => _id));

    setSelected(selected.filter((sel) => !matchInSelected.includes(sel)));
    setIsAllSelected(false);
  };

  const handleClick = (event, id) => {
    event.stopPropagation();

    const isSelected = Boolean(selected.find((selectedId) => selectedId === id));

    if (isSelected) {
      setSelected(selected.filter((selectedId) => selectedId !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  React.useEffect(() => {
    const matchInSelected = matchInArrays(selected, customers?.data?.map(({ _id }) => _id));

    if (!_.isEmpty(matchInSelected)) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [customers, selected]);

  return (
    <Box sx={{
      width: '100%',
    }}
    >
      <Paper elevation={3}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <TableContainer sx={{ borderRadius: 1, boxShadow: 2, width: '100%' }}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
                // onRequestSort={handleRequestSort}
              rowCount={customers?.data?.length}
              selected={selected}
              isAllSelected={isAllSelected}
              onRequestSort={handleRequestSort}
              sort={sort}
            />
            <TableBody sx={{ backgroundColor: colors.background }}>
              {isLoading ? <TableSkeleton rowsNum={5} columnsNum={6} />
                : customers?.data?.length > 0
                  ? customers.data.map((row, index) => {
                    const isItemSelected = Boolean(
                      selected.find((selectId) => selectId === row._id),
                    );
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => navigate(`/customers/${row._id}`)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                            onClick={(event) => handleClick(event, row._id)}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell>
                          <Chip
                            label={row.status}
                            color={
                              row.status === 'Actif'
                                ? 'success'
                                : row.status === 'Inactif' ? 'error'
                                  : row.status === 'En Attente' ? 'warning' : 'default'
                              }
                          />
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',

                          }}
                        >
                          {row.phoneNumber}

                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',

                          }}
                        >
                          {row.email}

                        </TableCell>
                        <TableCell align="right">
                          {row?.statusDate
                            ? new Date(row.statusDate).toLocaleDateString('fr-FR') : 'N/A'}
                        </TableCell>
                      </TableRow>
                    );
                  })
                  : null}
              {paddingHeight > 0 && (
                <TableRow
                  style={{
                    height: paddingHeight,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={customers?.totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ backgroundColor: colors.background }}
          labelRowsPerPage="candidats par page:"
          labelDisplayedRows={({ from, to, count }) => (`${from}–${to} sur ${count} candidats`)}
        />
      </Paper>
    </Box>
  );
}
