import * as React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import colors from '../../utils/colors';

export default function LoadingButtonsTransition({
  onClick, isLoading, title, icon,
}) {
  return (

    <LoadingButton
      size="small"
      style={!isLoading ? { background: colors.color } : {}}
      onClick={onClick}
      loading={isLoading}
      loadingPosition="start"
      startIcon={icon || <SaveIcon />}
      variant="contained"
    >
      <span>{title}</span>
    </LoadingButton>

  );
}
