import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {
  CardActionArea, IconButton, Menu, MenuItem,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import CardMedia from '@mui/material/CardMedia';

const settings = [
  { name: 'CV', value: 'cv' },
  { name: 'Comptes rendus', value: 'rendu' },
  { name: 'Autres documents', value: 'other' },
];

export default function MultiActionAreaCard({
  mail,
}) {
  const [openMenuLeftBar, setOpenMenuLeftBar] = useState(false);

  const clickOnUpdateIcon = async (event) => {
    event.stopPropagation();

    setOpenMenuLeftBar(event.currentTarget);
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>

        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <Typography>
            {' '}
            {mail.labelName}
            {' '}
          </Typography>
        </div>
        <CardContent style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <Typography gutterBottom variant="body2" color="text.secondary" align="center">
            {mail.newProspect.length}
            {' '}
            nouveau candidats trouvés
          </Typography>
          {/* <Typography variant="body2" color="text.secondary">
            Lizards are a widespread group of squamate reptiles, with over 6,000
            species, ranging across all continents except Antarctica
          </Typography> */}
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
      </CardActions> */}
    </Card>
  );
}
