import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

export default function JobList({
  allJobs, onJobDelete, handleToggle, checked,
}) {
  return (

    <List sx={{
      maxWidth: 360,
      bgcolor: 'background.paper',
      maxHeight: '20rem',
      overflow: 'auto',
    }}
    >
      {allJobs.map((job) => {
        const labelId = `checkbox-list-label-${job.jobName}`;

        return (
          <ListItem
            key={job}
            secondaryAction={(
              <IconButton edge="end" aria-label="comments" onClick={() => onJobDelete(job)}>
                <DeleteIcon />
              </IconButton>
        )}
            disablePadding
          >
            <ListItemButton role={undefined} onClick={handleToggle(job.jobName)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(job.jobName) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  variant: 'subtitle2',
                  style: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
                id={labelId}
                primary={job.jobName}
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>

  );
}
