/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import {
  TextField, Switch, Button, IconButton, Tooltip, FormControlLabel,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import InputAdornment from '@mui/material/InputAdornment';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import colors from '../../../utils/colors';
import ToggleButton from '../../../Components/ToggleButton';
import NativeSelect from '../../../Components/NativeSelect';
import AutocompleteOrCreate from '../../../Components/AutocompleteOrCreate';
import CheckBoxGroups from '../../../Components/CheckBoxGroups';

const CustomTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiInputBase-root': {
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& input': {
      fontSize: '16px', // Taille de la police souhaitée
      border: 'none', // Supprimer la bordure par défaut
      width: '80%',
    },
  },

  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
}));

function WorkerForCandidatComponent({
  onSubmit, isDisabled, updateSwitch, onDelete, valuesInitital,
}) {
  const initialValues = {
    ...(isDisabled && { isActive: valuesInitital?.isActive || false }),
    city: valuesInitital?.city || '',
    job: valuesInitital?.job || '',
    site: {
      apec: valuesInitital?.site?.apec || false,
    },
  };

  const onFinalSubmit = async (value, { resetForm }) => {
    await onSubmit(value);
    resetForm();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onFinalSubmit} enableReinitialize>
      {({ handleSubmit, values, setFieldValue }) => (
        <Form onSubmit={(handleSubmit)}>

          <Grid container spacing={3} sx={{ marginBottom: 5 }}>
            {isDisabled
            && (
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <FormControlLabel
                control={<Switch checked={valuesInitital?.isActive} />}
                label={isDisabled ? 'Actif' : 'Inactif'}
                onChange={
                  () => updateSwitch(valuesInitital?._id, { isActive: !valuesInitital.isActive })
                }
              />
              <Tooltip title="Supprimer la recherche">
                <IconButton onClick={() => onDelete(valuesInitital._id)}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Field
                name="job"
                label={(
                  <Typography>
                    Métier recherché
                  </Typography>
        )}
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="city"
                label={(
                  <Typography>
                    Département (44, 75)
                  </Typography>
        )}
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <Field>
                {({ field }) => (
                  <CheckBoxGroups
                    isDisabled={isDisabled}
                    name="site"
                    {...field}
                    value={values?.site}
                    label="Site recherché"
                    options={[
                      {
                        value: 'APEC',
                        name: 'site.apec',
                        label: 'apec',
                      },
                      {
                        value: 'Linkedin',
                        name: 'site.linkedin',
                        label: 'linkedin',
                        isDisabled: true,
                      },
                      {
                        value: 'Pole-emploie',
                        name: 'site.pe',
                        label: 'pe',
                        isDisabled: true,
                      },
                      {
                        value: 'Monster',
                        name: 'site.monster',
                        label: 'monster',
                        isDisabled: true,
                      },
                    ]}
                    style={{ display: 'block', flexDirection: 'row' }}
                    onChange={
                      (value) => setFieldValue(Object.keys(value)[0], value[Object.keys(value)[0]])
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: 'flex', alignItems: 'center', justifyContent: 'end',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                style={{ background: colors.color }}
              >
                {' '}
                Ajouter

              </Button>
            </Grid>
          </Grid>

        </Form>
      )}
    </Formik>
  );
}

export default WorkerForCandidatComponent;
