/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Button, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import colors from '../../../../../utils/colors';

const CustomTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiInputBase-root': {
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& input': {
      fontSize: '16px', // Taille de la police souhaitée
      border: 'none', // Supprimer la bordure par défaut
      width: '80%',
    },
  },

  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
}));

function FormikMaterialUIExample({
  contact, onSubmit, innerRef, isModify, onClose, isDisabled = false,
}) {
  const initialValues = {
    name: contact?.name || '',
    job: contact?.job || '',
    phoneNumber: contact?.phoneNumber || '',
    email: contact?.email || '',
  };

  return (
    <Formik innerRef={innerRef} initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, values, setFieldValue }) => (
        <Form onSubmit={(handleSubmit)}>
          <Grid container spacing={1} sx={{ marginBottom: 5 }}>
            <Grid item xs={12} md={6}>
              <Field
                name="name"
                label={(
                  <Typography>
                    Prénom et nom
                  </Typography>
                )}
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="job"
                label={(
                  <Typography>
                    Poste
                  </Typography>
                )}
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Field
                name="phoneNumber"
                label={(
                  <Typography>
                    Numéro de téléphone
                  </Typography>
                )}
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name="email"
                label={(
                  <Typography>
                    e-mail
                  </Typography>
                )}
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
          </Grid>

          {
            isModify
              ? (
                <Grid
                  container
                  spacing={1}
                  sx={{ marginTop: 5 }}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    style={{
                      background: colors.color,
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                    Enregistrer
                  </Button>
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              )
              : !isDisabled
              && (
              <Grid
                container
                spacing={1}
                sx={{ marginTop: 5 }}
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <Grid
                  item
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    textAlign: 'right',
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    style={{
                      background: colors.color,
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                    Enregistrer
                  </Button>
                </Grid>
              </Grid>
              )
          }

        </Form>
      )}
    </Formik>
  );
}

export default FormikMaterialUIExample;
