import React from 'react';
import Grid from '@mui/material/Grid';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import CheckMailComponent from './CheckMailComponent';
import EmailInput from '../../Components/EmailInput';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  paddingInline: '2rem',
  width: '80%',
  padding: '2rem',
}));

export default function JobPage() {
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
      >
        <Item elevation={6}>
          <CheckMailComponent />
        </Item>
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center',
        }}
      >
        <Item elevation={6}>
          <EmailInput />
        </Item>
      </Grid>
    </Grid>
  );
}
