import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import BuildIcon from '@mui/icons-material/Build';
import HailIcon from '@mui/icons-material/Hail';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import WorkIcon from '@mui/icons-material/Work';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArchiveIcon from '@mui/icons-material/Archive';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';

import {
  useNavigate,
  useLocation,
} from 'react-router-dom';

import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied';
import { Typography } from '@mui/material';
import colors from '../../utils/colors';
import { useMyContext } from '../../hooks/ContextOpen';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function TemporaryDrawer() {
  const navigate = useNavigate();
  const location = useLocation();

  const { openDrawing, setOpenDrawing } = useMyContext();

  const theme = useTheme();

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
    >
      <List>

        {[{ name: 'Candidats', navigate: '/candidats', icons: <EngineeringIcon /> },
          { name: 'Entreprises', navigate: '/customers', icons: <HailIcon /> },
          { name: 'Missions', navigate: '/missions', icons: <WorkIcon /> }].map((text, index) => (
            <ListItem
              key={text.name}
              disablePadding
              onClick={() => {
                navigate(text.navigate);
              }}
              style={{
                background:
                 location.pathname === text.navigate
                   ? colors.linear
                   : null,
                color:
                  location.pathname === text.navigate
                    ? 'white'
                    : null,
              }}
            >
              <ListItemButton>
                {text?.icons && (
                <ListItemIcon>
                  {React.cloneElement(text.icons, {
                    style: {
                      color:
                  location.pathname === text.navigate
                    ? 'white'
                    : null,
                    },
                  })}
                </ListItemIcon>
                )}
                <ListItemText primary={text.name} />
              </ListItemButton>
            </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <div>
          <Typography variant="caption" sx={{ padding: 2, fontSize: 9 }}>
            Récupérer nouveau candidats
          </Typography>
        </div>
        {[
          {
            name: 'Par mails',
            navigate: '/get-mail',
            icons: <ArchiveIcon />,
          },
          {
            name: 'Par scraping',
            navigate: '/get-prospect-scraping',
            icons: <ContactMailIcon />,
          },
        ].map((text, index) => (
          <ListItem
            key={text.name}
            disablePadding
            onClick={() => {
              navigate(text.navigate);
            }}
            style={{
              background:
               location.pathname === text.navigate
                 ? colors.linear
                 : null,
              color:
                location.pathname === text.navigate
                  ? 'white'
                  : null,
            }}
          >
            <ListItemButton>
              {text?.icons && (
                <ListItemIcon>
                  {React.cloneElement(text.icons, {
                    style: {
                      color:
                  location.pathname === text.navigate
                    ? 'white'
                    : null,
                    },
                  })}
                </ListItemIcon>
              )}
              <ListItemText primary={text.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <div>
          <Typography variant="caption" sx={{ padding: 2, fontSize: 9 }}>
            Outils
          </Typography>
        </div>
        {[{ name: 'Template mail', navigate: '/mail-template', icons: <MarkAsUnreadIcon /> },
          { name: 'Métier', navigate: '/update-job', icons: <BuildIcon /> },
          { name: 'Calendrier', navigate: '/calendar', icons: <CalendarMonthIcon /> },
        ].map((text, index) => (
          <ListItem
            key={text.name}
            disablePadding
            onClick={() => {
              navigate(text.navigate);
            }}
            style={{
              background:
               location.pathname === text.navigate
                 ? colors.linear
                 : null,
              color:
                location.pathname === text.navigate
                  ? 'white'
                  : null,
            }}
          >
            <ListItemButton>
              {text?.icons && (
                <ListItemIcon>
                  {React.cloneElement(text.icons, {
                    style: {
                      color:
                  location.pathname === text.navigate
                    ? 'white'
                    : null,
                    },
                  })}
                </ListItemIcon>
              )}
              <ListItemText primary={text.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      {/* <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List> */}
    </Box>
  );

  return (
    <div>

      <DrawerHeader>
        <IconButton onClick={() => setOpenDrawing(false)}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Drawer
        anchor="left"
        open={openDrawing}
        variant="persistent"
        onClose={() => setOpenDrawing(false)}
        sx={{
          width: 240,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
          },
        }}
      >
        {list()}
      </Drawer>

    </div>
  );
}
