/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-nested-ternary */

import React from 'react';
import Typography from '@mui/material/Typography';
import { Chip, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ENUM_MATCH_TO_FR, ENUM_UNMATCH_TO_FR } from '../../utils/enums/enumsStepper';

export default function OutlinedCard({
  commentary,
}) {
  const navigate = useNavigate();

  if (commentary?.title) {
    return (
      <Typography
        variant="body5"
        key={commentary._id}
      >
        {commentary.title}
      </Typography>
    );
  }

  if (commentary?.stepTo && commentary.stepTo.split('.')[0] === 'stepForProspect') {
    return (
      <Typography key={commentary._id}>
        le candidat (        
        <Link
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(`/candidats/${commentary?.prospectId}`)}
        >
          {commentary?.prospect?.name} 
        </Link>
        ) est passé à
        {' '}
        {' '}
        &quot;
        {ENUM_MATCH_TO_FR[commentary.stepTo.split('.')[1]]}
        &quot;
        {' '}
        {' '}
        pour une 
        {' '}
        {' '}
        <Link
          style={{ cursor: 'pointer' }} 
          onClick={() => navigate(`/missions/${commentary?.missionId}`)}
        >
          mission 
        </Link> 
        {' '}
        {' '}
        avec l&rsquo;entreprise
        {' '}
        {' '}
        <Link
          style={{ cursor: 'pointer' }} 
          onClick={() => navigate(`/customers/${commentary?.customerId}`)}
        >
          {commentary.customer.name}
        </Link>
        {' '}
        {' '}
      </Typography>
    );
  }

  if (commentary?.stepTo && commentary.stepTo.split('.')[0] === 'declineProspect') {
    return (
      <Typography key={commentary._id}>
        le candidat a été refusé
        {' '}
        {' '}
        {ENUM_UNMATCH_TO_FR[commentary.stepTo.split('.')[1]]}
        {' '}
        {' '}
        pour une 
        {' '}
        {' '}
        <Link
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(`/missions/${commentary?.missionId}`)}
        >
          mission 
        </Link> 
        {' '}
        {' '}
        avec l&rsquo;entreprise
        {' '}
        {' '}
        <Link
          style={{ cursor: 'pointer' }} 
          onClick={() => navigate(`/customers/${commentary?.customerId}`)}
        >
          {commentary.customer.name}
        </Link>
        {' '}
        {' '}
      </Typography>
    );
  }

  if (commentary?.status && commentary?.statusDate) {
    return (
      <Typography key={commentary._id}>
        le candidat est passé en status
        {' '}
        {' '}
        <Chip
          label={commentary.status}
          color={
              commentary.status === 'Actif'
                ? 'success'
                : commentary.status === 'Inactif' ? 'error'
                  : commentary.status === 'En Attente' ? 'warning' : 'default'
              }
        />
      </Typography>
    );
  }

  return null;
}
