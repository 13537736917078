/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelect({
  onChange, selected, values, isMultiple = true, label = 'Métier', isMenuProps = true, ...props
}) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <div>
      <FormControl variant="standard" sx={{ width: '90%' }}>
        <InputLabel
          id="demo-simple-select-standard-label"
          sx={{ fontSize: '14px' }}
        >
          {label}

        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          multiple={isMultiple}
          value={selected}
          onChange={handleChange}
          label="Métier"
          {...(isMenuProps && { MenuProps })}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          {values?.length && values.map((name) => {
            if (typeof name === 'object') {
              return (
                <MenuItem
                  key={name.value}
                  value={name.value}
                >
                  <Chip
                    label={name.value}
                    color={name.color}
                  />
                </MenuItem>

              );
            }
            return (
              <MenuItem
                key={name}
                value={name}
              >
                <Chip label={name} color="default" />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
