/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import FormLabel from '@mui/material/FormLabel';
import { withStyles } from '@mui/material/styles';

const SmallFormControlLabel = styled(FormControlLabel)`
  label {
    font-size: 20rem; /* Ajustez la taille de police souhaitée ici */
  }
`;

export default function CheckBoxGroups({
  onChange, options = [], value, label, style, isDisabled = false, ...rest
}) {
  return (
    <FormGroup
      onChange={(select) => onChange({ [select.target.name]: select.target.checked })}
      {...rest}
      style={style}
    >
      <FormLabel
        style={{ fontSize: '12px', textAlign: 'left' }}
        component="legend"
      >
        {label}

      </FormLabel>
      {options.map((option) => (
        <SmallFormControlLabel
          control={<Checkbox />}
          name={option.name}
          disabled={isDisabled || option?.isDisabled}
          checked={value[option?.label || option.value]}
          label={(
            <Typography
              variant="caption"
              {...(option?.isDisabled && { color: 'grey' })}
            >
              {option.value}
            </Typography>
          )}
        />
      ))}
    </FormGroup>
  );
}
