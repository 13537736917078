export const ENUM_MATCH = {
  matching: 'matching',
  selected: 'selected',
  firstContact: 'firstContact',
  phoningCall: 'phoningCall',
  customerProposal: 'customerProposal',
  customerInterview: 'customerInterview',
  approved: 'approved',
};

export const ENUM_MATCH_TO_FR = {
  [ENUM_MATCH.matching]: 'Matching',
  [ENUM_MATCH.selected]: 'Sélectionné',
  [ENUM_MATCH.firstContact]: 'Contact',
  [ENUM_MATCH.phoningCall]: 'RDV téléphonique',
  [ENUM_MATCH.customerProposal]: 'Proposition client',
  [ENUM_MATCH.customerInterview]: 'Entretien client',
  [ENUM_MATCH.approved]: 'Embauché',
};

export const ENUM_UNMATCH = {
  matching: 'matching',
  cvRefusal: 'cvRefusal',
  customerRefusal: 'customerRefusal',
};

export const ENUM_UNMATCH_TO_FR = {
  [ENUM_UNMATCH.matching]: 'Matching refusé',
  [ENUM_UNMATCH.cvRefusal]: 'Refus CV',
  [ENUM_UNMATCH.customerRefusal]: 'Refus client',
};
