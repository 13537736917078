import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import Modal from '../../../Components/Modal';
import ChipList from '../../../Components/ChipList';
import MailComponent from './ModalGetMail';
import colors from '../../../utils/colors';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function DividerStack() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allProspectGet, setAllProspectGet] = useState([]);
  const onSaveProspectByMail = async (value) => {
    setIsOpen(true);
    setIsLoading(true);
    const caca = await axios.get(`${process.env.REACT_APP_API_URL}/api/gmail`);

    setAllProspectGet(caca.data);

    setIsLoading(false);

    console.log(caca);
  };

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} md={12}>
          <Typography>
            Sélectionez les dossiers dans vos mails et Récupérez les candidats
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <ChipList chipData={[
            { key: 0, label: 'Candidatures' },
            { key: 1, label: 'CLIENT/EL2D/Candidatures' },
            { key: 2, label: 'CLIENT/EL2D/SMS envoyé' },
          ]}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
          }}
        >
          <Button
            size="small"
            color="primary"
            variant="contained"
            style={{ background: colors.color }}
            onClick={onSaveProspectByMail}
          >
            Récupérer
          </Button>
        </Grid>
      </Grid>
      <Modal isOpen={isOpen} handleClose={() => setIsOpen(false)}>
        {isLoading
          ? (<CircularProgress />)
          : (
            <Grid container spacing={3}>
              {' '}
              {allProspectGet.map((mail) => (
                <Grid item xs={6} md={6}>
                  <MailComponent mail={mail} />
                </Grid>
              ))}
              {' '}
            </Grid>
          )}
      </Modal>
    </>
  );
}
