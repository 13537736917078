/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  Formik, Form, Field, FieldArray,
} from 'formik';
import {
  TextField, Button, IconButton, Tooltip,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { AddCircle } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import colors from '../../utils/colors';
import NativeSelect from '../NativeSelect';
import AutoComplete from '../AutoComplete';
import AutocompleteOrCreateForArray from '../AutoCompleteOrCreateForArray';

const CustomTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiInputBase-root': {
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& input': {
      fontSize: '16px', // Taille de la police souhaitée
      border: 'none', // Supprimer la bordure par défaut
      width: '80%',
    },
  },

  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
}));

function FormikMaterialUIExample({
  mission, customerId, onSubmit, innerRef, isModify, onClose, isDisabled = false,
}) {
  const [options, setOptions] = useState([{ label: '', value: '' }]);
  const [allJobs, setAllJobs] = useState([]);
  const initialValues = {
    nameMission: mission?.nameMission || '',
    description: mission?.description || '',
    jobs: mission?.jobs || [''],
    city: mission?.city || '',
    minExperienceYear: mission?.minExperienceYear || '',
    ...(mission?.contactId && { contactId: mission?.contactId }),
    contactId: mission?.contactId,
    //   customerId: ,
    //   contactId: ,
  };

  console.log('initialValue', initialValues);

  const getContactListOfCustomer = async () => {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/contact/all`,
      { customerId },
    );

    setOptions([{ label: '', value: '' },
      ...result.data.data.map((contact) => ({ label: contact.name, value: contact._id }))]);
  };

  const getJobs = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs`);

    setAllJobs(response.data.jobs);
  };

  const addNewJob = async (value) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/jobs`, { jobName: value });

    await getJobs();
  };

  useEffect(() => {
    getContactListOfCustomer();
    getJobs();
  }, [customerId]);

  console.log('izi', options);

  return (
    <Formik innerRef={innerRef} initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, values, setFieldValue }) => (
        <Form onSubmit={(handleSubmit)}>
          <Grid container sx={{ marginBottom: 5 }}>
            <Grid item xs={12} md={12}>
              <Field
                name="nameMission"
                label={(
                  <Typography>
                    Intitulé de la mission
                  </Typography>
                )}
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ marginTop: 2 }}>
              <AutoComplete
                name="contactId"
                label="Contact de la mission"
                options={options}
                disabled={isDisabled}
                valueForm={values?.contactId}
                setFieldValue={setFieldValue}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <FieldArray name="jobs">
              {({
                remove, push, form,
              }) => (
                <>
                  {values?.jobs?.length > 0
                      && values.jobs.map((job, index) => (
                        <React.Fragment key={index}>
                          <Grid item xs={10} md={10}>
                            <AutocompleteOrCreateForArray
                              key={index}
                              name="job"
                              label={`Métier ${index + 1}`}
                              options={allJobs.map((j) => ({ label: j }))}
                              createNew={addNewJob}
                              setFieldValue={setFieldValue}
                              isDisabled={isDisabled}
                              valueForm={job}
                              push={push}
                              insert={(val) => {
                                const newJobs = [...form.values.jobs];
                                newJobs[index] = val; // Modifier la valeur spécifique

                                setFieldValue('jobs', newJobs); //
                              }}
                            />
                          </Grid>
                          {!isDisabled && (
                          <Grid
                            item
                            xs={2}
                            md={2}
                            style={{
                              display: 'flex',
                              justifyContent: 'left',
                              alignItems: 'end',
                            }}
                          >
                            <IconButton
                              aria-label="Supprimer"
                              onClick={() => remove(index)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          </Grid>
                          )}
                        </React.Fragment>

                      ))}
                  {!isDisabled && (
                  <Grid item xs={12} md={12}>
                    <IconButton onClick={() => push('')}>
                      <Tooltip title="Ajouter un nouveau métier">
                        <AddCircle sx={{ fontSize: '3rem' }} />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                  )}
                </>
              )}
            </FieldArray>
          </Grid>
          <Grid container spacing={1} sx={{ marginBottom: 5 }}>
            <Grid item xs={12} md={6}>
              <Field
                name="city"
                label={(
                  <Typography>
                    Lieux de la mission
                  </Typography>
                )}
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={12} md={6} sx={{ marginTop: 2 }}>
              <Field name="minExperienceYear">
                {({ field }) => (
                  <NativeSelect
                    name="minExperienceYear"
                    defaultValue={values?.minExperienceYear}
                    value={values?.minExperienceYear}
                    disabled={isDisabled}
                    {...field}
                    label="année d'experience minimum"
                    options={[
                      { value: 0, name: '' },
                      { value: 1, name: '< 1 an' },
                      { value: 2, name: '2 ans' },
                      { value: 3, name: '3 ans' },
                      { value: 4, name: '4 ans' },
                      { value: 5, name: '5 ans' },
                      { value: 6, name: '6 ans' },
                      { value: 7, name: '7 ans' },
                      { value: 8, name: '8 ans' },
                      { value: 9, name: '9 ans' },
                      { value: 10, name: '10 ans et +' },
                    ]}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12} md={12}>
              <Field
                name="description"
                label={(
                  <Typography>
                    Description de la mission
                  </Typography>
                )}
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                multiline
                rows={3}
              />
            </Grid>
          </Grid>

          {
            isModify
              ? (
                <Grid
                  container
                  spacing={1}
                  sx={{ marginTop: 5 }}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    style={{
                      background: colors.color,
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                    Enregistrer
                  </Button>
                  <IconButton onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              )
              : !isDisabled
              && (
              <Grid
                container
                spacing={1}
                sx={{ marginTop: 5 }}
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <Grid
                  item
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    textAlign: 'right',
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    style={{
                      background: colors.color,
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                    Enregistrer
                  </Button>
                </Grid>
              </Grid>
              )
          }
        </Form>
      )}
    </Formik>
  );
}

export default FormikMaterialUIExample;
