import React from 'react';
import { Modal } from '@mui/material';

function PdfModal({ pdfUrl, isOpen, onClose }) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >

      {isOpen
      && (
      <iframe
        src={pdfUrl}
        title="PDF Viewer"
        width="75%"
        height="90%"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        frameBorder="0"
        onLoad={() => console.log('puuuute')}
        onDragEnter={() => console.log('azeazea')}
      />
      )}

    </Modal>
  );
}

export default PdfModal;
