/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import DocumentsList2 from '../DocumentList';

export default function DocumentCategories({
  onClick, documents, name, selectedDocs,
}) {
  return (
    documents?.length
      ? (
        <Grid container spacing={1} sx={{ marginBottom: 5 }}>
          <Grid item xs={12} md={12}>
            <Typography variant="body1" component="div" gutterBottom>
              {name}
            </Typography>
          </Grid>
          {documents.map((doc) => (
            <Grid key={doc.file._id} item xs={12} md={2}>
              <DocumentsList2
                doc={doc}
                key={doc}
                onClick={() => onClick(doc)}
                isSelected={selectedDocs.find((url) => doc.pdfUrl === url)}
              />
            </Grid>
          ))}
        </Grid>
      )
      : null
  );
}
