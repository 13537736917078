/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import {
  Formik, Form,
} from 'formik';
import {
  TextField, Button,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import colors from '../../../../../utils/colors';
import AutoComplete from '../../../../../Components/AutoComplete';

const CustomTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiInputBase-root': {
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& input': {
      fontSize: '16px', // Taille de la police souhaitée
      border: 'none', // Supprimer la bordure par défaut
      width: '80%',
    },
  },

  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
}));

function FormikMaterialUIExample({
  mission, customerId, onSubmit, innerRef, isModify, onClose, isDisabled = false,
}) {
  const [options, setOptions] = useState([{ label: '', value: '' }]);
  const [allJobs, setAllJobs] = useState([]);
  const initialValues = {
    nameMission: mission?.nameMission || '',
    description: mission?.description || '',
    jobs: mission?.jobs || [''],
    minExperienceYear: mission?.minExperienceYear || '',
    ...(mission?.contactId && { contactId: mission?.contactId }),
    contactId: mission?.contactId,
    //   customerId: ,
    //   contactId: ,
  };

  console.log('initialValue', initialValues);

  const getContactListOfCustomer = async () => {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/prospects/all`,
    );

    setOptions([{ label: '', value: '' },
      ...result.data.data.map((contact) => ({ label: contact.name, value: contact._id }))]);
  };

  const getJobs = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs`);

    setAllJobs(response.data.jobs);
  };

  const addNewJob = async (value) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/jobs`, { jobName: value });

    await getJobs();
  };

  useEffect(() => {
    getContactListOfCustomer();
    getJobs();
  }, [customerId]);

  return (
    <Formik innerRef={innerRef} initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, values, setFieldValue }) => (
        <Form onSubmit={(handleSubmit)}>
          <Grid container spacing={1} sx={{ marginBottom: 5 }}>
            <Grid item xs={12} md={12} sx={{ marginTop: 2 }}>
              <AutoComplete
                name="prospectId"
                label="Candidat à ajouter*"
                options={options}
                disabled={isDisabled}
                valueForm={values?.contactId}
                setFieldValue={setFieldValue}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            sx={{ marginTop: 5 }}
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'right',
                textAlign: 'right',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                style={{
                  background: colors.color,
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                Enregistrer
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default FormikMaterialUIExample;
