/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete } from '@mui/material';

function SearchComponent({
  label, searchCustom, handleOptionChange, customerId, beginValue,
}) {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(beginValue || '');

  const handleInputChange = async (value) => {
    setInputValue(value || '');

    const result = await searchCustom(value || '');

    setOptions(result);
  };

  useEffect(() => {
    handleInputChange('');
  }, [customerId]);

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.label}
      onChange={(event, value) => handleOptionChange(value?.value || null)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          onChange={(event) => handleInputChange(event?.target?.value)}
          value={inputValue}
        />
      )}
    />
  );
}

export default SearchComponent;
