/* eslint-disable react/no-array-index-key */
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import { TableRow, TableCell } from '@mui/material';

function LoadingSkeleton({ rowsNum, columnsNum = 7 }) {
  return (
    [...Array(rowsNum)].map((row, index) => (
      <TableRow key={index}>
        {[...Array(columnsNum)].map((column, i) => {
          if (index === 0) {
            return (
              <TableCell component="th" scope="row" key={i}>
                <Skeleton animation="wave" variant="text" />
              </TableCell>
            );
          }
          return (
            <TableCell key={i}>
              <Skeleton animation="wave" variant="text" />
            </TableCell>
          );
        })}

      </TableRow>
    ))
  );
}

export default LoadingSkeleton;
