/* eslint-disable react/jsx-indent */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import Grid from '@mui/material/Grid';
/* eslint-disable max-len */
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import {
  deepOrange, green, red,
} from '@mui/material/colors';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import axios from 'axios';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Tooltip, Typography, Badge } from '@mui/material';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import BadgeIcon from '@mui/icons-material/Badge';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ToggleButton from '../../../../Components/ToggleButton';
import Select from '../../../../Components/MultipleSelect';
import DatePicker from '../../../../Components/DatePicker';
import FormForMission from '../../../../Components/FormForMission';
import CustomerDocuments from './Documents';
import { objectToQueryString } from '../../../../utils/allFunction';
import Paper from '../../../../Components/Paper';
import CommentaryComponent from '../../../../Components/CommentaryComponent';
import HistoricActivity from '../../../../Components/HistoricActivity';
import ProspectEstimate from './ProspectEstimate';
import ProspectRefused from './ProspectRefused';
import FormAlert from '../../../../Components/FormAlert';

function CustomerModal({ mission, getMission }) {
  const [pageProspect, setPageProspect] = useState('prospect-estimate');
  const [documents, setDocuments] = useState([]);

  const [commentaries, setCommentaries] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isAlertFormOpen, setIsAlertFormOpen] = useState(false);
  const [prospectsFind, setProspectsFind] = useState([]);
  const [historics, setHistorics] = useState([]);
  const formRef = useRef(null);

  const getCommentaries = async () => {
    if (mission._id) {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/commentary/prospect/${mission._id}?${objectToQueryString({ page: 1, limit: 100 })}`);
      setCommentaries(response.data.data);
    }
  };

  const handleClick = async (text) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/commentary`, { prospectId: mission._id, commentary: text });
    setCommentaries([response.data.commentary, ...commentaries]);
  };

  const addOrDeleteToFavorite = async (commentary) => {
    await axios.patch(`${process.env.REACT_APP_API_URL}/api/commentary/${commentary._id}`, { isFavorie: !commentary?.isFavorie });

    await getCommentaries();
  };

  const deleteCommentary = async (commentary) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/commentary/${commentary._id}`, { isFavorie: !commentary?.isFavorie });

    await getCommentaries();
  };

  const updateCommentary = async (id, body) => {
    await axios.patch(`${process.env.REACT_APP_API_URL}/api/commentary/${id}`, body);

    await getCommentaries();
  };

  const createAlert = async (body) => {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/api/alert`, { ...body, missionId: mission._id });

    return result.data.alert;
  };

  const getHistorics = async () => {
    if (mission._id) {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/historic/all`, { missionId: mission._id });

      setHistorics([...(response?.data?.data || []), ...(mission?.createdAt !== mission?.updatedAt ? [{ createdAt: mission.updatedAt, title: 'Dernière modification' }] : []), { createdAt: mission.createdAt, title: 'Création du client' }]);
    }
  };

  const onDeleteAlarm = async (commentary) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/alert/${commentary.alert._id}`);

    await axios.patch(`${process.env.REACT_APP_API_URL}/api/commentary/unset/${commentary._id}`, { alert: '' });

    await getCommentaries();
  };

  const getDocuments = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/file/mission/${mission._id}`);

    setDocuments(res.data);
  };

  const callRef = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
      setIsEdit(false);
    }
  };

  const cancelRef = () => {
    if (formRef.current) {
      formRef.current.resetForm();
      setIsEdit(false);
    }
  };

  const updateCustomer = async (values) => {
    function removeEmptyProperties(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (
            obj[key] === ''
                || obj[key] === 0
                || obj[key] === null
                || obj[key] === undefined
          ) {
            console.log(1.5);

            delete obj[key];
          } else if (typeof obj[key] === 'object' && !(obj[key] instanceof Date)) {
            removeEmptyProperties(obj[key]);

            if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
            }
          }
        }
      }
      return obj;
    }

    const coco = removeEmptyProperties(values);

    const result = await axios.patch(`${process.env.REACT_APP_API_URL}/api/mission/${mission._id}`, coco);
    await getHistorics();

    await getMission();
  };

  const getProspectByMission = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/prospects/match-mission/${mission._id}`,
    );
    setProspectsFind(response.data.result);
  };

  useEffect(() => {
    getCommentaries();
    getDocuments();
    getProspectByMission();
    getHistorics();
  }, [mission._id]);

  return (

<>
<Paper elevation={5} paddingTheme="2%" sx={{ marginBottom: '1rem' }}>
      <Grid container spacing={1}>
        <Grid item xs={3} md={1}>
          <Avatar sx={{ bgcolor: deepOrange[500] }}>{mission?.name?.split(' ')?.slice(0, 2)?.map((name) => name[0]) }</Avatar>
        </Grid>
        <Grid md={8} xs={4} />
        <Grid item xs={5} md={3}>
          <Select
            isMenuProps={false}
            values={[{ value: 'Inactif', color: 'error' },
              { value: 'Actif', color: 'success' },
              { value: 'En Attente', color: 'warning' },
              { value: 'A contacter' }]}
            isMultiple={false}
            label="Status"
            selected={mission?.status}
            onChange={async (event) => updateCustomer({ status: event.target.value, statusDate: new Date() })}
          />
        </Grid>
        <Grid item xs={7} md={9} />
        <Grid item xs={5} md={3}>
          <DatePicker
            value={mission?.statusDate}
            onChange={async (e) => updateCustomer({ statusDate: e.toDate() })}
          />
        </Grid>
      </Grid>
</Paper>
<Paper elevation={5}>
<ProspectEstimate
  mission={mission}
  getMission={getMission}
  getProspectByMission={getProspectByMission}
  prospectsFind={prospectsFind}
  getHistorics={getHistorics}
/>
</Paper>
<Grid container marginTop="1rem" spacing={2}>
<Grid item xs={4} md={4}>
    <Paper elevation={5}>
  <ProspectRefused mission={prospectsFind} getProspectByMission={getProspectByMission} />
    </Paper>
</Grid>
  <Grid item xs={4} md={4}>
    <Paper elevation={5}>
  <HistoricActivity historics={historics} />
    </Paper>
  </Grid>
  <Grid item xs={4} md={4}>
    <Paper elevation={5}>
    <CommentaryComponent
      commentaries={commentaries}
      handleClick={handleClick}
      addOrDeleteToFavorite={addOrDeleteToFavorite}
      deleteCommentary={deleteCommentary}
      updateCommentary={updateCommentary}
      createAlert={createAlert}
      onDeleteAlarm={onDeleteAlarm}
    />
    </Paper>
  </Grid>
</Grid>

<Paper elevation={6} sx={{ marginTop: '1rem' }}>
          <Grid item xs={12} md={12}>
            <Typography variant="body1" gutterBottom>
              Informations
            </Typography>

          </Grid>
          <Grid item xs={2} md={1}>
            <div style={{ textAlign: 'right', width: '100%' }}>
              {isEdit ? (
                <>
                  <IconButton onClick={callRef}>
                    <Tooltip title="Valider">
                      <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    </Tooltip>
                  </IconButton>
                  <IconButton onClick={cancelRef}>
                    <Tooltip title="Annuler">
                      <HighlightOffIcon style={{ color: red[500] }} />
                    </Tooltip>
                  </IconButton>
                </>
              )
                : (
                  <IconButton onClick={() => setIsEdit(true)}>
                    <Tooltip title="Modifier">
                      <ModeEditOutlineIcon />
                    </Tooltip>
                  </IconButton>
                )}
            </div>
          </Grid>
          <FormForMission
            onSubmit={(values) => updateCustomer(values)}
            mission={mission}
            innerRef={formRef}
            isDisabled={!isEdit}
          />
</Paper>

<Paper elevation={6} sx={{ marginTop: '1rem' }}>
<CustomerDocuments documents={documents} getDocuments={getDocuments} mission={mission} />
</Paper>
</>
  );
}

export default CustomerModal;
