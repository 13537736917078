import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Badge from '@mui/material/Badge';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ContactlessIcon from '@mui/icons-material/Contactless';
import CameraFrontIcon from '@mui/icons-material/CameraFront';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { IconButton } from '@mui/material';

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 31,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

export const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export const ColorlibStepIconRoot = styled('div')(({ theme, ownerState, isSelected }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  ...(isSelected ? {
    width: 80,
    height: 80,
  } : {
    width: 50,
    height: 50,
  }),
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

export function ColorlibStepIcon(props) {
  const {
    active, completed, className, icon, selected, setSelected, total,
  } = props;

  const icons = {
    1: <JoinInnerIcon />,
    2: <GroupAddIcon />,
    3: <ContactlessIcon />,
    4: <ContactPhoneIcon />,
    5: <CameraFrontIcon />,
    6: <Diversity1Icon />,
    7: <TaskAltIcon />,
  };

  if (setSelected) {
    return (
      <IconButton onClick={setSelected}>
        {total ? (
          <StyledBadge badgeContent={total} color="error">
            <ColorlibStepIconRoot
              ownerState={{ completed, active }}
              isSelected={selected}
              className={className}
            >
              {icons[String(icon)]}
            </ColorlibStepIconRoot>
          </StyledBadge>
        ) : (
          <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            isSelected={selected}
            className={className}
          >
            {icons[String(icon)]}
          </ColorlibStepIconRoot>
        ) }

      </IconButton>
    );
  }

  return total ? (
    <StyledBadge badgeContent={total} color="error">
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        isSelected={selected}
        className={className}
      >
        {icons[String(icon)]}
      </ColorlibStepIconRoot>
    </StyledBadge>
  ) : (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      isSelected={selected}
      className={className}
    >
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}
