/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Button, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import colors from '../../../utils/colors';
import NativeSelect from '../../../Components/NativeSelect';
import AutoComplete from '../../../Components/AutoComplete';
import Select from '../../../Components/MultipleSelect';
import CardPresentation from '../../../Components/CardPresentation';

import DateAlert from '../../../Components/DateAndHourPicker';

const CustomTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiInputBase-root': {
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& input': {
      fontSize: '16px', // Taille de la police souhaitée
      border: 'none', // Supprimer la bordure par défaut
      width: '80%',
    },
  },

  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
}));

function FormikMaterialUIExample({
  mission, customerId, onSubmit, innerRef, isModify, onClose, isDisabled = true,
}) {
  const [optionsCustomer, setOptionsCustomer] = useState([{ label: '', value: '' }]);
  const [optionsContact, setOptionsContact] = useState([{ label: '', value: '' }]);
  const [optionsProspect, setOptionsProspect] = useState([{ label: '', value: '' }]);
  const [infoFromAlert, setInfoFromAlert] = useState(null);

  console.log({ mission });

  useEffect(() => {
    const getAlertById = async () => {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/alert/${mission._id}`,
      );

      setInfoFromAlert(result.data.alert);
    };
    getAlertById();
  }, [mission]);

  const initialValues = {
    name: mission?.name || '',
    commentary: mission?.commentary || '',
    job: mission?.job || '',
    contactId: mission?.contactId,
    customerId: mission?.customerId,
    prospectId: mission?.prospectId,
    type: mission?.type || 'Rappel',
    dateBegin: mission?.dateBegin || '',
    dateFinish: mission?.dateFinish || '',
  };

  const getContactList = async () => {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/contact/all`,
      { customerId },
    );

    setOptionsContact([{ label: '', value: '' },
      ...result.data.data.map((contact) => ({ label: contact.name, value: contact._id }))]);
  };

  const getListOfCustomer = async () => {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/customer/all`,
      { paginate: { page: 1, limit: 1000 } },
    );

    setOptionsCustomer([{ label: '', value: '' },
      ...result.data.data.map((contact) => ({ label: contact.name, value: contact._id }))]);
  };

  const getListOfProspect = async () => {
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/prospects/all`,
      { paginate: { page: 1, limit: 1000 } },
    );

    setOptionsProspect([{ label: '', value: '' },
      ...result.data.data.map((contact) => ({ label: contact.name, value: contact._id }))]);
  };

  useEffect(() => {
    getContactList();
    getListOfCustomer();
    getListOfProspect();
  }, [customerId]);

  return (
    <Formik innerRef={innerRef} initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, values, setFieldValue }) => (
        <Form onSubmit={(handleSubmit)}>
          <Grid container spacing={3} sx={{ marginBottom: 5 }}>
            <Grid item xs={7} md={9} />
            <Grid item xs={5} md={3}>
              <Select
                isMenuProps={false}
                values={[{ value: 'Rdv physique', color: 'error' },
                  { value: 'Rdv téléphonique', color: 'success' },
                  { value: 'Rdv client', color: 'warning' },
                  { value: 'Rappel' }]}
                isMultiple={false}
                label="Type de RDV"
                selected={values?.type}
                onChange={async (event) => setFieldValue('type', event.target.value)}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Field
                name="name"
                label={(
                  <Typography>
                    Intitulé du RDV
                  </Typography>
                )}
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <DateAlert
                label="Date début"
                onAccept={(value) => setFieldValue('dateBegin', value)}
                initialValue={mission?.dateBegin}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <DateAlert
                label="Date fin"
                onAccept={(value) => setFieldValue('dateFinish', value)}
                initialValue={mission?.dateFinish}
                disabled={isDisabled}
              />
            </Grid>

            {infoFromAlert?.customer
            && (
            <Grid item xs={12} md={6} sx={{ marginTop: 2 }}>
              <CardPresentation title="Entreprise" value={infoFromAlert?.customer} />
            </Grid>
            )}
            {infoFromAlert?.contact
            && (
            <Grid item xs={12} md={6} sx={{ marginTop: 2 }}>
              <CardPresentation title="Contact Entreprise" value={infoFromAlert?.contact} />
            </Grid>
            )}
            {infoFromAlert?.prospect
            && (
            <Grid item xs={12} md={6} sx={{ marginTop: 2 }}>
              <CardPresentation title="Candidat" value={infoFromAlert?.prospect} />
            </Grid>
            )}

            <Grid item xs={12} md={12}>
              <Field
                name="commentary"
                label={(
                  <Typography>
                    Description du RDV
                  </Typography>
                )}
                margin="normal"
                as={CustomTextField}
                fullWidth
                disabled={isDisabled}
                InputLabelProps={{ shrink: true }}
                variant="standard"
                multiline
                rows={3}
              />
            </Grid>
          </Grid>

          {
            isModify
            && (
            <Grid
              container
              spacing={1}
              sx={{ marginTop: 5 }}
              style={{ display: 'flex', justifyContent: 'end' }}
            >
              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  textAlign: 'right',
                }}
              >
                <IconButton onClick={onClose}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    background: colors.color,
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                >
                  Enregistrer
                </Button>
              </Grid>
            </Grid>
            )
          }

          {/* {!isDisabled
          && (
          <Grid
            container
            spacing={1}
            sx={{ marginTop: 5 }}
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'right',
                textAlign: 'right',
              }}
            >
              <Button
                variant="contained"
                type="submit"
                style={{
                  background: colors.linear,
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                Enregistrer
              </Button>
            </Grid>
          </Grid>
          )} */}

        </Form>
      )}
    </Formik>
  );
}

export default FormikMaterialUIExample;
