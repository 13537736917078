/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {
  Box, Card, CardContent, Accordion, AccordionSummary, AccordionDetails, IconButton,
} from '@mui/material';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Grow from '@mui/material/Grow';
import CloseIcon from '@mui/icons-material/Close';
import FormForContact from './FormForContact';
import CardForContact from './CardForContact';
import colors from '../../../../../utils/colors';
import CardPresentation from '../../../../../Components/CardPresentation';
// import CardCommentary from '../CardCommentary';
// import Commentary from '../Commentary';

export default function ContactsComponent({ customerId }) {
  const [contacts, setContacts] = useState([]);
  const [contactModifyId, setContactModifyId] = useState(null);
  const [isCreateActive, setIsCreateActive] = useState(false);

  const getContacts = async () => {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/api/contact/all`, { customerId });

    setContacts(result.data.data);
  };

  const onSubmit = async (value, { resetForm }) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/api/contact`, { ...value, customerId });

    await getContacts();

    setIsCreateActive(false);
    resetForm();
  };

  const deleteContact = async (id) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/contact/${id}`);

    await getContacts();
  };

  const updateContact = async (id, body) => {
    await axios.patch(`${process.env.REACT_APP_API_URL}/api/contact/${id}`, body);

    await getContacts();

    setContactModifyId(null);
  };

  useEffect(() => {
    getContacts();
  }, []);

  console.log('ziziz', contacts);
  return (

    <Grid container spacing={1} sx={{ marginBottom: 3, maxHeight: '34rem', overflow: 'auto' }}>
      <Grid item xs={12} md={12}>
        <Typography variant="body1" gutterBottom>
          Contacts
        </Typography>

      </Grid>
      <Grid item xs={12} md={12}>

        {!isCreateActive
        && (
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Chip
              label="Ajouter un contact"
              onClick={() => setIsCreateActive(true)}
              style={{ background: colors.color, color: 'white' }}
            />
          </div>
        )}
        <Grow in={isCreateActive} mountOnEnter unmountOnExit>

          <Box sx={{ minWidth: '95%' }}>

            <Card variant="outlined">

              <CardContent>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <IconButton>
                    <CloseIcon onClick={() => setIsCreateActive(false)} />
                  </IconButton>
                </div>
                <FormForContact onSubmit={onSubmit} />
              </CardContent>
            </Card>
          </Box>
        </Grow>
      </Grid>

      {contacts.length
        ? contacts.map((contact) => {
          if (contact._id === contactModifyId) {
            return (
              <Grid item xs={12} md={12} key={contact._id}>

                <Card variant="outlined">
                  <CardContent>
                    <FormForContact onSubmit={(value) => updateContact(contact._id, value)} contact={contact} isModify onClose={() => setContactModifyId(null)} />
                  </CardContent>
                </Card>
              </Grid>
            );
          }
          return (
            <Grid item xs={12} md={12} key={contact._id}>
              <CardForContact
                value={contact}
                //   addOrDeleteToFavorite={addOrDeleteToFavorite}
                deleteContact={() => deleteContact(contact._id)}
                updateContact={() => setContactModifyId(contact._id)}
              />
            </Grid>
          );
        })
        : null}
    </Grid>
  );
}
