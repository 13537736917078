/* eslint-disable max-len */
import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import ActivityComponent from './ActivityComponent';

export default function HistoricActivity({
  historics,
}) {
  return (
    <>
      <Grid container spacing={1} sx={{ height: '3rem' }}>
        <Grid item xs={12} md={12}>
          <Typography variant="body1" gutterBottom>
            Historique
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ marginBottom: 3, height: '12rem', overflow: 'auto' }}>
        {historics.length
          ? historics.map((commentary) => (
            <Grid item xs={12} md={12} key={commentary}>
              <ActivityComponent key={commentary} commentary={commentary} />
            </Grid>
          ))
          : null}
      </Grid>
    </>
  );
}
