/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import React, { useState } from 'react';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import PostAddIcon from '@mui/icons-material/PostAdd';
import {
  Typography, FormControl,
} from '@mui/material';
import ModalConfirm from '../../../Components/ModalConfirm';
import Select from '../../../Components/MultipleSelect';
import SpeedDial from '../../../Components/SpeedDial';
import { useMyContext } from '../../../hooks/ContextOpen';

export default function LeftButtonTools({
  getCustomers, selected, setSelected,
}) {
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
  const [status, setStatus] = useState(null);
  const [selectedOption, setSelectedOptions] = useState(null);
  const { openDrawing } = useMyContext();

  const onSubmitActionBar = async () => {
    if (selectedOption === 'updateStatus') {
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/mission`, { ids: selected, set: { status, statusDate: new Date() } });
    }

    if (selectedOption === 'delete') {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/mission`, { params: { ids: selected } });
    }

    await getCustomers();

    setIsOpenModalConfirm(false);
    setSelected([]);
  };

  const settingsLeftButton = [{
    icon: <CheckBoxOutlineBlankIcon />,
    name: 'Déselectionner',
    onClick: () => {
      setSelected([]);
    },
  }, {
    icon: <DeleteIcon />,
    name: 'Supprimer',
    onClick: () => {
      setSelectedOptions('delete');
      setIsOpenModalConfirm(true);
    },
  },
  {
    icon: <PostAddIcon />,
    name: 'Changement status',
    onClick: () => {
      setSelectedOptions('updateStatus');
      setIsOpenModalConfirm(true);
    },
  }];

  return (
    <div>

      {!!selected?.length

        && (
        <SpeedDial
          actions={settingsLeftButton}
          isRight={false}
          iconPrincipal={<AutoFixHighIcon />}
          sx={openDrawing ? {
            position: 'fixed',
            bottom: '1rem',
            left: '17rem',
          } : {
            position: 'fixed',
            bottom: '1rem',
            left: '2rem',
          }}
        />
        )}

      <ModalConfirm isOpen={isOpenModalConfirm} handleClose={() => setIsOpenModalConfirm(false)} onValid={onSubmitActionBar}>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: '2rem',
        }}
        >
          {selectedOption === 'delete' && (
          <Typography variant="body1">
            Vous êtes sur le point de supprimer
            {' '}
            {selected.length}
            {' '}
            candidats. Êtes vous sûr ?
          </Typography>
          )}
          {selectedOption === 'updateStatus'
          && (
          <>
            <Typography variant="body1">
              En quel status voulez vous changer ces
              {' '}
              {selected?.length || 0}
              {' '}
              candidats ?
            </Typography>
            <FormControl sx={{ minWidth: 200, maxWidth: 300, marginTop: '4rem' }}>
              <Select
                isMenuProps={false}
                values={[{ value: 'Inactif', color: 'error' },
                  { value: 'Actif', color: 'success' },
                  { value: 'En Attente', color: 'warning' },
                  { value: 'A contacter' }]}
                isMultiple={false}
                label="Status"
                onChange={async (event) => setStatus(event.target.value)}
              />

            </FormControl>
          </>
          )}
        </div>
      </ModalConfirm>
    </div>
  );
}
