/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import EditNoteIcon from '@mui/icons-material/EditNote';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  Grid, Typography,
} from '@mui/material';
import HailIcon from '@mui/icons-material/Hail';

import {
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Modal from '../Modal';
import FormForProspect from '../FormForProspect';
import FormForCustomer from '../FormForCustomer';
import FileUploadComponent from '../FileUploadComponent';
import SpeedDial from '../SpeedDial';
import { MyContextProvider, useMyContext } from '../../hooks/ContextOpen';

export default function RightButtonCreate({ getCustomers, getProspects }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setFunctionDebounce } = useMyContext();

  const [isModalCreateProspectOpen, setIsModalCreateProspectOpen] = useState(false);
  const [isModalCreateCustomerOpen, setIsModalCreateCustomerOpen] = useState(false);
  const [isModalCreateProspectPdf, setIsModalCreateProspectPdf] = useState(false);
  const [allJobs, setAllJobs] = useState([]);

  const createProspect = async (values) => {
    function removeEmptyProperties(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (
            obj[key] === ''
              || obj[key] === 0
              || obj[key] === null
              || obj[key] === undefined
          ) {
            delete obj[key];
          } else if (typeof obj[key] === 'object') {
            removeEmptyProperties(obj[key]);

            if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
            }
          }
        }
      }
      return obj;
    }
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/api/prospects`, removeEmptyProperties({ ...values, data: { isCreated: true } }));

    navigate(`/candidats/${result.data.prospect._id}`);
  };

  const getAllJobs = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs`);

    setAllJobs(response.data.jobs);
  };

  const createCustomer = async (values) => {
    function removeEmptyProperties(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (
            obj[key] === ''
              || obj[key] === 0
              || obj[key] === null
              || obj[key] === undefined
          ) {
            delete obj[key];
          } else if (typeof obj[key] === 'object') {
            removeEmptyProperties(obj[key]);

            if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
            }
          }
        }
      }
      return obj;
    }
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/api/customer`, removeEmptyProperties({ ...values, data: { isCreated: true } }));

    navigate(`/customers/${result.data.prospect._id}`);
  };

  const openModalCreateCustomer = async () => {
    setIsModalCreateCustomerOpen(true);
  };

  const closeModalCreateCustomer = async () => {
    setIsModalCreateCustomerOpen(false);
  };

  const openModalCreateProspect = async () => {
    setIsModalCreateProspectOpen(true);
  };

  const closeModalCreateProspect = async () => {
    setIsModalCreateProspectOpen(false);
  };

  const settingsRightButton = [
    {
      icon: <EditNoteIcon />,
      name: 'Créer un candidat par formulaire',
      onClick: () => {
        openModalCreateProspect();
      },
    },
    {
      icon: <PictureAsPdfIcon />,
      name: 'Créer un candidat par PDF',
      onClick: () => {
        setIsModalCreateProspectPdf(true);
      },
    },
    {
      icon: <HailIcon />,
      name: 'Créer un nouveau client',
      onClick: () => {
        openModalCreateCustomer();
      },
    },
  ];

  const getWorkerActif = async (id) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/worker/${id}`);

    return !response.data.worker.isActive;
  };

  useEffect(() => {
    getAllJobs();
  }, []);

  return (
    <>

      <SpeedDial actions={settingsRightButton} />

      <Modal isOpen={isModalCreateCustomerOpen} handleClose={closeModalCreateCustomer}>
        <FormForCustomer onSubmit={createCustomer} isButtonSubmit />
      </Modal>
      <Modal isOpen={isModalCreateProspectOpen} handleClose={closeModalCreateProspect}>
        <FormForProspect onSubmit={createProspect} allJobs={allJobs} isButtonSubmit />
      </Modal>
      <Modal isOpen={isModalCreateProspectPdf} handleClose={() => setIsModalCreateProspectPdf(false)} width="60%" height="20%">
        <Grid container spacing={6}>
          <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant="body2">
              Sélectionner un CV en pdf pour créer le candidat
            </Typography>

          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            sx={{
              display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
          >
            <div>
              <FileUploadComponent
                isMultiple
                metadata={{ documentType: 'rendu' }}
                accept=".pdf"
                callback={async (reponse) => {
                  [<span>
                    L'analyseur est entrain d'analyser vos PDFS,
                    <br />
                    les candidats seront créés dans quelques instants
                   </span>].map((value) => enqueueSnackbar(value, { variant: 'warning' }));
                  setFunctionDebounce({ func: (params) => getWorkerActif(params), params: reponse.workerId });
                  setIsModalCreateProspectPdf(false);
                  // if (['/candidats', '/'].includes(location.pathname)) {
                  //   await getProspects();
                  // } else {
                  //   navigate('/candidats');
                  // }
                }}
                url="/api/upload/cv-before-create-prospect"
              />
            </div>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
}
