import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {
  useNavigate,
} from 'react-router-dom';
import { IconButton } from '@mui/material';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import { useMyAuth } from '../../hooks/useAuth';

const bcrypt = require('bcryptjs');

const saltRounds = 10;
const salt = bcrypt.genSaltSync(saltRounds);

function LoginPage({ history, useAuth }) {
  const { setIsAuthentificated } = useMyAuth();
  const [password, setPassword] = useState('');
  const [helperText, setHelperText] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    console.log(bcrypt.hashSync(password, salt));
    // Vérifie le mot de passe (par exemple, s'il est égal à 'monMotDePasse')

    const isPasswordCorrect = bcrypt.compareSync(
      password,
      '$2a$10$JD2ghY1BZK7GCVuFEI.uzuXDIp3X.rdLIKjpE7R3lDRqxu16f9Xja',
    );

    if (isPasswordCorrect) {
      // Si le mot de passe est correct, redirige vers la page principale
      setIsAuthentificated(true);
      return navigate('/candidats');
    }
    // Sinon, gère l'erreur ou affiche un message d'erreur
    setHelperText('mot de passe incorrect');
    return console.error('Mot de passe incorrect');
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleLogin();
        // Mettez ici le code que vous souhaitez exécuter lorsque la touche Entrée est pressée
      }
    };

    document.addEventListener('keypress', handleKeyPress);

    // Nettoyer l'écouteur d'événements lors du démontage du composant
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  return (
    <div>
      <TextField
        type="password"
        label="Mot de passe"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        helperText={helperText}
        onKeyDown={(event) => {
          console.log(event.key);
          if (event.keyCode === 13) {
            // Si la touche Entrée est pressée, déclencher l'événement onChange
            handleLogin();
          }
        }}
      />
      <IconButton
        variant="contained"
        onClick={handleLogin}
        onKeyDown={(event) => {
          console.log(event.key);
          if (event.keyCode === 13) {
          // Si la touche Entrée est pressée, déclencher l'événement onChange
            handleLogin();
          }
        }}
      >
        <KeyboardTabIcon />
      </IconButton>
    </div>
  );
}

export default LoginPage;
