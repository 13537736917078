/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { ColorlibStepIcon, ColorlibConnector } from './ComponentStyled';
import { ENUM_MATCH_TO_FR, ENUM_MATCH } from '../../utils/enums/enumsStepper';

const steps = [
  'matching',
  'selected',
  'firstContact',
  'phoningCall',
  'customerProposal',
  'customerInterview',
  'approved',
];

export default function CustomizedSteppers({
  selected, prospectsFind, setSelected, step,
}) {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel activeStep={step} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={
                (props) => (
                  <ColorlibStepIcon
                    {...props}
                    selected={selected === label}
                    {...(setSelected && { setSelected: () => setSelected(label) })}
                    total={prospectsFind?.stepForProspect?.[label]?.length}
                  />
                )
}
            >
              {selected === label
                ? <strong>{ENUM_MATCH_TO_FR[label]}</strong>
                : ENUM_MATCH_TO_FR[label]}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
