/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import {
  Chip, MenuItem, Paper, Button, TextField, IconButton, Fade,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import EmailInput from '../../Components/EmailInput';
import colors from '../../utils/colors';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  //   padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  paddingInline: '2rem',
  width: '80%',
  padding: '2rem',
}));

const CustomTextField = styled(TextField)(({ theme, disabled }) => ({
  '& .MuiInputBase-root': {
    boxShadow: 'none',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '& input': {
      fontSize: '16px', // Taille de la police souhaitée
      border: 'none', // Supprimer la bordure par défaut
      width: '80%',
    },
  },

  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
}));

export default function JobPage() {
  const [selected, setSelected] = useState(null);
  const [mailTemplates, setMailTemplates] = useState([]);
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [nameNewTemplate, setNameNewTemplate] = useState('');
  const [isCreateActive, setIsCreateActive] = useState(false);

  const getAllMailsTemplate = async () => {
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/gmail/template`,
    );

    setMailTemplates(result?.data?.mails || []);
  };

  const createMailTemplate = async () => {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/api/gmail/template`,
      { name: nameNewTemplate },
    );
    setNameNewTemplate('');
    await getAllMailsTemplate();
    setIsCreateActive(false);
  };

  const saveTemplate = async (editorState) => {
    const mail = mailTemplates.find(({ name }) => name === selected);

    await axios.patch(
      `${process.env.REACT_APP_API_URL}/api/gmail/template/${mail._id}`,
      { text: editorState, title },
    );

    await getAllMailsTemplate();
  };

  useEffect(() => {
    getAllMailsTemplate();
  }, []);

  useEffect(() => {
    if (selected) {
      const mail = mailTemplates.find(({ name }) => name === selected);

      setText(mail?.text || '');
      setTitle(mail?.title || '');
    }
  }, [selected]);

  return (
    <div style={{
      marginInline: '4rem', justifyContent: 'center', alignContent: 'center', alignItems: 'center',
    }}
    >
      <Grid
        container
        spacing={3}
        padding="2rem"
      >
        {!isCreateActive
          ? (
            <Grid
              item
              xs={12}
              md={12}
              style={{
                display: 'flex', alignItems: 'center', justifyContent: 'right',
              }}
            >
              <Button
                sx={{ background: colors.color }}
                component="label"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => setIsCreateActive(true)}
              >
                nouveau template
              </Button>

            </Grid>
          )
          : (

            <Grid
              item
              xs={12}
              md={12}
              container
              style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center',
              }}
            >
              <Fade in={isCreateActive}>

                <Item elevation={6}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: 'flex', alignItems: 'center', justifyContent: 'right',
                    }}
                  >
                    <IconButton onClick={() => setIsCreateActive(false)}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    container
                    style={{
                      display: 'flex', alignItems: 'center', justifyContent: 'center',
                    }}
                  >
                    <CustomTextField
                      label="Nom du template"
                      margin="normal"
                      variant="standard"
                      onChange={(value) => setNameNewTemplate(value.target.value)}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    container
                    style={{
                      display: 'flex', alignItems: 'center', justifyContent: 'right',
                    }}
                  >
                    <Button
                      sx={{ background: colors.color }}
                      component="label"
                      variant="contained"
                      startIcon={<SaveIcon />}
                      onClick={() => createMailTemplate()}
                    >
                      Ajouter
                    </Button>
                  </Grid>

                </Item>
              </Fade>

            </Grid>
          )}
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
        >
          {mailTemplates?.length
            ? (
              <Item elevation={6}>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label="caca"
                  value={selected}
                  onChange={(value) => setSelected(value.target.value)}
                  sx={{ width: '60%' }}
                >
                  {mailTemplates?.length && mailTemplates.map(({ name }) => (
                    <MenuItem
                      key={name}
                      value={name}
                    >
                      <Chip label={name} color="default" />
                    </MenuItem>
                  ))}
                </Select>
              </Item>
            )
            : null}
        </Grid>
        <Fade in={Boolean(selected)}>
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}
          >
            <Item elevation={6}>
              <EmailInput text={text} setText={setText} title={title} setTitle={setTitle} saveTemplate={saveTemplate} />
            </Item>
          </Grid>
        </Fade>
      </Grid>
    </div>
  );
}
