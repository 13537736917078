import React from 'react';
import * as ReactDOM from 'react-dom';
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import NavBar from './Components/NavBar';
import App from './Page/Home';
import ErrorPage from './Page/Error';
import CustomerPage from './Page/Customers';
import CustomerProfile from './Page/Customers/CustomerProfil';
import MissionPage from './Page/Missions';
import MissionProfile from './Page/Missions/MissionProfil';
import LoginPage from './Page/Login';
import CalendarPage from './Page/Calendar';
import GetMailPage from './Page/GetMail';
import ProspectScraping from './Page/ProspectScraping';
import ProspectProfil from './Page/Home/ProspectProfil';
import JobPage from './Page/Job';
import TemplateMailPage from './Page/TemplateMail';
import { MyContextProvider } from './hooks/ContextOpen';
import { MyAuthentificationProvider, useMyAuth } from './hooks/useAuth';

function PrivateRoute({ component }) {
  const { isAuthentificated, setIsAuthentificated } = useMyAuth();

  return isAuthentificated
    ? <NavBar isAuthenticated={isAuthentificated}>{component}</NavBar>
    : <Navigate to="/login" />;
}

const router = createBrowserRouter([
  // {
  //   path: '/',
  //   element: <PrivateRoute component={<App />} />,
  //   // children: [
  //   //   {
  //   //     path: "team",
  //   //     element: <Team />,
  //   //     loader: teamLoader,
  //   //   },
  //   // ],
  // },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/candidats',
    element: <PrivateRoute component={<App />} />,
  },
  {
    path: '/candidats/:id',
    element: <PrivateRoute component={<ProspectProfil />} />,
    // loader: eventLoader,
  },
  {
    path: '/customers',
    element: <PrivateRoute component={<CustomerPage />} />,
  },
  {
    path: '/customers/:id',
    element: <PrivateRoute component={<CustomerProfile />} />,
  },
  {
    path: '/missions',
    element: <PrivateRoute component={<MissionPage />} />,
  },
  {
    path: '/missions/:id',
    element: <PrivateRoute component={<MissionProfile />} />,
  },
  {
    path: '/get-mail',
    element: <PrivateRoute component={<GetMailPage />} />,
  },
  {
    path: '/get-prospect-scraping',
    element: <PrivateRoute component={<ProspectScraping />} />,
  },
  {
    path: '/mail-template',
    element: <PrivateRoute component={<TemplateMailPage />} />,
  },
  {
    path: '/update-job',
    element: <PrivateRoute component={<JobPage />} />,
  },
  {
    path: '/calendar',
    element: <PrivateRoute component={<CalendarPage />} />,
  },
  {
    path: '*',
    element: <NavBar><ErrorPage /></NavBar>,
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <SnackbarProvider>
    <MyContextProvider>
      <MyAuthentificationProvider>
        <RouterProvider router={router} />
      </MyAuthentificationProvider>
    </MyContextProvider>
  </SnackbarProvider>,
);
