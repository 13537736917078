/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import { ButtonGroup } from '@mui/material';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModalConfirm from '../../Components/ModalConfirm';

import CheckMailComponent from './CheckMailComponent';
import WorkerForCandidatComponent from './WorkerForCandidatComponent';
import JobList from './Components/ListJob';
import colors from '../../utils/colors';

const CustomTextField = styled(TextField)(({ theme, disabled }) => ({

  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#000000',
  },
}));

export default function JobPage() {
  const [allJobs, setAllJobs] = useState([]);
  const [checked, setChecked] = useState([]);
  const [jobToDelete, setJobToDelete] = useState(null);
  const [modalToModify, setModalToModify] = useState(false);
  const [text, setText] = useState('');

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  console.log({ checked });
  const getJobs = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs`);

    setAllJobs(response.data.jobsList);
  };

  const deleteJob = async (value) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/jobs/${value}`);
    setJobToDelete(null);
    await getJobs();
  };

  const onChangeJob = async (value) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/jobs/${value}`);
    setJobToDelete(null);
    await getJobs();
  };

  useEffect(() => {
    getJobs();
  }, []);

  return (
    <div style={{
      marginInline: '4rem', justifyContent: 'center', alignContent: 'center', alignItems: 'center',
    }}
    >

      <Grid
        container
      >

        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center',
          }}
        >

          <JobList allJobs={allJobs} onJobDelete={(value) => setJobToDelete(value)} handleToggle={handleToggle} checked={checked} />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem',
          }}
        >
          <ButtonGroup variant="text" aria-label="outlined button group">
            <Button size="small" color="primary" onClick={() => setModalToModify(true)} disabled={!checked.length}>
              Modifier
            </Button>
            <Button size="small" color="primary" disabled={!checked.length}>
              Supprimer
            </Button>
          </ButtonGroup>
        </Grid>

      </Grid>

      <ModalConfirm
        handleClose={() => {
          setJobToDelete(false);
        }}
        isOpen={Boolean(jobToDelete)}
        onValid={() => deleteJob(jobToDelete._id)}
      >
        <Typography variant="body1">
          Vous êtes sur le point de supprimer
          {' '}
          {jobToDelete?.jobName}
          {' '}
          comme nom de métier. Êtes vous sûr ?
        </Typography>
      </ModalConfirm>
      <ModalConfirm
        handleClose={() => setModalToModify(false)}
        isOpen={modalToModify}
        onValid={() => onChangeJob()}
        disabled={!text.length}
      >
        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>

          <Grid xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <List
              sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                maxHeight: 300,
                '& ul': { padding: 0 },
              }}
              subheader={<li />}
            >
              {checked.map((check) => (
                <li key={`section-${check}`}>
                  <ul>

                    <ListItem key={`item-${check}`}>
                      <ListItemText primary={`${check}`} />
                    </ListItem>

                  </ul>
                </li>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '2rem' }}>
            <Typography variant="body1">
              En quoi voulez vous modifier ces noms de métiers ?
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <CustomTextField
              label="Nouveau métier"
              margin="normal"
              value={text}
              onChange={(event) => setText(event.target.value)}
            />
          </Grid>
        </Grid>
        {' '}
        <Grid />

      </ModalConfirm>
    </div>
  );
}
