/* eslint-disable no-param-reassign */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
/* eslint-disable max-len */

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import {
  deepOrange, green, red,
} from '@mui/material/colors';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import axios from 'axios';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Tooltip, Typography, CardActionArea, CardContent, Box, Tabs, Tab,
} from '@mui/material';
import Card from '@mui/material/Card';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import BadgeIcon from '@mui/icons-material/Badge';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PostAddIcon from '@mui/icons-material/PostAdd';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import SportsMartialArtsIcon from '@mui/icons-material/SportsMartialArts';
import MailLockOutlinedIcon from '@mui/icons-material/MailLockOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import SubjectIcon from '@mui/icons-material/Subject';
import BookIcon from '@mui/icons-material/Book';
import CardMedia from '@mui/material/CardMedia';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import ProspectDocuments from './ProspectDocuments';
import FormForProspect from '../../FormForProspect';
import ExperianceAndFormation from './ExperienceAndFormation';
import PdfModal from '../../../../Components/PdfModal';
import Select from '../../../../Components/MultipleSelect';
import Paper from '../../../../Components/Paper';
import FeedBackButton from '../../../../Components/FeedbackButton';
import DatePicker from '../../../../Components/DatePicker';
import { objectToQueryString } from '../../../../utils/allFunction';
import CommentaryComponent from '../../../../Components/CommentaryComponent';
import HistoricActivity from '../../../../Components/HistoricActivity';
import ProspectResume from './ProspectResume';
import colors from '../../../../utils/colors';
import ShowPdf from './ShowPdf';
import DocumentShowAndIA from './DocumentShowAndIA';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function ProspectModal({
  prospect, setProspect, updateProspectById, getPrincipalDocuments, documentsPrincipal,
}) {
  const [commentaries, setCommentaries] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [documents, setDocuments] = useState([]);
  const formRef = useRef(null);
  const refDocument = useRef(null);
  const [allJobs, setAllJobs] = useState([]);
  const [isResumeSelectDocument, setIsResumeSelectDocument] = useState(false);
  const [historics, setHistorics] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const getJobs = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/jobs`);

      setAllJobs(response.data.jobs);
    };

    getJobs();
  }, []);

  const getProspectById = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/prospects/${prospect._id}`);

    setProspect(response.data.prospect);
  };

  const openFile = async (doc) => {
    if (doc.file.fileName.slice(-4) === '.pdf') {
      return setPdfUrl(doc.pdfUrl);
    }
    return window.open(doc.pdfUrl, '_blank');
  };

  const getCommentaries = async () => {
    if (prospect._id) {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/commentary/prospect/${prospect._id}?${objectToQueryString({ page: 1, limit: 100 })}`);
      setCommentaries(response.data.data);
    }
  };

  const getHistorics = async () => {
    if (prospect._id) {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/historic/all`, { prospectId: prospect._id });

      setHistorics([...(response?.data?.data || []), ...(prospect?.createdAt !== prospect?.updatedAt ? [{ createdAt: prospect.updatedAt, title: 'Dernière modification' }] : []), { createdAt: prospect.createdAt, title: 'Création du candidat' }]);
    }
  };

  const getDocuments = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/file/prospect/${prospect._id}`);

    setDocuments(res.data);
  };

  const handleClick = async (text) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/commentary`, { prospectId: prospect._id, commentary: text });
    setCommentaries([response.data.commentary, ...commentaries]);
  };

  const addOrDeleteToFavorite = async (commentary) => {
    await axios.patch(`${process.env.REACT_APP_API_URL}/api/commentary/${commentary._id}`, { isFavorie: !commentary?.isFavorie });

    await getCommentaries();
  };

  const deleteCommentary = async (commentary) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/commentary/${commentary._id}`, { isFavorie: !commentary?.isFavorie });

    await getCommentaries();
  };

  const updateCommentary = async (id, body) => {
    await axios.patch(`${process.env.REACT_APP_API_URL}/api/commentary/${id}`, body);

    await getCommentaries();
  };

  const createAlert = async (body) => {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/api/alert`, { ...body, prospectId: prospect._id });

    return result.data.alert;
  };

  const onDeleteAlarm = async (commentary) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/alert/${commentary.alert._id}`);

    await axios.patch(`${process.env.REACT_APP_API_URL}/api/commentary/unset/${commentary._id}`, { alert: '' });

    await getCommentaries();
  };

  const getResume = async (document) => {
    setIsResumeSelectDocument(false);
    await axios.patch(`${process.env.REACT_APP_API_URL}/api/prospects/resume/${prospect._id}`, { url: document.pdfUrl });

    const result = await axios.get(`${process.env.REACT_APP_API_URL}/api/prospects/${prospect._id}`);

    setProspect(result.data.prospect);
  };

  const getCvInterne = async (document) => {
    setIsResumeSelectDocument(false);
    const resultDoc = await axios.post(`${process.env.REACT_APP_API_URL}/api/prospects/cv-interne`, { url: document.pdfUrl });

    console.log(resultDoc.data);

    window.open(resultDoc.data.docUrl);

    const result = await axios.get(`${process.env.REACT_APP_API_URL}/api/prospects/${prospect._id}`);

    setProspect(result.data.prospect);
  };

  const getCompteRendu = async (document) => {
    setIsResumeSelectDocument(false);
    const resultDoc = await axios.post(`${process.env.REACT_APP_API_URL}/api/prospects/compte-rendu`, { url: document.pdfUrl });

    // console.log(resultDoc.data);

    // window.open(resultDoc.data.docUrl);

    // const result = await axios.get(`${process.env.REACT_APP_API_URL}/api/prospects/${prospect._id}`);

    // setProspect(result.data.prospect);
  };

  const callRef = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
      setIsEdit(false);
    }
  };

  const cancelRef = () => {
    if (formRef.current) {
      formRef.current.resetForm();
      setIsEdit(false);
    }
  };

  const getAll = async () => {
    await getCommentaries();
    await getDocuments();
    await getHistorics();
  };

  const updateProspect = async (values) => {
    function removeEmptyProperties(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (
            obj[key] === ''
              || obj[key] === 0
              || obj[key] === null
              || obj[key] === undefined
          ) {
            delete obj[key];
          } else if (typeof obj[key] === 'object' && !(obj[key] instanceof Date) && !_.isArray(obj[key])) {
            removeEmptyProperties(obj[key]);

            if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
            }
          }
        }
      }
      return obj;
    }

    const coco = removeEmptyProperties(values);

    const result = await axios.patch(`${process.env.REACT_APP_API_URL}/api/prospects/${prospect._id}`, coco);

    setProspect(result.data.prospect);

    await getAll();
  };

  const onDeleteFile = async (id) => {
    if (documentsPrincipal?.cvPrincipal?.file?._id === id) {
      enqueueSnackbar('Vous ne pouvez pas le supprimer, ceci est un document principal', { variant: 'warning' });

      return;
    }
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/file/${id}`);
    await getDocuments();
  };

  useEffect(() => {
    getAll();
  }, [prospect._id]);

  return (
    <>
      <Paper elevation={5} paddingTheme="2%">
        <Grid container>
          <Grid item xs={3} md={1}>
            <EngineeringIcon style={{ fontSize: '2rem' }} />
          </Grid>
          <Grid xs={3} md={3}>
            <Typography variant="subtitle1">

              {prospect?.name}
            </Typography>
            <Typography
              variant="body2"
              style={{
                overflowWrap: 'break-word',
              }}
            >
              <Tooltip title={prospect?.jobs?.[0]}>
                {prospect?.jobs?.[0]}
              </Tooltip>
            </Typography>
            <Typography
              variant="body2"
              style={{
                overflowWrap: 'break-word',
              }}
            >
              {prospect?.phoneNumber}
            </Typography>
            <Typography
              variant="body2"
              style={{
                overflowWrap: 'break-word',
              }}
            >
              {prospect?.email}
            </Typography>
          </Grid>
          <Grid xs={5} md={5} />
          <Grid item xs={5} md={3}>
            <Grid container direction="row-reverse" spacing={2}>
              <Grid item xs={12} md={12}>
                <Select
                  isMenuProps={false}
                  values={[{ value: 'Inactif', color: 'error' },
                    { value: 'Actif', color: 'success' },
                    { value: 'En Attente', color: 'warning' },
                    { value: 'A contacter' }]}
                  isMultiple={false}
                  label="Status"
                  selected={prospect?.status}
                  onChange={async (event) => updateProspect({ status: event.target.value, statusDate: new Date() })}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <DatePicker value={prospect?.statusDate} onChange={async (e) => updateProspect({ statusDate: e.toDate() })} />
              </Grid>
              <Grid item xs={12} md={12}>
                {prospect?.isFirstOpen
                  ? (
                    <IconButton onClick={() => updateProspectById(prospect._id, { isFirstOpen: false })}>
                      <Tooltip title="Marquer comme non lu">
                        <MailLockOutlinedIcon />
                      </Tooltip>
                    </IconButton>
                  )
                  : (
                    <IconButton onClick={() => updateProspectById(prospect._id, { isFirstOpen: true })}>
                      <Tooltip title="Marquer comme lu">
                        <DraftsOutlinedIcon />
                      </Tooltip>
                    </IconButton>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={2} marginTop="4px" style={{ marginBottom: '1rem' }}>
        <Grid item xs={6} md={6}>
          <ExperianceAndFormation prospect={prospect} setProspect={setProspect} allJobs={allJobs} getAll={getAll} documents={documents} onSubmit={(values) => updateProspect(values)} innerRef={formRef} isDisabled={!isEdit} />
        </Grid>
        <Grid item xs={6} md={6}>
          <DocumentShowAndIA documentsPrincipal={documentsPrincipal} prospect={prospect} setProspect={setProspect} documents={documents} getPrincipalDocuments={getPrincipalDocuments} refDocument={refDocument} />
        </Grid>
        <Grid item xs={4} md={4}>
          <Grid container direction="row-reverse" spacing={2}>
            <Grid item xs={12} md={12}>
              <Paper elevation={5}>
                <HistoricActivity historics={historics} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={12}>
              <Paper elevation={5}>
                <CommentaryComponent commentaries={commentaries} handleClick={handleClick} addOrDeleteToFavorite={addOrDeleteToFavorite} deleteCommentary={deleteCommentary} updateCommentary={updateCommentary} createAlert={createAlert} onDeleteAlarm={onDeleteAlarm} />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8} md={8}>
          <Paper elevation={5} ref={refDocument}>
            <Grid container spacing={1} sx={{ height: '1rem' }}>
              <Grid item xs={12} md={12}>
                <Typography variant="body1" gutterBottom>
                  Documents
                </Typography>
              </Grid>
            </Grid>
            <ProspectDocuments documents={documents} getDocuments={getDocuments} prospect={prospect} getProspectById={getProspectById} getPrincipalDocuments={getPrincipalDocuments} onDeleteFile={onDeleteFile} />
          </Paper>
        </Grid>
      </Grid>

      {Boolean(pdfUrl) && (<PdfModal isOpen={Boolean(pdfUrl)} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} />)}

    </>
  );
}

export default ProspectModal;
