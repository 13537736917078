/* eslint-disable max-len */
import React, { useState } from 'react';

import { TextField, IconButton, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';

export default function Commentary({
  handleClick, textInitial, onClose, label = 'Ajouter un commentaire',
}) {
  const [text, setText] = useState(textInitial || '');

  return (
    <TextField
      id="outlined-multiline-static"
      label={label}
      multiline
      rows={2}
      value={text}
      onChange={(event) => setText(event.target.value)}
      sx={{ width: '100%' }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey && text.length) {
          handleClick(text);
          setText('');
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {onClose && (
            <IconButton
              onClick={onClose}
              disabled={!text.length}
            >
              <CloseIcon />
            </IconButton>
            )}
            <IconButton
              onClick={async () => {
                await handleClick(text);
                setText('');
              }}
              disabled={!text.length}
            >
              <SendIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />

  );
}
