import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ModeIcon from '@mui/icons-material/Mode';

function formatString(inputString) {
  // Supprimer les occurrences de ".", "-", "_" et " "
  const cleanedString = inputString.replace(/[.\-_ ]/g, '');

  // Ajouter un point tous les deux caractères
  const formattedString = cleanedString.match(/.{1,2}/g).join('.');

  return formattedString;
}

export default function MediaCard({
  value, deleteContact, updateContact,
}) {
  return (
    <Card>
      {/* <CardMedia
        sx={{ height: 140 }}
        image="/static/images/cards/contemplative-reptile.jpg"
        title="green iguana"
      /> */}
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {value?.name}
        </Typography>
        {value?.phoneNumber
        && (
        <Typography variant="body3" color="text.primary">
          {formatString(value.phoneNumber)}
        </Typography>
        )}
        {value?.job
        && (
        <Typography variant="body2" color="text.secondary">
          {value.job}
        </Typography>
        )}
        {value?.email
        && (
        <Typography variant="body2" color="text.secondary">
          {value.email}
        </Typography>
        )}
      </CardContent>
      <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton aria-label="delete" onClick={updateContact}>
          <ModeIcon />
        </IconButton>
        <IconButton aria-label="delete" onClick={deleteContact}>
          <DeleteOutlineOutlinedIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
